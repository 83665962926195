import db from '@/utils/localstorage'
import axios from '../index'
// const account = db.get('account')
export default {
  /**
   * 模糊查询
   */
  async fuzzy(keyWord, isCurrent = true, enterpriseId = '') {
    let account = db.get('account')
    const { data } = await axios(
      {
        url: '/user/listAll',
        method: 'GET',
        data: {
          keyWord,
          enterpriseId: isCurrent ? enterpriseId : account.enterpriseId,
        },
      },
      {
        aloneBaseUrl: true,
      }
    )
    return data.map((item) => ({
      label: item.name,
      value: item.accountId,
      mobile: item.mobile,
    }))
  },
  async valueEnum(isCurrent = true, enterpriseId = '') {
    let account = db.get('account')
    const { data } = await axios(
      {
        url: '/user/listAll',
        method: 'GET',
        data: {
          enterpriseId: isCurrent ? enterpriseId : account.enterpriseId,
        },
      },
      {
        aloneBaseUrl: true,
      }
    )
    return data.map((item) => ({
      label: item.name,
      value: String(item.accountId),
      code: item.accountId,
      id: item.accountId,
    }))
  },
  /**
   * 账号模糊查询
   */
  async fuzzyAccount(keyWord) {
    const { data } = await axios(
      {
        url: '/account/list',
        method: 'GET',
        data: {
          account: keyWord,
          // typeKey: 2,
          size: 10,
        },
      },
      {
        aloneBaseUrl: true,
      }
    )
    return data.records
      ? data.records.map((item) => ({
          label: item.nickName,
          value: item.id,
        }))
      : []
  },
  /**
   * 理货员列表
   */
  async tallyer(departmentId) {
    const { data } = await axios(
      {
        url: '/user/listAll',
        method: 'GET',
        data: {
          departmentTypeKey: 'TALLYING',
          departmentId,
          performanceStatus: 3,
        },
      },
      {
        alone: true,
        aloneBaseUrl: true,
      }
    )
    return data.map((item) => ({
      leaf: true, // 级联属性
      label: item.name,
      value: item.accountId,
      mobile: item.mobile,
    }))
  },
  /**
   * 工人列表
   */
  async worker(departmentId) {
    const { data } = await axios(
      {
        url: '/account/findUserByType',
        method: 'POST',
        data: {
          departmentType: 'WORKER_TEAM',
          departmentId,
        },
      },
      {
        alone: true,
      }
    )
    return data.map((item) => ({
      leaf: true, // 级联属性
      label: item.name,
      value: item.accountId,
      mobile: item.mobile,
    }))
  },
}
