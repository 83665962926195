import axios from '../index'

export default {
  async goodsTypeTree(params) {
    const { data } = await axios({
      url: '/goods/tree',
      method: 'GET',
      data: {
        ...params,
        sortKey: 'TYPE',
      },
    },{
      aloneBaseUrl:'/api-warehouse'
    })
    return data
  },
  async list(params) {
    const { data } = await axios({
      url: '/goods/listAll',
      method: 'GET',
      data: {
        ...params,
        sortKey: 'GOODS',
      },
    },{
      aloneBaseUrl:'/api-warehouse'
    })
    return data.map((item) => ({
      label: item.name,
      value: item.id,
    }))
  },
  async texture(params) {
    const { data } = await axios({
      url: '/goods/listAll',
      method: 'GET',
      data: {
        ...params,
        sortKey: 'TEXTURE',
      },
    },{
      aloneBaseUrl:'/api-warehouse'
    })
    return data.map((item) => ({
      label: item.name,
      value: item.id,
    }))
  },
  async size(params) {
    const { data } = await axios({
      url: '/goods/listAll',
      method: 'GET',
      data: {
        ...params,
        sortKey: 'SPECIFICATION',
      },
    },{
      aloneBaseUrl:'/api-warehouse'
    })
    return data.map((item) => ({
      label: item.name,
      value: item.id,
    }))
  },
}
