<script>
import Util from '../../lib/util'
import UiForm from '../form'
import UiDescriptions from '../descriptions'
import UiCell from '../cell'
import SchemaList from '../../lib/schema'
export default {
  name: 'ui-modal',
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    config: Object,
    sections: {
      type: Array,
      default: () => [],
    },
    contextProps: Object,
  },
  data() {
    return {
      visible: false,
      cacheKey: 1,
      loading: false,
      closeOnClickModal: false,
      /**
       * 内容数据缓存
       */
      contextData: [],
    }
  },
  render(h) {
    const { rowdata } = this.contextProps
    const sections = this.sections.map(
      ({ title, form, descriptions: desc, context }, index) => {
        if (form) {
          const { alignLeft } = this.config
          /**
           * form组件
           */
          const formNode = (
            <UiForm
              ref={'sec' + index}
              columns={SchemaList.compose(this.columns, form.columns)}
              initialValues={rowdata}
              readonly={form.readonly}
              ndiv={form.ndiv}
              labelWidth={form.labelWidth}
              onChange={(fd) => this.onContextChange(index, fd)}
            />
          )
          return (
            <div
              class="section"
              style={alignLeft ? 'padding:0' : 'padding:20px 160px'}
              key={['form', this.cacheKey, index].join('_')}
            >
              {title && <div class="section-title">{title}</div>}
              {title ? <div class="section-wrapper">{formNode}</div> : formNode}
            </div>
          )
        }
        if (desc) {
          const { alignLeft, noPadding } = this.config
          /**
           * 描述组件
           */
          const descNode = (
            <UiDescriptions
              ref={'sec' + index}
              columns={SchemaList.compose(this.columns, desc.columns)}
              ndiv={desc.ndiv}
              editable={desc.editable}
              config={this.config}
              onRequest={(cb) => cb(rowdata)}
            />
          )
          return (
            <div
              class="section"
              style={
                alignLeft || noPadding ? 'padding:20px 0' : 'padding:20px 160px'
              }
              key={['desc', this.cacheKey, index].join('_')}
            >
              {title && <div class="section-title">{title}</div>}
              {title ? <div class="section-wrapper">{descNode}</div> : descNode}
            </div>
          )
        }
        if (context) {
          /**
           * 自定义内容
           */
          return h(context, {
            key: ['context', this.cacheKey, index].join('_'),
            ref: 'sec' + index,
            props: {
              ...this.contextProps,
            },
            on: {
              bridge: this.onModalBridge,
              finish: this.doModalCallback,
              change: (cd) => this.onContextChange(index, cd),
            },
          })
        }
      }
    )

    const { mode, title, tag, width, footer } = this.config

    const modalEvents = {
      close: this.close,
      'update:visible': () => (this.visible = false),
    }

    if (this.visible && mode === 'dialog') {
      /**
       * 对话框
       */
      return (
        <el-dialog
          class="ui-modal"
          visible={true}
          title={title}
          width={`${width}px`}
          loading={this.loading}
          close-on-click-modal={false}
          show-close={footer.length == 0}
          on={modalEvents}
        >
          {sections}
          <div slot="footer" class="footer">
            {footer.map((btn, index) => (
              <el-button
                key={index}
                type={btn.type}
                onClick={() => this.onModalFinish(btn)}
              >
                {btn.text}
              </el-button>
            ))}
          </div>
        </el-dialog>
      )
    }
    if (this.visible && mode === 'drawer') {
      /**
       * 抽屉
       */
      return (
        <el-drawer
          visible={true}
          loading={this.loading}
          modal={false}
          size={width}
          on={modalEvents}
        >
          <div slot="title" class="drawer-title__container">
            <span class="title">{title}</span>
            {tag && (
              <UiCell
                data={rowdata}
                value={rowdata?.[tag]}
                schema={SchemaList.buildSchema(
                  this.columns.find((i) => i.dataIndex === tag)
                )}
              />
            )}
            <div style="width:20px;"></div>
            {footer.length === 1 &&
              footer.map((btn, index) => (
                <el-button
                  key={index}
                  type={btn.type}
                  onClick={() => this.onModalFinish(btn)}
                >
                  {btn.text}
                </el-button>
              ))}
          </div>
          {sections}
        </el-drawer>
      )
    }
  },
  methods: {
    /**
     * 打开模态框
     */
    open() {
      this.contextData = []
      this.cacheKey++
      this.visible = true
    },
    /**
     * 关闭模态框
     */
    close(init) {
      this.visible = false
    },
    /**
     * 向对话框传递参数
     */
    onModalBridge(receiver) {
      receiver?.({
        secRefs: this.getRefs,
        data: this.contextData,
      })
    },
    getRefs(index = -1) {
      if (index >= 0) {
        return this.$refs['sec' + index]
      } else {
        return this.sections.map((_, idx) => this.$refs['sec' + idx])
      }
    },
    /**
     * 表单改变
     */
    onContextChange(index, data) {
      this.contextData[index] = data
      this.getRefs().forEach((secRef) =>
        secRef?.onContextChange?.(this.contextData)
      )
    },
    /**
     * 对话框结束按钮
     *
     * - 整理回调函数及参数
     */
    async onModalFinish(buttonConfig) {
      const { execute, method } = buttonConfig

      const params = {
        ...this.contextProps,
        data: [],
      }

      const callback = this.sections.reduce((cb, section, index) => {
        if (section.context) {
          const context = this.getRefs(index)
          if (context?.[method]) {
            return context[method].bind(context, params.data)
          }
        }
        return cb
      }, execute?.bind(this, params))

      if (callback) {
        for (let i = 0; i < this.sections.length; i++) {
          const secRef = this.getRefs(i)
          if (secRef) {
            try {
              params.data.push(await secRef?.validate?.())
            } catch (err) {
              if (typeof err === 'string') {
                this.$message.info(err)
              } else {
                Util.error(err)
              }
              return null
            }
          }
        }
      }

      this.doModalCallback(callback, buttonConfig)
    },
    /**
     * 执行回调函数
     */
    async doModalCallback(
      callback,
      { updateContent = true, gobackContent = true } = {}
    ) {
      if (callback) {
        this.closeOnClickModal = false
        this.loading = true

        try {
          const success = await callback()
          if (typeof success === 'string') {
            this.$message.success(success)
          }
          updateContent && this.$emit('success')
        } catch (err) {
          if (typeof err === 'string') {
            this.$message.error(err)
          } else {
            Util.error('对话框执行异常:', err)
          }
          gobackContent = false
        }

        this.loading = false
        this.closeOnClickModal = true
      }
      gobackContent && this.close(!!callback)
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-modal {
  & ::v-deep .el-dialog {
    margin-bottom: 60px;
  }
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.drawer-title__container {
  display: flex;
  align-items: center;

  .el-tag {
    margin-left: 12px;
  }

  .title {
    font-size: 15px;
    font-weight: 600;
  }
}
::v-deep .el-dialog__body {
  // padding: 20px 180px;
  padding: 0;
  color: #000;
}
</style>
