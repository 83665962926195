export default {
  disable: true,
  order: 9,
  label: "表单",
  head: "fa fa-",
  list: [
    "check-square",
    "check-square-o",
    "circle",
    "circle-o",
    "dot-circle-o",
    "minus-square",
    "minus-square-o",
    "plus-square",
    "plus-square-o",
    "square",
    "square-o",
  ]
}