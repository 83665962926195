<template>
  <div class="deal">
    <div class="head fl al-c">
      <div>品名</div>
      <ul class="head-ul fl al-c">
        <li>今日成交量</li>
        <li>昨日成交量</li>
        <li>日环比</li>
      </ul>
    </div>
    <div class="head content fl al-c">
      <div>全部</div>
      <ul class="head-ul content-ul fl al-c">
        <li>25032</li>
        <li>133834</li>
        <li>+44.9%</li>
      </ul>
    </div>
    <div class="chart-unit">单位：吨</div>
    <div ref="echart" class="echart"></div>

    <div class="list">
      <div
        class="list-item fl al-c"
        v-for="(item, index) in tableData"
        :key="index"
      >
        <div class="item-name text-el">{{ item.name }}</div>
        <div class="item-t">{{ item.today }}</div>
        <div class="item-t">{{ item.yestoday }}</div>
        <div :class="['item-t', getColor(item.per)]">{{ item.per }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { options } from "./options";
export default {
  data() {
    return {
      headerStyle: {
        fontWeight: "normal",
        fontSize: "12px",
      },
      echart: null,
      options,
      tableData: [
        {
          name: "螺纹",
          today: 12343,
          yestoday: 231416,
          per: "+44.9%",
        },
        {
          name: "盘螺",
          today: 12343,
          yestoday: 23141,
          per: "+44.9%",
        },
        {
          name: "热卷",
          today: 12343,
          yestoday: 231416,
          per: "-0.5%",
        },
        {
          name: "中板",
          today: 12343,
          yestoday: 23146,
          per: "+44.9%",
        },
        {
          name: "冷轧",
          today: 12343,
          yestoday: 231416,
          per: "+44.9%",
        },
        {
          name: "镀锌",
          today: 12343,
          yestoday: 231416,
          per: "+44.9%",
        },
      ],
    };
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      this.echart = echarts.init(this.$refs["echart"]);
      this.echart.setOption(this.options);
      window.onresize = () => {
        this.echart.resize();
      };
    },
    getColor(s) {
      if (s.indexOf("+") > -1) {
        return "plus";
      }
      return "reduce";
    },
  },
};
</script>

<style scoped>
.deal {
  font-size: 12px;
  color: #999;
  margin-top: 21px;
}
.head {
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 11px;
}
.head-ul li {
  /* margin-right: 20px; */
  width: 60px;
  text-align: right;
}
.head-ul li:first-child {
  margin-right: 20px;
}
.content-ul li:last-child {
  color: #bb271a;
}
.content {
  padding: 0;
  padding-top: 11px;
  border: 0;
  font-weight: bold;
  color: #333;
}
.echart {
  height: 190px;
  width: 100%;
  margin: 10px 0;
}
.chart-unit {
  text-align: right;
  margin-top: 10px;
  color: #999;
  font-size: 12px;
  line-height: 17px;
}
.list {
  width: 100%;
  height: 228px;
  overflow: auto;
}
.list-item {
  width: 100%;
  height: 38px;
  border-top: 1px solid #f0f0f0;
  justify-content: space-between;
  font-size: 12px;
  color: #333;
  display: grid;
  grid-template-columns: 90px 48px 48px 48px;
}
.item-name {
  /* width: 25%; */
}
.list-item .item-t {
  text-align: right;
}
.plus {
  color: #bb271a;
}
.reduce {
  color: #83b647;
}
</style>
