import axios from "@/axios"

export default <Service>{
  getNotice(params){
    return axios({
      url:'/platformCounseling/public/list',
      method:'GET',
      data:{
        isPublic:1,
        ...params
      }
    })
  }
}