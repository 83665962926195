<script>
export default {
  props: ['value'],
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.$emit('change', newVal)
      },
    },
  },
  render() {
    return null
  },
}
</script>
