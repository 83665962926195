<template>
  <div class="rich-text">
    <quill-editor
      v-model="content"
      :options="editorOption"
      ref="myQuillEditor"
      class="ql-editor"
    ></quill-editor>
    <input type="file" @change="change" id="upload" style="display: none" />
  </div>
</template>

<script>
import { fileAxios } from "@/axios/lib";
import { Quill } from "vue-quill-editor";
const size = Quill.import("attributors/style/size");
size.whitelist = ["10px", "12px", "14px", "16px", "18px"];
Quill.register(size, true);
export default {
  data() {
    return {
      content: "",
      editorOption: {
        placeholder: "请输入内容...",
        modules: {
          imageResize: {
            //图片放大缩小配置
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["10px", "12px", "14px", "16px", "18px"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
              // ['link', 'image', 'video'],
              ["link", "image"],
            ], // container为工具栏，此次引入了全部工具栏，也可自行配置
            handlers: {
              image: function (value) {
                console.log("img", value);
                if (value) {
                  document.querySelector("#upload").click(); // 劫持原来的图片点击按钮事件
                } else {
                  // console.log('img', value)
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      },
    };
  },
  // computed: {
  //   editor() {
  //     return this.$refs.myQuillEditor.quill
  //   },
  // },
  methods: {
    change(e) {
      // console.log(e)
      let file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("info_id", this.uuid);
      fileAxios.simpleUpload(file).then((res) => {
        console.log(res);
        let quill = this.$refs.myQuillEditor.quill;
        if (res) {
          let length = quill.getSelection().index; //光标位置
          // 插入图片  图片地址
          quill.insertEmbed(length, "image", res.url);
          // 调整光标到最后
          quill.setSelection(length + 1); //光标后移一位
          document.querySelector("#upload").value = "";
        }
      });
    },
  },
};
</script>
<style lang="scss">
p {
  margin: 10px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px" !important;
  font-size: 14px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10px" !important;
  font-size: 10px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12px" !important;
  font-size: 12px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16px" !important;
  font-size: 16px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18px" !important;
  font-size: 18px;
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本" !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1" !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2" !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3" !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4" !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5" !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6" !important;
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体" !important;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体" !important;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体" !important;
}
</style>
<style scoped lang="scss">
.rich-text {
  width: 100%;
  height: 100%;
  background: #fff;
}
::v-deep .ql-container {
  height: 80% !important;
}
/* ::v-deep .quill-editor {
  height: calc(100% - 42px);
} */
</style>
