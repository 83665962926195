<template>
  <div class="ui-segmented">
    <div
      class="segmented-thumb segmented-thumb-active"
      :style="thumbStyle"
    ></div>
    <div
      v-for="option in options"
      :key="option.value"
      ref="segmented-item"
      class="segmented-item"
      @click="$emit('update:value', option.value)"
    >
      {{ option.label }}
    </div>
  </div>
</template>

<script>
/**
 * 分段控制器组件(优化标签页样式)
 *
 * - 抄的antd的,部分功能懒得抄了,如果有需求再添加好了
 */
export default {
  name: 'ui-segmented',
  props: {
    value: {
      type: [String, Number],
      default: '',
      require: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      moving: false,
      thumbStyle: {},
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        for (let i = 0; i < this.options.length; i++) {
          if (this.options[i].value == val) {
            this.$nextTick(() => {
              const { clientWidth, clientHeight, offsetTop, offsetLeft } =
                this.$refs['segmented-item'][i]
              this.thumbStyle = {
                width: clientWidth + 'px',
                height: clientHeight + 'px',
                transform: `translate(${offsetLeft}px, ${offsetTop}px)`,
              }
            })
          }
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-segmented {
  width: 100%;
  height: 40px;
  display: flex;
  box-sizing: border-box;
  padding: 6px;
  color: #000000a6;
  background-color: #0000000a;
  border-radius: 2px;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: #0000000f;
  }

  .segmented-item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 9;
    font-size: 14px;
    font-weight: 600;
  }

  .segmented-thumb {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    transform: translate(0, 0);
    overflow: hidden;
    background-color: #ffffff;
    border-radius: 2px;
    box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05),
      0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  }

  .segmented-thumb-active {
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    will-change: transform, width, height;
  }
}
</style>
