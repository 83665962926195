<template>
  <div class="price">
    <div class="address">
      <span>上海</span>
      <svg-icon icon-class="arrowright" class="icon"></svg-icon>
    </div>
    <div class="unit">价格：元</div>
    <div class="echart" ref="echart"></div>

    <div class="grid grid-title">
      <div class="grid-item">品名</div>
      <div class="grid-item">钢厂</div>
      <div class="grid-item">价格</div>
      <div class="grid-item">涨跌</div>
    </div>
    <div class="grid grid-content" v-for="(item, index) in datas" :key="index">
      <div class="grid-item">{{ item.name }}</div>
      <div class="grid-item">{{ item.factory }}</div>
      <div class="grid-item">{{ item.price }}</div>
      <div class="grid-item num">+{{ item.per }}</div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { options } from "./options";
export default {
  data() {
    return {
      echart: null,
      options,
      datas: [
        {
          name: "三级螺纹钢",
          factory: "沙钢厂提",
          price: 4510,
          per: 30,
        },
        {
          name: "三级螺纹钢",
          factory: "沙钢厂提",
          price: 4510,
          per: 30,
        },
        {
          name: "三级螺纹钢",
          factory: "沙钢厂提",
          price: 4510,
          per: 30,
        },
        {
          name: "三级螺纹钢",
          factory: "沙钢厂提",
          price: 4510,
          per: 30,
        },
        {
          name: "三级螺纹钢",
          factory: "沙钢厂提",
          price: 4510,
          per: 30,
        },
        {
          name: "三级螺纹钢",
          factory: "沙钢厂提",
          price: 4510,
          per: 30,
        },
      ],
    };
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      this.echart = echarts.init(this.$refs["echart"]);
      this.echart.setOption(this.options);
    },
  },
};
</script>

<style scoped>
.price {
  color: #666;
  font-size: 12px;
}
.address {
  text-align: right;
  font-size: 14px;
  line-height: 20px;
  margin: 17px 0;
}
.address .icon {
  width: 10px;
  height: 10px;
}
.unit {
  text-align: right;
  line-height: 17px;
  color: #999;
  margin-bottom: 10px;
}
.echart {
  height: 160px;
}
.grid {
  display: grid;
  grid-template-columns: 100px 48px 48px 48px;
  justify-content: space-between;
}
.grid-title {
  margin-top: 30px;
  color: #999;
  border-bottom: 1px solid #f0f0f0;
}
.grid-item {
  text-align: right;
  line-height: 38px;
}
.grid-item:first-child {
  text-align: left;
}
.grid-item.num {
  color: #bb271a;
}
.grid-content {
  font-weight: bold;
  color: #333;
  border-bottom: 1px solid #f0f0f0;
}
.grid-content:last-child {
  border: 0;
}
</style>
