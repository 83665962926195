export default {
  order: 16,
  label: "医疗",
  head: "fa fa-",
  list: [
    "ambulance",
    "h-square",
    "heart",
    "heart-o",
    "heartbeat",
    "hospital-o",
    "medkit",
    "plus-square",
    "stethoscope",
    "user-md",
    "wheelchair",
    "wheelchair-alt"
  ]
}