export const preList = [
  {
    value: '京',
    label: '京',
    isClick: false,
  },
  {
    value: '津',
    label: '津',
    isClick: false,
  },
  {
    value: '沪',
    label: '沪',
    isClick: false,
  },
  {
    value: '渝',
    label: '渝',
    isClick: false,
  },
  {
    value: '冀',
    label: '冀',
    isClick: false,
  },
  {
    value: '晋',
    label: '晋',
    isClick: false,
  },
  {
    value: '陕',
    label: '陕',
    isClick: false,
  },
  {
    value: '甘',
    label: '甘',
    isClick: false,
  },
  {
    value: '宁',
    label: '宁',
    isClick: false,
  },
  {
    value: '蒙',
    label: '蒙',
    isClick: false,
  },
  {
    value: '辽',
    label: '辽',
    isClick: false,
  },
  {
    value: '吉',
    label: '吉',
    isClick: false,
  },
  {
    value: '黑',
    label: '黑',
    isClick: false,
  },
  {
    value: '苏',
    label: '苏',
    isClick: false,
  },
  {
    value: '浙',
    label: '浙',
    isClick: false,
  },
  {
    value: '皖',
    label: '皖',
    isClick: false,
  },
  {
    value: '闽',
    label: '闽',
    isClick: false,
  },
  {
    value: '赣',
    label: '赣',
    isClick: false,
  },
  {
    value: '鲁',
    label: '鲁',
    isClick: false,
  },
  {
    value: '豫',
    label: '豫',
    isClick: false,
  },
  {
    value: '鄂',
    label: '鄂',
    isClick: false,
  },
  {
    value: '湘',
    label: '湘',
    isClick: false,
  },
  {
    value: '粤',
    label: '粤',
    isClick: false,
  },
  {
    value: '桂',
    label: '桂',
    isClick: false,
  },
  {
    value: '新',
    label: '新',
    isClick: false,
  },
  {
    value: '青',
    label: '青',
    isClick: false,
  },
  {
    value: '川',
    label: '川',
    isClick: false,
  },
  {
    value: '藏',
    label: '藏',
    isClick: false,
  },
  {
    value: '黔',
    label: '黔',
    isClick: false,
  },
  {
    value: '云',
    label: '云',
    isClick: false,
  },
  {
    value: '贵',
    label: '贵',
    isClick: false,
  },
  {
    value: '琼',
    label: '琼',
    isClick: false,
  },
  {
    value: '港',
    label: '港',
    isClick: false,
  },
  {
    value: '澳',
    label: '澳',
    isClick: false,
  },
  {
    value: '台',
    label: '台',
    isClick: false,
  },
]
export const midList = [
  {
    value: 'A',
    isClick: false,
  },
  {
    value: 'B',
    isClick: false,
  },
  {
    value: 'C',
    isClick: false,
  },
  {
    value: 'D',
    isClick: false,
  },
  {
    value: 'E',
    isClick: false,
  },
  {
    value: 'F',
    isClick: false,
  },
  {
    value: 'G',
    isClick: false,
  },
  {
    value: 'H',
    isClick: false,
  },
  {
    value: 'I',
    isClick: false,
  },
  {
    value: 'J',
    isClick: false,
  },
  {
    value: 'K',
    isClick: false,
  },
  {
    value: 'L',
    isClick: false,
  },
  {
    value: 'M',
    isClick: false,
  },
  {
    value: 'N',
    isClick: false,
  },
  {
    value: 'P',
    isClick: false,
  },
  {
    value: 'Q',
    isClick: false,
  },
  {
    value: 'R',
    isClick: false,
  },
  {
    value: 'S',
    isClick: false,
  },
  {
    value: 'T',
    isClick: false,
  },
  {
    value: 'U',
    isClick: false,
  },
  {
    value: 'V',
    isClick: false,
  },
  {
    value: 'W',
    isClick: false,
  },
  {
    value: 'X',
    isClick: false,
  },
  {
    value: 'Y',
    isClick: false,
  },
  {
    value: 'Z',
    isClick: false,
  },
]
export const lastList = [
  {
    value: '0',
    isClick: false,
  },
  {
    value: '1',
    isClick: false,
  },
  {
    value: '2',
    isClick: false,
  },
  {
    value: '3',
    isClick: false,
  },
  {
    value: '4',
    isClick: false,
  },
  {
    value: '5',
    isClick: false,
  },
  {
    value: '6',
    isClick: false,
  },
  {
    value: '7',
    isClick: false,
  },
  {
    value: '8',
    isClick: false,
  },
  {
    value: '9',
    isClick: false,
  },
]
