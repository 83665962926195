import axios from '../index'

export default {
  async simpleUpload(file) {
    const formData = new FormData()

    formData.append('file', file)
    formData.append('type', window.location.hash)

    const { data } = await axios(
      {
        url: '/fileRelation/noToken/simpleUpload',
        method: 'POST',
        headers: {
          'content-type': 'multipart/form-data',
        },
        data: formData,
      },{
        alone:true,
        aloneBaseUrl:true
      }
    )
    return data
  },
  async removeFile(id) {
    if (id) {
      await axios(
        {
          url: '/fileRelation/noToken/removeFile',
          method: 'GET',
          data: { id },
        },
        {
          aloneBaseUrl: true,
        }
      )
    }
  },
}
