export const options = {
  grid: {
    bottom: "25%",
    top: "10%",
    right: '10%',
    left: "25%",
  },

  tooltip: {
    trigger: "axis",
    show: true,
    textStyle: {
      fontSize: 12,
      color: "#333",
    },
  },
  xAxis: {
    type: 'category',
    boundaryGap: false, //从0开始绘制
    axisLabel: {
      show: true,
      interval: 0,
      // color: '#ffffff',
      width: 50,
      overflow: 'truncate',
      showMaxLabel: true,
      hideOverlap: false,
    },
    axisTick: {
      //不展示刻度
      show: false,
    },
    axisLine: {
      //不展示坐标轴线
      show: false,
    },
    data: ["08:00", "18:00"],
  },
  yAxis: [
    {
      type: "value",
      splitNumber:2
    },
  ],
  series: [
    {
      name: "上一工作日",
      type: "line",
      symbol: "none",
      color: "#6788EB",
      lineStyle: {
        color: "#6788EB",
        join: "round",
      },
      data: [0, 630],
    },
    {
      name: "日环比",
      type: "line",
      symbol: "none",
      color: "#BB1A3F",
      lineStyle: {
        color: "#BB1A3F",
        join: "round",
      },
      data: [0, 500],
    },
  ],
};
