export default {
  disable: true,
  order: 4,
  label: "辅助功能",
  head: "fa fa-",
  list: [
    "american-sign-language-interpreting",
    "asl-interpreting",
    "assistive-listening-systems",
    "audio-description",
    "blind",
    "braille", "cc",
    "deaf",
    "deafness",
    "hard-of-hearing",
    "low-vision",
    "question-circle-o",
    "sign-language",
    "signing",
    "tty",
    "universal-access",
    "volume-control-phone",
    "wheelchair",
    "wheelchair-alt",
  ]
}