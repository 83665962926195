import axios from "@/axios"
import { TenantTypeValueEnum } from "@/typings/enum"
import { tenantInfoColumns } from "@/utils/columns"
import {loadPlugins} from '@amap/amap-vue'

let mapSearch: any
const getMapSearch = async () => {
  if (!mapSearch) {
    await loadPlugins(['AMap.AutoComplete'])
    // @ts-ignore
    mapSearch = new AMap.AutoComplete()
  }
  return mapSearch
}

let mapGeocoder: any
const getMapGeocoder = async () => {
  if (!mapGeocoder) {
    await loadPlugins(['AMap.Geocoder'])
    // @ts-ignore
    mapGeocoder = new AMap.Geocoder({
      city: '全国',
    })
  }
  return mapGeocoder
}
export const companyColumns: Schema[] = [
  {
    dataIndex: 'typeKey',
    title: '企业类型',
    valueType: 'select',
    valueEnum: TenantTypeValueEnum,
  },
  {
    dataIndex: 'type',
    hideInForm: true,
    listener: 'typeKey.label',
  },
  {
    dataIndex: 'name',
    title: '企业名称',
    // formFieldLayout: {
    //   rightBlank: 1,
    // },
  },
  {
    dataIndex: 'creditCode',
    title: '统一社会信用代码',
  },
  {
    dataIndex: 'address',
    title: '地址',
    valueType: 'fuzzy',
    fieldProps: {
      fuzzyDesc: 'district',
      initialText: 'address',
    },
    request: ({ keyWord }) => {
      return new Promise(async (resolve) => {
        ;(await getMapSearch())?.search(keyWord, (status: Key, result: any) => {
          console.log(result)
          if (status === 'complete' && result.tips) {
            resolve(
              result.tips
                .filter((item: any) => {
                  return item.location
                })
                .map((item: any) => ({
                  ...item,
                  value: item.name,
                  label: item.name,
                }))
            )
          } else {
            resolve()
          }
        })
      })
    },
    async validate({ tempdata: { address }, setValues }) {
      if (address?.location) {
        const { lng, lat } = address.location as any
        ;(await getMapGeocoder())?.getAddress(
          [lng, lat],
          (status: Key, result: any) => {
            if (status === 'complete' && result.info === 'OK') {
              const {
                regeocode: { addressComponent: info },
              } = result
              setValues({
                province: info.province,
                city: info.city,
                district: info.district,
              })
            }
          }
        )
      }
    },
  },
  {
    dataIndex: 'phone',
    title: '联系方式',
    rule:[
      {
        trigger:'blur',required:true,pattern:/^(?:(?:\+|00)86)?1[3-9]\d{9}$/,message:'请输入正确的手机号'
      }
    ]
  },
  {
    dataIndex: 'latitude',
    title: '纬度',
    hideInForm: true,
    validate({ tempdata: { address }, setValue }) {
      if (address) {
        console.log(address)
        setValue(address.location.lat)
      }
    },
  },
  {
    dataIndex: 'longitude',
    title: '经度',
    hideInForm: true,
    validate({ tempdata: { address }, setValue }) {
      if (address) {
        console.log(address)
        setValue(address.location.lng)
      }
    },
  },
  // {
  //   dataIndex: 'password',
  //   valueType: 'password',
  //   title: '密码',
  // },
  // {
  //   dataIndex: 'confirmPassword',
  //   valueType: 'password',
  //   title: '确认密码',
  // },
  ...tenantInfoColumns,
]

export const service: Service={
  //企业认证
  companyAccount(data: any) {
    return axios(
      {
        url: '/authentication/enterprise',
        method: 'POST',
        data,
      },
      {
        aloneBaseUrl: true,
      }
    )
  },
}