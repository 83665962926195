<template>
  <div class="icon-picker">
    <el-select
      ref="picker"
      v-model="result"
      popper-class="icon-picker-menus"
      @visible-change="onVisibleChange"
    >
      <el-option-group
        v-for="group in icons"
        :key="group.name"
        :label="group.label"
      >
        <el-option
          v-for="icon in group.list"
          :key="icon"
          :value="group.head + icon"
        >
          <i :class="group.head + icon"></i>
        </el-option>
      </el-option-group>
    </el-select>
    <div class="icon-selected">
      <i :class="value"></i>
    </div>
  </div>
</template>

<script>
const iconFiles = require.context('./lib', true, /^\.\/(.*?)\.ts$/)

const icons = iconFiles
  .keys()
  .reduce((list, path) => {
    const name = /([a-zA-Z]+)\.ts$/.exec(path)
    if (name) {
      const group = iconFiles(path).default
      group.name = name[1]
      const { order, disable } = group
      if (!disable) {
        list[order] = group
      }
    }
    return list
  }, [])
  .filter((group) => group)
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      icons,
      result: this.value,
    }
  },
  watch: {
    value(newVal) {
      this.result = newVal
    },
    result(newVal) {
      this.$emit('input', newVal)
      this.$emit('change', newVal)
    },
  },
  methods: {
    onVisibleChange(visible) {
      if (visible) {
        const picker = this.$refs.picker
        const width = picker['$el'].clientWidth
        picker['popperElm'].style.width = width + 64 + 'px'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-picker {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .el-select {
    width: 1px;
    flex-grow: 1;
  }

  .icon-selected {
    width: 64px;
    height: 32px;
    // margin-left: 12px;
    flex-shrink: 0;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
<style lang="scss">
.icon-picker-menus {
  .el-select-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 6px;

    .el-select-dropdown__item {
      width: 32px;
      height: 32px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
    }

    .el-select-dropdown__item:hover {
      background-color: #0f0f0f;
      color: #ffffff;
    }
  }
}
</style>
