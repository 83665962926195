import axios from '../index'

export default {
  submit(data:any){
    return axios({
      url:'/financialMessage/submit',
      method:'POST',
      data
    })
  },
  list(data:any){
    return axios({
      url:'/financialMessage/list',
      method:'GET',
      data
    })
  },
  detail(id:any){
    return axios({
      url:'/financialMessage/detail',
      method:'GET',
      data:{
        id
      }
    })
  },
  bannerList(messageId:any){
    return axios({
      url:'/financialMessageBanner/listAll',
      method:'GET',
      data:{
        messageId
      }
    })
  },
  teZheng(messageId:any){
    return axios({
      url:'/financialMessageCharacteristics/listAll',
      method:'GET',
      data:{
        messageId
      }
    })
  },
  liuCheng(messageId:any){
    return axios({
      url:'/financialMessageFlow/listAll',
      method:'GET',
      data:{
        messageId
      }
    })
  },
  isCollect(messageId:any){
    return axios({
      url:'/financiaMessageCollection/isCollection',
      method:'GET',
      data:{
        messageId
      }
    })
  },
  collect(messageId:any){
    return axios({
      url:'/financiaMessageCollection/collection',
      method:'GET',
      data:{
        messageId
      }
    })
  },
  remove(id:any){
    return axios({
      url:'/financialMessage/remove?id='+id,
      method:'POST',
    })
  },
  pageView(id:any){
    return axios({
      url:'/financialMessage/public/pageView?id='+id,
      method:'GET',
    })
  },
}