import { FileListItem } from 'element-ui/types/upload'

export type FileEntity = {
  id: number
  originalName: string
  url: string
}

export type FileItem = FileListItem & {
  uid?: number
  response?: FileEntity
}

export const buildFileList = (value: FileEntity[] = []): FileItem[] => {
  return value.map((item) => ({
    name: item.originalName,
    url: item.url,
    status: 'success',
    response: item,
  }))
}

export const buildFieldValue = (list: FileItem[]): string => {
  return list
    .map((item) => (item.status === 'success' ? item?.response?.id : null))
    .filter((i) => i)
    .toString()
}
