export const options = {
  grid: {
    bottom: "15%",
    top: "5%",
    right: 0,
  },
  tooltip: {
    trigger: "axis",
    show: true,
    textStyle: {
      fontSize: 12,
      color: "#333",
    },
  },
  xAxis: [
    {
      boundaryGap: false, //从0开始绘制
      axisTick: {
        //不展示刻度
        show: false,
      },
      axisLine: {
        //不展示坐标轴线
        show: false,
      },
      data: [
        "2021-08-17",
        "2021-09-17",
        "2021-10-17",
        "2021-11-17",
        "2021-12-17",
        "2022-01-17",
        "2022-02-17",
        "2022-03-17",
        "2022-04-17",
        "2022-05-17",
        "2022-06-17",
        "2022-07-17",
        "2022-08-17",
        "2022-09-17",
        "2022-10-17",
        "2022-11-17",
        "2022-12-17",
      ],
    },
  ],
  yAxis: [
    {
      type: "value",
      splitNumber:4
    },
  ],
  series: [
    {
      type: "line",
      symbol: "none",
      color: "#BF2D28",
      lineStyle: {
        color: "#BF2D28",
        join: "round",
      },
      data: [
        0, 100, 200, 300, 400, 500, 600, 300, 400, 500, 600, 300, 400, 500, 600,
        300, 400,
      ],
    },
  ],
};
