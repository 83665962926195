<template>
  <div :class="['statics', showBorder ? 'border-bt' : '']">
    <div class="title">{{ title }}</div>
    <ul class="list fl">
      <li class="item">
        <span>本周累计接单量</span>
        <span class="num">0吨</span>
      </li>
      <li class="item">
        <span>本年度累计接单量</span>
        <span class="num">1000万吨</span>
      </li>
      <li class="item">
        <span>本年累计服务用户数</span>
        <span class="num">123位</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    showBorder: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "采集",
    },
  },
};
</script>

<style scoped>
.statics {
  font-size: 12px;
  color: #999;
  line-height: 17px;
}
.border-bt {
  border-bottom: 1px solid #f0f0f0;
}
.title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  line-height: 22px;
  padding: 12px 0 0 30px;
}
.list {
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 12px 30px;
}
.item {
  width: 50%;
  padding-left: 10px;
  position: relative;
  line-height: 17px;
  margin-bottom: 12px;
}
.item::after {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #bb271a;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.item span:first-child {
  margin-right: 6px;
}
.list .item:last-child {
  margin: 0;
}
.num {
  font-size: 12px;
  color: #333;
  font-weight: bold;
}
</style>
