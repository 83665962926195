/**
 * 工具类
 *
 * - ui模块内部所需函数/类
 */
export { default as SchemaList } from './lib/schema'
export { default as Config } from './lib/config'
export { default as Util } from './lib/util'

/**
 * 功能组件
 *
 * - 不与业务产生关联
 * - 不调用ui模块外部函数/类
 */
export { default as UiTable } from './modules/table/index.vue'
export { default as UiForm } from './modules/form/index.vue'
export { default as UiLabelField } from './modules/form/label-field/index.vue'
export { default as UiFormGroup } from './modules/form-group/index.vue'
export { default as UiDescriptions } from './modules/descriptions/index.vue'
export { default as UiSegmented } from './modules/segmented/index.vue'
export { default as UiDescriptionsGroup } from './modules/descriptions-group/index.vue'
export { default as UiCell } from './modules/cell/index.vue'
export { default as UiCodeArea } from './modules/codearea/index.vue'
export { default as richText } from './modules/richText/index.vue'

/**
 * 业务组件
 *
 * - 可以根据业务场景定制
 * - 可以调用ui模块外部的函数/类
 */
export { default as UiTablePage } from './modules/table-page/index.vue'
export { default as UiTreePage } from './modules/tree-page/index.vue'
export { default as UiPageView } from './modules/page-view/index.vue'
export { default as IconPicker } from './modules/icon-picker/index.vue'
export { default as UiIm } from './modules/im/index.vue'
export { default as LoginSection } from './modules/loginSection/index.vue'
export { default as UiModal } from './modules/modal/index.vue'
// export { default as AuthCertification } from './modules/authCertification/index.vue'

/**
 * 功能组件需要调用的业务功能
 */
export { default as External } from './lib/external'

/**
 * 自定义指令
 */
import { perm } from './lib/directive'

export default <Vue.PluginObject<{}>>{
  install(Vue) {
    Vue.use(perm)
  },
}
