import { buildSchema } from './lib'

/**
 * babel不支持Array的继承
 *
 * https://babeljs.io/docs/en/learn/#ecmascript-2015-features-subclassable-built-ins
 */
export default class SchemaList extends Array<IntactSchema> {
  static buildSchema = buildSchema

  constructor(list: Schema[] = []) {
    const schemas: IntactSchema[] = []
    for (let i = 0; i < list.length; i++) {
      const { dataIndex } = list[i]
      if (dataIndex) {
        schemas.some(
          (item, idx) => item.dataIndex === dataIndex && schemas.splice(idx, 1)
        )
        schemas.push(buildSchema(list[i]))
      }
    }
    super(...schemas)
  }

  static compose = (schemas: Schema[], list: ComposeSchemas = []): Schema[] => {
    const result: Schema[] = []
    for (let i = 0; i < list.length; i++) {
      const _item = list[i]
      // 1. is last group?
      const hasDivider = Array.isArray(_item) && i < list.length - 1
      // 2. build group
      const group = Array.isArray(_item) ? _item : [_item]
      for (let j = 0; j < group.length; j++) {
        const item = group[j]
        // 3. build params
        const composeSchema =
          typeof item === 'string'
            ? {
                dataIndex: item,
              }
            : item
        // 4. var schema
        const schema = {
          ...schemas.filter((s) => s.dataIndex === composeSchema.dataIndex)[0],
          ...composeSchema,
        }
        // 5. add divider
        if (hasDivider && j === group.length - 1) {
          schema.formFieldLayout = {
            ...schema.formFieldLayout,
            divider: true,
          }
        }
        result.push(schema)
      }
    }
    return result
  }
}
