export default {
  order: 5,
  label: "运输",
  head: "fa fa-",
  list: [
    "ambulance",
    "automobile",
    "bicycle",
    "bus",
    "cab",
    "car",
    "fighter-jet",
    "motorcycle",
    "plane",
    "rocket",
    "ship",
    "space-shuttle",
    "subway",
    "taxi",
    "train",
    "truck",
    "wheelchair",
    "wheelchair-alt",
  ]
}