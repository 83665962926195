export default {
  order: 7,
  label: "文件类型",
  head: "fa fa-",
  list: [
    "file",
    "file-archive-o",
    "file-audio-o",
    "file-code-o",
    "file-excel-o",
    "file-image-o",
    "file-movie-o",
    "file-o",
    "file-pdf-o",
    "file-photo-o",
    "file-picture-o",
    "file-powerpoint-o",
    "file-sound-o",
    "file-text",
    "file-text-o",
    "file-video-o",
    "file-word-o",
    "file-zip-o",
  ]
}