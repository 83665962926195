<template>
  <div class="table-page">
    <!-- 搜索 -->
    <div class="search" v-if="searchProps">
      <div class="right-form">
        <ui-form
          ref="search-form"
          :inspect="false"
          :columns="searchColumns"
          :labelWidth="searchProps.labelWidth"
        />
        <div class="search-control">
          <el-button type="primary" @click="search">查询</el-button>
          <el-button @click="resetSearch">重置</el-button>
        </div>
      </div>
    </div>

    <div class="content">
      <!-- 表格头部 -->
      <div class="header">
        <div class="title" v-if="pageConfig.title">
          <div class="back" v-if="pageConfig.title.canBack" @click="goBack">
            <i class="el-icon-back"></i>
            <span>返回</span>
            <div class="split"></div>
          </div>
          <span>{{ pageConfig.title.content(params) }}</span>
        </div>
        <div class="blank"></div>
        <div class="toolbar">
          <el-button
            v-for="(ctrl, index) in pageConfig.toolbar"
            v-perm="ctrl.permissions"
            :key="index"
            :type="ctrl.type"
            @click="delegate(ctrl)"
            >{{ ctrl.text }}</el-button
          >
          <slot name="toolbar"></slot>
        </div>
      </div>
      <!-- 表格 -->
      <ui-table
        ref="table"
        :loading="loading"
        :records="records"
        :config="pageConfig"
        :editable="pageConfig.get('editable')"
        :columns="SchemaList.compose(columns, pageConfig.get('columns'))"
        @delegate="delegate"
        @rowClick="rowClick"
        @change="change"
      >
        <template
          v-if="pageConfig.context('expand')"
          v-slot:expand="{ row, key }"
        >
          <component
            :is="pageConfig.context('expand')"
            :rowdata="row"
            :key="key"
        /></template>
      </ui-table>
      <!-- 分页器 -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="current"
          :page-sizes="[8, 10, 12, 14, 16, 20, 30, 40, 50]"
          :page-size="pageSize"
          :total="total"
          :pager-count="5"
          @size-change="setPageSize"
          @current-change="(_current) => update(_current)"
        />
      </div>
    </div>

    <!-- 对话框 -->
    <ui-modal
      ref="modal"
      :pageConfig="pageConfig"
      :columns="columns"
      :service="service"
      :config="modalConfig"
      :sections="controlSections"
      :contextProps="controlContextProps"
      @success="update"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Util from "../../lib/util";
import UiTable from "../table";
import UiForm from "../form";
import UiDescriptions from "../descriptions";
import UiModal from "../modal";
import pageMixin from "../../mixins/page";
import { buildRecords } from "./lib";
import SchemaList from "../../lib/schema";
export default {
  name: "table-page",
  components: { UiTable, UiForm, UiDescriptions, UiModal },
  mixins: [pageMixin],
  data() {
    return {
      SchemaList,
      /**
       * 表格
       */
      loading: false,
      records: [],
      pageSize: 10,
      current: 1,
      total: 1,
      listBy: {},
    };
  },
  computed: {
    ...mapState("app", ["crumbs"]),
    ...mapGetters(["sidebar"]),
  },
  mounted() {
    this.update();
  },
  methods: {
    change({ rowIndex, schema, val: value }) {
      console.log("change", { rowIndex, schema, val: value });
      this.$emit("change", { rowIndex, schema, val: value });
    },
    jump(index) {
      const gap = this.crumbs.length - 1 - index;
      if (gap > 0) {
        this.$store.dispatch("app/setCrumbs", this.crumbs.slice(0, index + 1));
        this.$router.go(-gap);
      }
    },
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    /**
     * 设置表格单页数量[8, 10, 12, 14, 16, 20, 30, 40, 50]
     *
     * - 尽量使用列表内的数量,以保持统一和规范
     *
     * @param {number} size
     */
    setPageSize(size) {
      if (size && this.pageSize !== size) {
        this.pageSize = size;
        this.update(1);
      }
    },
    /**
     * 用于详情内的返回
     */
    goBack() {
      this.$store.dispatch(
        "app/setCrumbs",
        this.crumbs.slice(0, this.crumbs.length - 1)
      );
      this.$router.go(-1);
    },
    rowClick(row) {
      this.$emit("rowClick", row);
    },
    /**
     * 重置搜索条件
     */
    resetSearch() {
      this.$refs["search-form"].resetFields();
      this.listBy = {};
      this.update(1);
    },
    async search() {
      this.listBy = await this.$refs["search-form"].validate();
      this.update(1);
    },
    /**
     * 更新表格
     *
     * @param {number} targetPage 目标页
     * @param {any} params
     */
    async update(targetPage, params) {
      this.loading = true;
      this.records = [];
      try {
        const _current = targetPage || this.current;
        const resp = await this.service.view?.(
          {
            ...this.listBy,
            size: this.pageSize,
            current: _current,
          },
          {
            ...this.params,
            ...params,
          }
        );
        const { current = _current, total, records } = buildRecords(resp);

        this.current = current;
        this.records = records;
        this.total = total;
      } catch (e) {
        Util.error("获取分页失败:", e);
      }
      this.loading = false;
      this.$emit("updated");
    },
  },
};
</script>

<style scoped lang="scss">
.table-page {
  box-sizing: border-box;
  padding: 12px;

  .search {
    display: flex;
    // align-items: flex-start;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 18px;
    margin-bottom: 12px;
    .hamburger-box {
      display: flex;
      align-items: center;
    }
    .hamburger-container {
      cursor: pointer;
      transition: background 0.3s;
      -webkit-tap-highlight-color: transparent;

      &:hover {
        background: rgba(0, 0, 0, 0.025);
      }
    }
    .search-control {
      height: 40px;
      display: flex;
      align-items: center;
      margin-left: 12px;
    }
  }
  .right-form {
    display: flex;
    align-items: center;
  }
  .content {
    background-color: #ffffff;
    padding: 18px;
    border-radius: 6px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 18px;

      .title {
        display: flex;
        align-items: center;
        font-size: 16px;
        .back {
          display: flex;
          align-items: center;
          cursor: pointer;

          i {
            font-size: 18px;
          }

          span {
            font-size: 14px;
            margin-left: 6px;
            position: relative;
          }

          .split {
            width: 1px;
            height: 16px;
            background-color: #dcdfe6;
            margin: 0 20px;
          }
        }
      }
    }

    .pagination {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 14px;
      margin-top: 18px;
    }
  }
}
</style>
