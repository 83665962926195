export default {
  disable: true,
  order: 10,
  label: "支付",
  head: "fa fa-",
  list: [
    "cc-amex",
    "cc-diners-club",
    "cc-discover",
    "cc-jcb",
    "cc-mastercard",
    "cc-paypal",
    "cc-stripe",
    "cc-visa",
    "credit-card",
    "credit-card-alt",
    "google-wallet",
    "paypal",
  ]
}