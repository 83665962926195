import axios from '@/axios'

export default <Service>{
  async login(data){
    return axios(
      {
        url: '/login/login',
        method: 'POST',
        data,
      },
      {
        aloneBaseUrl: true,
      }
    )
  },
  async register(params){
    const { data } =await axios(
      {
        url: '/register/account',
        method: 'POST',
        data:params,
      },
      {
        aloneBaseUrl: true,
      }
    )
    return data
  },
  async registerCompany(params){
    const { data } =await axios(
      {
        url: '/register/enterprise',
        method: 'POST',
        data:params,
      },
      {
        aloneBaseUrl: true,
      }
    )
    return data
  },
  async roleList(params){
    const { data } =await axios(
      {
        url: '/role/enterpriseRole',
        method: 'GET',
        data:params,
      },
      {
        aloneBaseUrl: true,
      }
    )
    return data
  },
  async getAccountDetail(id){
    const { data } =await axios(
      {
        url: '/account/detail',
        method: 'GET',
        data:{id},
      },
      {
        aloneBaseUrl: true,
      }
    )
    return data
  },
  async getYzm(phone:any) {
    return axios(
      {
        url: '/smsSend/register',
        method: 'GET',
        data:{phone}
      },
      {
        withToken: false,
        aloneBaseUrl: true,
      }
    )
  },
  //验证验证码
  async checkYzm(data:any) {
    return axios(
      {
        url: '/register/smsRegister',
        method: 'GET',
        data,
      },
      {
        withToken:false,
        aloneBaseUrl: true,
      }
    )
  }
}


//用户注册
// export const personRegister = async (params) => {
//   const { data } = await axios(
//     {
//       url: '/register/user',
//       method: 'POST',
//       data: params,
//     },
//     {
//       aloneBaseUrl: true,
//     }
//   )
//   return data
// }

// export function logout() {
//   return axios(
//     {
//       url: '/login/logout',
//       method: 'GET',
//     },
//     {
//       aloneBaseUrl: true,
//     }
//   )
// }
// //获取验证码
// export function getCaptcha() {
//   return axios(
//     {
//       url: '/captcha/getCaptcha',
//       method: 'GET',
//     },
//     {
//       isYzm: true,
//       aloneBaseUrl: true,
//     }
//   )
// }
// //验证验证码
// export function checkCaptcha(data) {
//   return axios(
//     {
//       url: '/captcha/checkCaptcha',
//       method: 'GET',
//       data,
//     },
//     {
//       aloneBaseUrl: true,
//     }
//   )
// }
