/**
 * ui的使用
 *
 * 开发时是将ui和项目的概念分开的;
 * ui属于外部组件库,而一些不规范的功能或与业务相关较大的操作均不放在ui中,但会开放相应的添加自定义功能的方式;
 * ui.extra相关的文件就是项目在使用ui组件的过程中所有的自定义操作
 */
import Vue from 'vue'

import store from '@/store'
import ui, { External } from './index'
import { Control } from './lib/config'

Vue.use(ui)

External.realize({
  hasAnyPerm: (perms) => {
    const permissions = store.state.oauth.permissions
    return (
      !perms?.length || perms.some((perm) => permissions.indexOf(perm) !== -1)
    )
  },
  hasAllPerm: (perms) => {
    const permissions = store.state.oauth.permissions
    return (
      !perms?.length || perms.every((perm) => permissions.indexOf(perm) !== -1)
    )
  },
})

Control.action('check', <ControlAction>((build) => {
  return {
    text: '审核',
    icon: 'el-icon-s-check',
    type: 'warning',
    ...build,
    modal: {
      title: '审核',
      width: 720,
      footer: [
        Control.button<[Record<string, any>]>('cancel', {
          text: '审核不通过',
          updateContent: true,
          async execute({ rowdata, data: [formdata], service }) {
            await service?.check?.({
              rowdata,
              formdata,
              state: false,
            })
            return '审核已提交'
          },
        }),
        Control.button<[Record<string, any>]>('sure', {
          text: '审核通过',
          async execute({ rowdata, data: [formdata], service }) {
            await service?.check?.({
              rowdata,
              formdata,
              state: true,
            })
            return '审核已提交'
          },
        }),
      ],
      ...build.modal,
    },
  }
}))
