<template>
  <el-tree
    class="ui-tree"
    ref="tree"
    default-expand-all
    :node-key="nodeKey"
    highlight-current
    v-loading="loading"
    :props="{ label }"
    :data="data"
    :filter-node-method="filterNode"
    :current-node="defaultSelected"
    @node-click="nodeClick"
  >
    <div class="custom-tree-node" slot-scope="{ node, data }">
      <div>
        <span>{{ node.label }}</span>
        <span v-if="data.sort" style="padding-left: 10px"
          >({{ data.sort }})</span
        >
      </div>

      <span v-if="controlsVisible">
        <template v-for="(ctrl, index) in controls">
          <!-- <el-button
            v-if="similar(data, ctrl.show)"
            size="mini"
            :key="index"
            :type="ctrl.type ? ctrl.type : 'default'"
            :disabled="!similar(data, ctrl.able)"
            @click.stop="delegate(ctrl, data)"
            >{{ ctrl.text }}</el-button
          > -->
          <el-link
            v-if="similar(data, ctrl.show)"
            size="mini"
            class="btn-link"
            :underline="false"
            :key="index"
            :type="ctrl.type ? ctrl.type : 'default'"
            :disabled="!similar(data, ctrl.able)"
            @click.stop="delegate(ctrl, data)"
          >
            {{ ctrl.text }}
          </el-link>
        </template>
      </span>
    </div>
  </el-tree>
</template>

<script>
import Util from '../../lib/util'
import Config from '../../lib/config'
import External from '../../lib/external'
export default {
  name: 'ui-tree',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    config: Config,
    label: {
      type: String,
      default: 'label',
    },
    nodeKey: {
      type: String,
      default: 'value',
    },
    defaultSelected: {
      type: String | Number,
      default: '',
    },
  },
  watch: {
    defaultSelected: {
      immediate: true,
      handler(n) {
        if (n) {
          this.$refs['tree'].setCurrentKey(n)
        }
      },
    },
  },
  data() {
    const controls = this.config.controls.filter((ctrl) =>
      External.hasPerm(ctrl.permissions)
    )
    return {
      controls,
      controlsVisible: true,
    }
  },
  methods: {
    nodeClick(node) {
      this.$emit('nodeClick', node)
    },
    filter(name) {
      this.$refs['tree'].filter(name)
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    toggleControls(visible) {
      this.controlsVisible =
        visible === undefined ? !this.controlsVisible : visible
    },
    /**
     * 判断相似度
     */
    similar(data, able) {
      const judge =
        !able ||
        Object.entries(able).every(([key, rule]) => {
          return Util.similar(String(Util.chainValue(data, key)), rule)
        })
      return judge
    },
    /**
     * 按钮事件代理
     */
    delegate(control, data) {
      if (this.similar(data, control.able)) {
        this.$emit('delegate', control, data)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-tree {
  ::v-deep .el-tree-node__content {
    height: 28px;
    margin-bottom: 10px;
  }

  .custom-tree-node {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }
}
.btn-link {
  margin-right: 20px;
}
</style>
