/**
 * 内部组件工具类
 */
export default class Util {
  /**
   * 生产环境保留的打印信息
   */
  static error(...args: any[]) {
    console.error(...args)
  }
  /**
   * 数组转字符串
   *
   * eg: arrToStr([1, 2, 3]) -> '1,2,3'
   */
  static arrToStr(
    list: any[] | string,
    type: string = 'string'
  ): string | any[] | void {
    return Array.isArray(list)
      ? list.length
        ? type === 'string'
          ? list.toString()
          : type === 'json'
          ? JSON.stringify(list)
          : list
        : undefined
      : list
  }
  /**
   * 字符串转数组
   *
   * eg: strToArr('1,2,3') -> [1, 2, 3]
   */
  static strToArr(str: any[] | string, type: string = 'string'): any[] {
    if (Util.isNull(str)) {
      return []
    } else {
      if (Array.isArray(str)) {
        return str
      } else {
        if (type === 'string') {
          if (str[0] === '[') {
            return JSON.parse(str)
          } else {
            return str.split(',')
          }
        } else if (type === 'json') {
          return JSON.parse(str)
        } else {
          return []
        }
      }
    }
  }
  /**
   * 多条数据是否相等
   *
   * eg: equal(1 + 1, 2, 3 - 1) -> true
   */
  static equal(...args: any[]): boolean {
    let last = args[0]
    return args.every((arg) => {
      const bool = String(last) === String(arg)
      last = arg
      return bool
    })
  }
  /**
   * 获取两个字符串相似度
   *
   * eg: similar('abc', /^a/) -> true
   */
  static similar(str: string, rule: string | RegExp): boolean {
    if (rule instanceof RegExp) {
      return rule.test(str)
    } else {
      return str.indexOf(rule) !== -1 || rule.indexOf(str) !== -1
    }
  }
  /**
   * 获取文件后缀
   *
   * eg: ext('aaa.txt') -> txt
   */
  static ext(fileName: string): string {
    const fileExt = /.*\.(.*?)$/.exec(fileName)
    return fileExt ? fileExt[1] : ''
  }
  /**
   * 链式获取对象属性
   *
   * eg: chainValue({aaa: {bbb: {ccc: '111'}}}, 'aaa.bbb.ccc') -> '111'
   */
  static chainValue(
    target: Record<string, any> | null | undefined,
    chain: string,
    initialValue?: any
  ): any {
    if (target) {
      if (target.hasOwnProperty(chain)) {
        return target[chain]
      } else {
        if (typeof chain === 'string') {
          const result = chain.split('.').reduce(
            ({ val, error }, key) => {
              if (!error) {
                if (val) {
                  if (val.hasOwnProperty(key)) {
                    return {
                      val: val[key],
                      error,
                    }
                  }
                }
              }
              return { val, error: true }
            },
            { val: target, error: false }
          )
          if (!result.error) {
            return result.val
          }
        }
      }
    }
    return initialValue
  }
  /**
   * 拆开链式对象
   */
  static chain(target: Record<string, any>): Record<string, any> {
    Object.entries(target).forEach(([key, value]) => {
      if (key.indexOf('.') !== -1) {
        const keys = key.split('.')
        keys.reduce((supVal, keyItem, index) => {
          if (index === keys.length - 1) {
            supVal[keyItem] = value
          } else {
            if (typeof supVal[keyItem] !== 'object') {
              supVal[keyItem] = {}
            }
            return supVal[keyItem]
          }
        }, target)
        delete target[key]
      }
    })
    return target
  }
  /**
   * 简化链式对象
   */
  static cutChain(target: Record<string, any>): Record<string, any> {
    Object.entries(target).forEach(([key, value]) => {
      if (key.indexOf('.') !== -1) {
        const keys = key.split('.')
        target[keys[0]] = value
        delete target[key]
      }
    })
    return target
  }
  /**
   * 链式属性转普通字符串
   */
  static deChain(str: string): string {
    return str.replace(/\./g, '__')
  }
  /**
   * 字符串转链式属性
   */
  static enChain(str: string): string {
    return str.replace(/__/g, '.')
  }
  /**
   * 判断是否为空(避免数字0和false被误判)
   */
  static isNull(...args: any[]): boolean {
    return args.every((arg) => arg === undefined || arg === null || arg === '')
  }
  static notNull(...args: any[]): boolean {
    return !Util.isNull(...args)
  }
}
