/** 库存模块 */
import axios from '../index'

export default {
  /**
   * 出库选择(类型汇总)
   */
  sum(data: any) {
    return axios({
      url: '/goodsInventory/goodsFirstListALL',
      method: 'GET',
      data: {
        isInventory: 1,
        ...data,
      },
    })
  },
  /**
   * 入库纪录(类型、入库汇总)
   */
  entrance(data: any) {
    return axios({
      url: '',
      method: '',
      data,
    })
  },
  /**
   * 库存明细
   */
  async records(params: any) {
    const { data } = await axios({
      url: '/goodsInventory/secondListAll',
      method: 'GET',
      data: {
        ...params,
        isInventory:1
      },
    },{
      aloneBaseUrl:'/api-warehouse'
    })
    return data
  },
}
