import { default as $axios, AxiosRequestConfig as Query } from "axios";
import qs from "qs";
import { Message } from "element-ui";
//@ts-ignore
import db from "@/utils/localstorage";
//@ts-ignore
import { BASE_URL } from "@/settings";
const isDev = process.env.NODE_ENV === "development";

export type HttpQuery = {
  (query: Query, extra?: ExtraConfig): Promise<any>;
  get: (
    url: string,
    query?: Query & { url?: undefined; method?: undefined },
    extra?: ExtraConfig
  ) => Promise<any>;
  post: (
    url: string,
    query?: Query & { url?: undefined; method?: undefined },
    extra?: ExtraConfig
  ) => Promise<any>;
};

export type ExtraConfig = {
  /**
   * 报错是否提示
   */
  warning?: boolean;
  /**
   * 请求头是否携带token
   */
  withToken?: boolean;
  /**
   * 是否独立请求
   */
  alone?: boolean;
  /**
   * 缓存key
   */
  cacheKey?: string;
  /**
   * 独立前缀
   */
  aloneBaseUrl?: boolean | string;
};

export type AxiosCallBackParams = {
  pendingData: any;
  extraConfig: ExtraConfig;
  query: Query;
};

enum HttpError {
  TIMEOUT = "请求超时",
  INVALID = "登录已失效，请重新登录",
  NULL = "服务器正忙,请稍后重试",
  NOTHING = "访问的地址不存在",
}

/**
 * 登录失效
 */
const invalid = function (msg: string, params: AxiosCallBackParams) {
  const {
    extraConfig: { warning },
  } = params;

  warning && Message.error(msg);
  db.clear();

  // window.location.href = '/#/login'
};

/**
 * 成功响应
 *
 * 1. 状态不正确依旧会走异常
 * 2. 登录失效时,重新获取token并重新请求目标接口,使用户无感
 */
async function handleSuccess(params: AxiosCallBackParams) {
  const {
    pendingData: { data },
    extraConfig: { warning },
  } = params;

  const { status, info } = data;
  //data?.code 兼容钢铁网返回数据
  if (status === 200 || data?.code == 200) {
    return data;
  } else {
    if (status === 404) {
      // Message.error(HttpError.NOTHING);
    } else if (status === 403 || status === 401) {
      // invalid(info, params);
      // invalid(HttpError.INVALID, params)
    } else {
      warning && info && Message.error(info);
    }
    throw null;
  }
}

/**
 * 错误响应
 */
async function handleError(params: AxiosCallBackParams) {
  const {
    pendingData: error,
    extraConfig: { warning },
  } = params;

  if (error) {
    const { code, response } = error;
    if (code === "ECONNABORTED") {
      Message.error(HttpError.TIMEOUT);
    } else if (response) {
      let { data } = response
      data.info && Message.error(data.info)
    }
  }
  throw null;
}

/**
 * 请求缓存: 接口请求返回值组成的Map
 */
const requestMap: Record<string | symbol, Promise<any> | undefined> = {};

const defaultExtra: ExtraConfig = {
  warning: true,
  withToken: true,
  alone: false,
};
//仓储接口
const warehouseList: any = [
  "/goods/tree",
  "/goods/listAll",
  "/goodsInventory/secondListAll",
  "/departure/submit",
  "/transportVehicleTask/listAll",
  "/outWarehouseGoods/listAll",
  "/transportVehicleTask/signFor",
  "/pay/phoneWebAlipay",
  "/pay/settlementList",
  "/pay/settlementPay",
  "/pay/wechatJsapiPay",
];

const isWarehouse: any = (url: any = "") => {
  return warehouseList.indexOf(url) > -1;
};
/**
 * 接口请求封装
 */
const axios: HttpQuery = async function (
  query: Query,
  extra?: ExtraConfig
): Promise<any> {
  const { url = "", method, headers = {} } = query;
  const extraConfig = { ...defaultExtra, ...extra };
  const { withToken, alone, cacheKey, aloneBaseUrl } = extraConfig;

  if (withToken) {
    headers.Authorization = db.get("token");
  }

  if (method === "GET" || method === "DELETE") {
    query.params = {
      ...query.params,
      ...query.data,
    };
    query.paramsSerializer = (params) =>
      qs.stringify(params, { indices: false });
  }

  /**
   * 请求缓存
   *
   * TODO: 类型声明
   *
   * 1. alone -> true       : 使用symbol值作为key,目的是使请求唯一,不适用缓存
   * 2. alone -> false      : 使用url作为key,请求结束时删除该key,目的是当一个接口在请求时，所有对该接口的请求值都使用同一个返回值
   * 3. cacheKey            : 使用自定义字符串作为key,目的是在页面打开至关闭的所有时间内,所有cacheKey的请求都使用同一个返回值
   */
  const mapKey: any = alone ? Symbol(url) : cacheKey || url;
  let baseUrlProd =
    typeof aloneBaseUrl === "boolean"
      ? "https://api.tswlsz.com/account"
      : isWarehouse(url)
      ? "https://api.tswlsz.com/warehouse"
      : BASE_URL;
  let baseUrlDev =
    typeof aloneBaseUrl === "boolean"
      ? "/api-account"
      : isWarehouse(url)
      ? "/api-warehouse"
      : BASE_URL;
  let baseUrl =
    process.env.VUE_APP_BASE_API === "/prod-api" ? baseUrlProd : baseUrlDev;
  if (!requestMap[mapKey]) {
    requestMap[mapKey] = $axios({
      // baseURL:  aloneBaseUrl ? '/api-account' : isWarehouse(url) ? '/api-warehouse' : BASE_URL,
      // baseURL:  typeof(aloneBaseUrl) === 'boolean' ? '/api-account' : aloneBaseUrl ? aloneBaseUrl : BASE_URL,
      baseURL: url.indexOf("https") > -1 ? url : baseUrl,
      // baseURL: BASE_URL,
      timeout: 30000,
      ...query,
      headers,
    })
      .then((response) => {
        !cacheKey && delete requestMap[mapKey];
        return handleSuccess({ query, pendingData: response, extraConfig });
      })
      .catch((error) => {
        !cacheKey && delete requestMap[mapKey];
        return handleError({ query, pendingData: error, extraConfig });
      });
  }

  return requestMap[mapKey];
};

axios.get = function (url: string, query?: Query, extra?: ExtraConfig) {
  return axios(
    {
      ...(query || {}),
      url,
      method: "GET",
    },
    extra
  );
};

axios.post = function (url: string, query?: Query, extra?: ExtraConfig) {
  return axios(
    {
      ...(query || {}),
      url,
      method: "POST",
    },
    extra
  );
};

export default axios;
