<template>
  <div>
    <el-tag v-if="cellValue.tag" :type="cellValue.tag">{{
      cellValue.text
    }}</el-tag>
    <div v-else-if="isImg.test(cellValue.text)">
      <el-image
        style="width: 80px; height: 80px"
        :src="cellValue.text.split(',')[0]"
        :preview-src-list="cellValue.text.split(',')"
      ></el-image>
    </div>
    <i v-else :class="cellValue.icon" :style="{ color: cellValue.color }">{{
      cellValue.text
    }}</i>
  </div>
</template>

<script>
/**
 * 单元格组件
 *
 * TODO: value属性prop可以去掉
 *
 * 1. 用于展示一些规范化但又比较麻烦的数据
 * 2. 注意：虽然该组件会请求接口,如果批量使用该组件,也会多次请求同一个接口,但当前项目axios请求做过处理,同一时间内的相同请求只会请求一次接口
 */
export default {
  name: "ui-cell",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    value: [String, Number, Boolean],
    schema: Object,
  },
  data() {
    const { valueType, valueEnum, request, formatter } = this.schema;
    if (!formatter && valueType === "select") {
      request?.().then(
        (list) =>
          (this.valueEnum = list.reduce((result, item) => {
            result[item.value] = {
              text: item.label,
              tag: item.tagType,
            };
            return result;
          }, {}))
      );
    }
    return {
      valueEnum,
      isImg:
        /^https?:\/\/(.+\/)+.+(\.(gif|png|jpg|jpeg|webp|svg|psd|bmp|tif))$/i,
    };
  },
  computed: {
    cellValue() {
      const { valueType, formatter } = this.schema;
      if (formatter) {
        return formatter({
          data: this.data,
          value: this.getValue(this.value),
        });
      }
      if (valueType === "file") {
        return {
          icon: /^.*\.(doc|docx|xlsx|pdf)$/.test(this.value)
            ? "el-icon-document"
            : "el-icon-picture-outline",
        };
      }
      return (
        this.valueEnum?.[this.value] || {
          text: this.getValue(this.value),
        }
      );
    },
  },
  methods: {
    getValue(v) {
      if (v === "undefined" || v === "null") return "";
      return v;
    },
  },
};
</script>
