export default {
  disable: true,
  order: 6,
  label: "性别",
  head: "fa fa-",
  list: [
    "genderless",
    "intersex",
    "mars",
    "mars-double",
    "mars-stroke",
    "mars-stroke-h",
    "mars-stroke-v",
    "mercury",
    "neuter",
    "transgender",
    "transgender-alt",
    "venus",
    "venus-double",
    "venus-mars",
  ]
}