/**
 * 开发环境
 *
 * api-proxy
 * api-account
 */
const DEV_BASE_URL = '/api-proxy'
/**
 * 生产环境
 */
// const PROD_BASE_URL = 'https://api.tswlsz.com/message'
// const PROD_BASE_URL = '/api-proxy'
const PROD_BASE_URL = process.env.VUE_APP_BASE_API === '/prod-api' ? 'https://api.tswlsz.com/message' : '/api'
exports.IS_DEV = process.env.NODE_ENV === 'development'

exports.BASE_URL = exports.IS_DEV ? DEV_BASE_URL : PROD_BASE_URL

exports.WEBSITE_NAME = '现代服务业智慧服务平台'

exports.WEBSITE_LOGO = '/logo.png'

