<template>
  <div class="swiper-container" ref="swiper">
    <img src="../../assets/logo-new.png" class="logo" alt="" />
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, index) in banner" :key="index">
        <img :src="item.url" class="img" alt="" />
      </div>
      <!-- <div class="swiper-slide" v-for="(item, index) in videos" :key="index">
        <video
          :src="item.url"
          :ref="'video-' + index"
          muted
          class="img"
          @ended="videoEnd"
        ></video>
      </div> -->
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  data() {
    return {
      swiper: null,
      banner: [
        {
          url: require("@/assets/images/banner/index2.png"),
        },
        {
          url: require("@/assets/images/banner/index1.png"),
        },
        {
          url: require("@/assets/images/banner/index3.png"),
        },
        {
          url: require("@/assets/images/banner/index4.jpg"),
        },
      ],
      activeVideoIndex: 0,
    };
  },
  mounted() {
    this.initSwiper();
  },
  methods: {
    videoEnd() {
      this.$refs["video-" + this.activeVideoIndex][0].currentTime = 0;
      if (this.activeVideoIndex == 0) {
        this.swiper.slideNext();
      } else {
        this.swiper.slidePrev();
      }
    },
    initSwiper() {
      this.swiper = new Swiper(this.$refs["swiper"], {
        speed: 500,
        autoplay: true,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
        },
      });
    },
  },
};
</script>

<style scoped>
.swiper-container {
  width: 100%;
  height: 536px;
  overflow: hidden;
  position: relative;
}
.logo {
  width: 64px;
  height: auto;
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 100;
}
.swiper-slide {
  width: 100%;
  height: 536px;
  background: #ccc;
}
.swiper-slide .img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.swiper-pagination {
  /* position: fixed; */
}
.swiper {
  --swiper-theme-color: #ff6600;
}
::v-deep .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: #fff;
  opacity: 1;
  transition: all 0.5s;
}
::v-deep .swiper-pagination-bullet-active {
  width: 30px;
  height: 10px;
  border-radius: 10px;
  opacity: 1;
  background: #3c9cff;
}
</style>
