<template>
  <el-table
    class="ui-table"
    size="medium"
    ref="table"
    v-loading="loading"
    :data="records"
    :header-cell-style="headerStyle"
    :row-key="config.get('rowKey', 'id')"
    :row-class-name="rowClassName"
    :cell-class-name="cellClassName"
    :tree-props="{ children: 'children' }"
    :highlight-current-row="true"
    :lazy="config.get('lazy')"
    :load="config.get('load')"
    :indent="10"
    :default-expand-all="showExpand"
    @row-click="rowClick"
    @cell-click="cellClick"
    @selection-change="onSelectionChange"
    @expand-change="onExpandChange"
    @mousedown.native="onMouseDown"
    @mouseup.native="onMouseUp"
    @mousemove.native="onMouseMove"
    @mouseleave.native="onMouseLeave"
  >
    <!-- 行拓展内容: 将宽度设为1,是因为将控制权交给序号列以优化显示 -->
    <el-table-column
      type="expand"
      width="1"
      class-name="ui-table-expand-column"
    >
      <template slot-scope="{ row, $index }">
        <slot
          name="expand"
          v-bind="{ row, key: row[config.get('rowKey', 'id')], index: $index }"
        ></slot>
      </template>
    </el-table-column>
    <!-- 多选列 -->
    <el-table-column
      v-if="config.get('selectable')"
      align="center"
      type="selection"
      width="50"
      :reserve-selection="true"
    />
    <!-- 序号列: 当有拓展内容时,序号列可作为拓展内容显示/隐藏的开关 -->
    <el-table-column
      v-if="config.get('order', true)"
      align="center"
      prop="rowIndex"
      label="序号"
      width="80"
      :formatter="orderRender"
    />
    <!-- 内容列 -->
    <template v-for="s in schemas">
      <el-table-column
        v-if="!s.hideInTable"
        :key="s.dataIndex"
        :prop="s.dataIndex"
        :min-width="s.width"
        :formatter="formatter"
        align="center"
        showOverflowTooltip
      >
        <template slot="header">
          {{ s.title }}
          <span v-if="s.unit">({{ s.unit }})</span>
          <i
            v-if="s.tableIcon"
            :class="['header-icon', s.tableIcon]"
          ></i></template></el-table-column
    ></template>
    <!-- 操作列 -->
    <el-table-column
      v-if="controls.length"
      align="right"
      fixed="right"
      label="操作"
      :width="controlsWidth"
    >
      <template slot-scope="scope">
        <template v-for="(ctrl, index) in controls">
          <el-badge
            v-if="similar(scope.row, ctrl.show) && ctrl.message"
            :value="ctrl.message && getMessageCount(scope.row)"
            :key="index"
          >
            <el-button
              size="mini"
              :type="ctrl.type ? ctrl.type : 'default'"
              :disabled="!similar(scope.row, ctrl.able)"
              @click.stop="delegate(ctrl, scope.row)"
              >{{ ctrl.text }}</el-button
            >
          </el-badge>
          <!-- <el-button
            v-else-if="similar(scope.row, ctrl.show)"
            :key="index"
            size="mini"
            :type="ctrl.type ? ctrl.type : 'default'"
            :disabled="!similar(scope.row, ctrl.able)"
            @click.stop="delegate(ctrl, scope.row)"
            >{{ ctrl.text }}</el-button
          > -->
          <el-link
            v-else-if="similar(scope.row, ctrl.show)"
            :key="index + 's'"
            :underline="false"
            size="mini"
            class="btn-link"
            :type="ctrl.type ? ctrl.type : 'default'"
            :disabled="!similar(scope.row, ctrl.able)"
            @click.stop="delegate(ctrl, scope.row)"
            >{{ ctrl.text }}</el-link
          >
        </template>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import Config, { Control } from "../../lib/config";
import SchemaList from "../../lib/schema";
import Util from "../../lib/util";
import External from "../../lib/external";
import LabelField from "../form/label-field";
import UiCell from "../cell";
import { formMixin } from "./mixin";
export default {
  name: "ui-table",
  /**
   * 编辑相关操作使用mixin引入
   */
  mixins: [formMixin],
  props: {
    loading: Boolean,
    records: Array,
    config: Config,
    columns: {
      type: Array,
      default: () => [],
    },
    editable: Boolean,
    showExpand: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const schemas = new SchemaList(this.columns);
    /**
     * 用户权限验证
     */
    const controls = this.config.controls.filter((ctrl) =>
      External.hasPerm(ctrl.permissions)
    );
    /**
     * 计算操作列的宽度
     *
     * TODO: 可以考虑添加controlsWidth参数以强制操作列宽度
     */
    const baseWidth = 30; // 基础宽度
    const buttonPadding = 15 * 2; // 按钮内边距
    const buttonMargin = 10; // 按钮外边距
    const wordLength = 12; // 单个文字长度

    const controlsWidth = controls?.reduce(
      (width, ctrl) =>
        width + wordLength * ctrl.text?.length + buttonPadding + buttonMargin,
      baseWidth
    );

    return {
      schemas,
      controls,
      controlsWidth,

      selections: [],
      expandedRows: [],
      currentRow: null,
      /**
       * 是否可以触发点击事件
       */
      canEmitClick: true,
      scrollParams: {},
      headerStyle: {
        background: "#F8F9FF",
        color: "#333",
      },
    };
  },
  computed: {
    /**
     * 是否有expand插槽
     */
    hasExpand() {
      return !!this.$scopedSlots.expand;
    },
  },
  methods: {
    // 消息数量
    getMessageCount(row) {
      let col = this.columns.filter((i) => i.valueType == "message")[0];
      if (col) {
        return row[col.dataIndex] > 0 ? row[col.dataIndex] : "";
      }
      return "";
    },
    /**
     * 表格按下事件
     *
     * - 表格长按时不触发点击事件,用于优化用户体验,可根据需求修改
     */
    onTableMouseDown() {
      this.canEmitClick = true;
      setTimeout(() => {
        this.canEmitClick = false;
      }, 200);
    },
    onMouseDown(e) {
      this.canEmitClick = true;
      setTimeout(() => {
        this.canEmitClick = false;
      }, 200);
      const table = this.$refs.table;
      const el = table.$el;
      let tableBody = el.getElementsByClassName("el-table__body")[0];
      this.scrollParams.width =
        tableBody.offsetWidth || parseInt(tableBody.style.width);
      this.scrollParams.startScrollLeft = el.getElementsByClassName(
        "el-table__body-wrapper"
      )[0].scrollLeft;
      this.scrollParams.startX = e.clientX;
    },
    onMouseMove(e) {
      const { startScrollLeft = 0, startX = 0, width } = this.scrollParams;
      if (width) {
        let scrollLeft = startScrollLeft + startX - e.clientX;

        const table = this.$refs.table;
        const el = table.$el.getElementsByClassName(
          "el-table__body-wrapper"
        )[0];
        el.scrollLeft = Math.min(width, Math.max(0, scrollLeft));
      }
    },
    onMouseUp() {
      this.scrollParams = {};
    },
    onMouseLeave() {
      this.scrollParams = {};
    },
    /**
     * 表格的选中修改事件
     *
     * - 注意: values内的值是复制的对象,修改行内数据并不会同步到selections
     */
    onSelectionChange(values = []) {
      this.selections = values;
    },
    onExpandChange(_, expandedRows = []) {
      this.expandedRows = expandedRows;
      this.$emit("onExpandChange", _, expandedRows);
    },
    rowClick(row, col, e) {
      if (e.target.nodeName === "INPUT") return;
      if (this.canEmitClick) {
        this.currentRow = row;
        if (this.config.get("selectable")) {
          this.$refs.table.toggleRowSelection(row);
        }
        if (this.config.get("rowClick")) {
          this.$emit(
            "delegate",
            new Control(this.config.get("rowClick"), this.config),
            row
          );
        }
        this.$emit("rowClick", row);
      }
    },
    cellClick(row, { property }) {
      if (this.config.get(`cellClick.${property}`)) {
        this.canEmitClick = false;
        this.$emit(
          "delegate",
          new Control(this.config.get(`cellClick.${property}`), this.config),
          row
        );
      }
      if (property === "rowIndex" && this.hasExpand) {
        this.$refs.table.toggleRowExpansion(row);
      }
    },
    rowClassName({ row, rowIndex }) {
      row.rowIndex = rowIndex;
    },
    cellClassName({ column, rowIndex }) {
      if (this.inError[rowIndex]?.[column.property]) {
        return "is-error";
      }
    },
    /**
     * 判断该行数据是否满足某个条件
     *
     * @param {object} row 行数据
     * @param {string | regexp} able 判断条件
     */
    similar(row, able) {
      const judge =
        !able ||
        Object.entries(able).every(([key, rule]) => {
          let r = rule instanceof Function ? rule() : rule;
          return Util.similar(String(Util.chainValue(row, key)), r);
        });
      return judge;
    },
    /**
     * 点击事件代理
     *
     * @param {Control} control
     * @param {object} row
     */
    delegate(control, row) {
      if (this.similar(row, control.able)) {
        this.$emit("delegate", control, row);
      }
    },
    /**
     * 序号列内容
     */
    orderRender({ rowIndex }) {
      if (this.hasExpand) {
        const expanded = this.expandedRows.some(
          (item) => item.rowIndex === rowIndex
        );
        return (
          <div class="ui-table__expand-order">
            <div class="ui-table__expand-order-icon">
              <i
                class={expanded ? "el-icon-arrow-down" : "el-icon-arrow-right"}
              ></i>
            </div>
            {rowIndex + 1}
          </div>
        );
      }
      return rowIndex + 1;
    },
    /**
     * 格式化单元格内容
     */
    formatter(row, column, cellValue) {
      const { rowIndex } = row;
      const schema = this.schemas.find(
        (item) => item.dataIndex === column.property
      );
      const { chainIndex, listener, fieldProps } = schema;
      //fieldProps.listener 一定要加这个判断，否则会影响form校验！！！
      if (fieldProps.listener && listener) {
        const tempdata = this.tempdata[rowIndex];
        const params = {
          chaindata: this.records[rowIndex],
          setSchema: (s) => {
            for (let sk in s) {
              // @ts-ignore
              schema[sk] = s[sk];
            }
          },
        };
        if (typeof listener === "string") {
          /**
           * 单体监听
           */
          const [key, property] = listener.split(".");
          this.updateField(rowIndex, chainIndex, tempdata[key]?.[property]);
        } else {
          /**
           * 指定监听
           */
          Object.entries(listener).forEach(async ([key, fn]) => {
            fn.call(schema, params);
          });
        }
      }
      if (this.editable && schema.editable !== false) {
        return (
          <LabelField
            ref={`${schema.chainIndex}-${rowIndex}`}
            size="mini"
            schema={schema}
            initialValue={cellValue}
            tempdata={this.tempdata[rowIndex]}
            chaindata={row}
            onChange={(val) => {
              this.changeField(rowIndex, schema, val);
            }}
            onEmitListener={(params) => {
              this.emitListener(rowIndex, params);
            }}
          />
        );
      } else {
        return <UiCell data={row} value={cellValue} schema={schema} />;
      }
    },
    updateSchema(columns) {
      this.schemas = new SchemaList(columns);
      console.log("更新schema", this.schemas);
      this.$forceUpdate();
    },
    // changeField(rowIndex, schema, val){
    //   this.$emit('change',{rowIndex, schema, val})
    // }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ui-table-expand-column {
  visibility: hidden;
  overflow: hidden;
}

.ui-table__expand-order {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .ui-table__expand-order-icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;
    font-size: 12px;
  }
}

::v-deep .el-table__expanded-cell {
  padding: 12px 24px;
}

::v-deep .el-table__cell.is-error .el-input__inner {
  border-color: #f56c6c;
}

::v-deep .cell {
  .header-icon {
    margin-left: 3px;
  }
}

::v-deep .el-badge {
  margin-left: 10px;
}

::v-deep .el-badge__content {
  z-index: 9;
  top: 8px;
}
.btn-link {
  margin-right: 20px;
}
.btn-link:last-child {
  margin: 0;
}
::v-deep .el-table__row .el-table__cell:nth-child(2) {
  .cell {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: visible;
  }
}
</style>
