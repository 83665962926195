import Vue from 'vue'
import Util from '../../lib/util'
import SchemaList from '../../lib/schema'

type EmitListenerParams = {
  /**
   * 新值
   */
  newdata: Record<string, any>
  /**
   * 旧值
   */
  olddata: Record<string, any>
  /**
   * 触发者
   */
  launcher?: string
}

export const formMixin = Vue.extend<any, any, any, any>({
  data() {
    return {
      tempdata: [],
      inError: [],
    }
  },
  watch: {
    records: {
      immediate: true,
      handler(newVal = []) {
        for (let i = 0; i < newVal.length; i++) {
          this.tempdata[i] = this.tempdata[i] || {}
          // this.emitListener(i, { newdata: newVal[i] })
        }
      },
    },
  },
  methods: {
    updateRules() {
      const schemas: any = new SchemaList(this.columns)
      schemas.reduce((result: any, schema: any) => {
        const { chainIndex, rule } = schema
        result[chainIndex] = rule
        return result
      }, {} as Record<string, Field.Rule[]>)
    },
    /**
     * 由field发起
     */
    changeField(rowIndex: number, schema: IntactSchema, value: any) {
      const { chainIndex } = schema
      const oldValue = this.records[rowIndex]?.[chainIndex]
      if (Util.notNull(value, oldValue)) {
        if (value !== oldValue) {
          /**
           * TODO
           *
           * this.$set(this.records, rowIndex, {...this.records[rowIndex], [chainIndex]: value})
           * 上述写法将当前行对象重置,之前对该行的选中会失效,但值不会从selections中删去,导致selections出现重复数据
           */
          this.records[rowIndex][chainIndex] = value
          this.$set(this.records, rowIndex, this.records[rowIndex])
          this.validateField(rowIndex, schema, value)
          this.emitListener(rowIndex, {
            newdata: this.records[rowIndex],
            launcher: chainIndex,
          })
          this.$emit('change', { rowIndex, schema, val: value })
        }
      }
    },
    /**
     * 发起方: form
     */
    updateField(rowIndex: number, chainIndex: Key, value: any) {
      const fieldRef = this.$refs[`${chainIndex}-${rowIndex}`]
      if (fieldRef) {
        fieldRef['update']?.(value)
      } else {
        this.records[rowIndex][chainIndex] = value
      }
    },
    /**
     * 表单验证
     */
    async validate() {
      const records = this.config.get('selectable')
        ? this.selections
        : this.records
      const result: Record<string, any>[] = []

      for (let i = 0; i < records.length; i++) {
        const record = records[i]
        // 不带默认值的写法
        // const rowdata: Record<string, any> = { }
        const rowdata: Record<string, any> = { ...record }

        for (let j = 0; j < this.schemas.length; j++) {
          const schema = this.schemas[j]
          const { dataIndex, chainIndex, validate } = schema

          if (this.validateField(i, schema, record[chainIndex])) {
            rowdata[dataIndex] = record[chainIndex]
            await validate?.call(schema, {
              value: record[chainIndex],
              setValue: (v: any) => (rowdata[dataIndex] = v),
              chaindata: record,
              tempdata: this.tempdata[i],
            })
          } else {
            Util.error(`${chainIndex}校验失败`)
            throw null
          }
        }
        result.push(rowdata)
      }
      return result
    },
    validateField(index: number, schema: IntactSchema, value: any) {
      const { chainIndex, rule, editable, required } = schema
      let isError = false
      if (editable) {
        if (required) {
          if (Util.isNull(value)) {
            isError = true
          }
        }
        this.$set(this.inError, index, {
          ...this.inError[index],
          [chainIndex]: isError,
        })
      }
      return !isError
    },
    /**
     * 更新监听
     */
    emitListener(
      index: number,
      { newdata, olddata = {}, launcher }: EmitListenerParams
    ) {
      const tempdata = this.tempdata[index]
      this.schemas.forEach((schema: IntactSchema) => {
        const { chainIndex, listener } = schema
        if (listener) {
          const params: Field.ListenerParams = {
            chaindata: newdata,
            tempdata,
            // initialValues: this.initialValues,
            setValue: (data) => {
              for (let key in data) {
                this.updateField(index, key, data[key])
              }
            },
            setSchema: (s) => {
              // console.log(s, 's')
              // for (let sk in s) {
              //   // @ts-ignore
              //   schema[sk] = s[sk]
              //   this.updateRules()
              // }
            },
          }

          if (typeof listener === 'string') {
            /**
             * 单体监听
             */
            const [key, property] = listener.split('.')
            ;(!launcher || launcher === key) &&
              // this.chainIndexs.indexOf(key) !== -1 &&
              this.updateField(index, chainIndex, tempdata[key]?.[property])
          } else if (typeof listener === 'function') {
            /**
             * 整体监听
             */
            listener.call(schema, params)
          } else {
            /**
             * 指定监听
             */
            Object.entries(listener).forEach(async ([key, fn]) => {
              if (newdata[key] !== olddata[key]) {
                ;(!launcher || launcher === key) && fn.call(schema, params)
              }
            })
          }
        }
      })
    },
  },
})
