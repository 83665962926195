<template>
  <el-autocomplete
    clearable
    v-model="text"
    :fetch-suggestions="fetch"
    :trigger-on-focus="false"
    @select="handleSelect"
    @clear="handleSelect"
  >
    <template v-if="schema.fieldProps.fuzzyDesc" slot-scope="{ item }">
      <div class="name">{{ item.value }}</div>
      <span style="font-size: 12px; color: #a1a1a1">{{
        item.data[schema.fieldProps.fuzzyDesc]
      }}</span>
    </template>
  </el-autocomplete>
</template>

<script>
import Util from '@/ui/lib/util'
export default {
  name: 'fuzzy-field',
  props: {
    schema: Object,
    chaindata: Object,
    value: {},
    tempdata: Object,

    initialValues: Object,
  },
  data() {
    const {
      fieldProps: { initialText },
    } = this.schema
    return {
      text: this.initialValues?.[initialText],
    }
  },
  watch: {
    //监听value 赋值
    value: {
      deep: true,
      immediate: true,
      handler(newVal) {
        const {
          fieldProps: { initialText },
        } = this.schema
        if (initialText) {
          this.text =
            this.initialValues?.[initialText] ||
            this.chaindata?.[initialText] ||
            ''
        }
      },
    },
    //监听依赖项 依赖项更新 清空当前value和text
    chaindata: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        const { dependencies } = this.schema
        if (
          newVal[dependencies] &&
          oldVal[dependencies] &&
          oldVal[dependencies] !== newVal[dependencies]
        ) {
          this.text = ''
          this.$emit('input', null)
        }
      },
    },
  },
  methods: {
    async fetch(text, callback) {
      const { dependencies, request } = this.schema

      if (!dependencies || Util.notNull(this.chaindata[dependencies])) {
        const list =
          (await request?.({
            keyWord: text,
            chaindata: this.chaindata,
          })) || []
        callback(
          list.map((item) => ({
            value: item.label,
            code: item.value,
            data: item,
          }))
        )
      } else {
        callback([])
      }
    },
    handleSelect(val) {
      const { chainIndex } = this.schema
      this.tempdata[chainIndex] = val?.data
      this.$emit('input', val?.code)
    },
  },
}
</script>

<style></style>
