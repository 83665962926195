<template>
  <div class="market">
    <div class="nav fl al-c">
      <div class="nav-item active">钢坯</div>
      <div class="nav-item">焦炭</div>
      <div class="nav-item">铁矿石</div>
      <div class="nav-item">重废</div>
    </div>
    <div class="unit">价格：元</div>
    <div class="echart" ref="echart"></div>

    <div class="grid grid-title">
      <div class="grid-item">日期</div>
      <div class="grid-item">价格</div>
      <div class="grid-item">涨跌</div>
      <div class="grid-item">涨跌百分比</div>
    </div>
    <div class="grid grid-content" v-for="(item, index) in datas" :key="index">
      <div class="grid-item">{{ item.date }}</div>
      <div class="grid-item">{{ item.price }}</div>
      <div class="grid-item">{{ item.zhangdie }}</div>
      <div class="grid-item num">{{ item.per }}</div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { options } from "./options";
export default {
  data() {
    return {
      echart: null,
      options,
      datas: [
        {
          date: "2023/02/13",
          zhangdie: 30,
          price: 4510,
          per: "0.8%",
        },
        {
          date: "2023/02/13",
          zhangdie: 30,
          price: 4510,
          per: "0.6%",
        },
        {
          date: "2023/02/13",
          zhangdie: 30,
          price: 4510,
          per: "0.5%",
        },
        {
          date: "2023/02/13",
          zhangdie: 40,
          price: 4510,
          per: "30%",
        },
        {
          date: "2023/02/13",
          zhangdie: -35,
          price: 4510,
          per: "-1.06%",
        },
        {
          date: "2023/02/13",
          zhangdie: 0,
          price: 4510,
          per: 30,
        },
      ],
    };
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      this.echart = echarts.init(this.$refs["echart"]);
      this.echart.setOption(this.options);
    },
  },
};
</script>

<style scoped>
.market {
  margin-top: 12px;
}
.nav {
  font-size: 14px;
  line-height: 20px;
  color: #666;
  text-align: right;
  justify-content: flex-end;
}
.nav-item {
  margin-right: 20px;
  padding: 2px 0;
}
.nav-item:last-child {
  margin: 0;
}
.nav-item.active {
  color: #3c9cff;
}
.unit {
  text-align: right;
  line-height: 17px;
  color: #999;
  margin: 10px 0;
}
.echart {
  height: 160px;
}

.grid {
  display: grid;
  grid-template-columns: 100px 48px 48px 68px;
  justify-content: space-between;
}
.grid-title {
  margin-top: 30px;
  color: #999;
  border-bottom: 1px solid #f0f0f0;
}
.grid-item {
  text-align: right;
  line-height: 38px;
}
.grid-item:first-child {
  text-align: left;
}
.grid-item.num {
  color: #bb271a;
}
.grid-content {
  font-weight: bold;
  color: #333;
  border-bottom: 1px solid #f0f0f0;
}
.grid-content:last-child {
  border: 0;
}
</style>
