import axios from '../index'

export default {
  /**
   * 登录
   */
  login(data:any) {
    return axios(
      {
        url: '/login/login',
        method: 'POST',
        data,
      },
      {
        aloneBaseUrl: true,
      }
    )
  },
  /**
   * 注册
   */
  register(data:any) {
    return axios(
      {
        url: '/oauth/noToken/register',
        method: 'POST',
        data,
      },
      {
        aloneBaseUrl: true,
      }
    )
  },
  /**
   * 退出登录
   */
  logout() {
    return axios(
      {
        url: '/login/logout',
        method: 'GET',
      },
      {
        aloneBaseUrl: true,
      }
    )
  },
}
