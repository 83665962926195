export default {
  order: 13,
  label: "文本编辑",
  head: "fa fa-",
  list: [
    "align-center",
    "align-justify",
    "align-left",
    "align-right",
    "bold",
    "chain",
    "chain-broken",
    "clipboard",
    "columns",
    "copy",
    "cut",
    "dedent",
    "eraser",
    "file",
    "file-o",
    "file-text",
    "file-text-o",
    "files-o",
    "floppy-o",
    "font",
    "header",
    "indent",
    "italic",
    "link",
    "list",
    "list-alt",
    "list-ol",
    "list-ul",
    "outdent",
    "paperclip",
    "paragraph",
    "paste",
    "repeat",
    "rotate-left",
    "rotate-right",
    "save",
    "scissors",
    "strikethrough",
    "subscript",
    "superscript",
    "table",
    "text-height",
    "text-width", "th",
    "th-large",
    "th-list",
    "underline",
    "undo",
    "unlink",
  ]
}