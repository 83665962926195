<template>
  <pre class="ui-codearea" :contenteditable="editable"></pre>
</template>

<script>
export default {
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
  },
  name: 'ui-codearea',
}
</script>

<style lang="scss" scoped>
.ui-codearea {
  min-width: 200px;
  min-height: 200px;
  background-color: #ffffff;
  box-sizing: border-box;
}
</style>
