import { ControlTypeEnum } from '@/typings/enum'
import * as actions from './actions'
import button from './button'
import Util from '../util'

export class Control<T> implements OutputTable.Control<T> {
  static $id = 1
  static $modalId = 1
  static button = button
  static $actions: Record<string, ControlAction> = actions

  key: string | number

  text: string
  icon?: string
  type: `${ControlTypeEnum}`
  message: string | number
  alignLeft?: boolean
  noPadding?: boolean

  prepare?(params: PrepareParams): void | Promise<void>

  sections: OutputTable.Control<T>['sections'] = []

  modal?: OutputTable.Modal
  view?: OutputTable.View

  able?: Record<string, string | RegExp>
  show?: Record<string, string | RegExp | Object>
  permissions?: Perms

  constructor(build: InputTable.Control<T>, config: ConfigType<T>) {
    if (build.action) {
      build =
        Control.$actions[build.action]?.({ ...build, action: undefined }) || {}
    }

    const { text = '', icon, type = 'primary', message = '' } = build

    this.key = ++Control.$id
    this.text = text
    this.icon = icon
    this.type = type
    this.alignLeft = false
    this.noPadding = true
    this.message = message

    const { able, show, permissions, prepare } = build

    this.able = able
    this.show = show
    this.permissions = Control.setPermissions(permissions, config.perm)
    this.prepare = prepare

    const { form, descriptions, context } = build

    const _sections: InputTable.Control<T>['sections'] = [
      { form },
      { descriptions },
      { context },
      ...(build.sections || []),
    ]

    for (let i = 0; i < _sections.length; i++) {
      const section = _sections[i]
      if (section.form) {
        this.sections.push({
          ...section,
          form: Control.setForm<T>(section.form),
        })
        continue
      }
      if (section.descriptions) {
        this.sections.push({
          ...section,
          descriptions: Control.setDescriptions<T>(section.descriptions),
        })
        continue
      }
      if (section.context) {
        this.sections.push({
          ...section,
          context: config.contextComponents?.[section.context],
        })
        continue
      }
    }

    const { modal, view } = build

    this.modal = Control.setModal(modal)
    this.view = this.setView(view)
  }

  static action(actionType: `${ActionTypeEnum}`, fn: ControlAction) {
    Control.$actions[actionType] = fn
  }

  static setPermissions(
    permissions?: InputTable.Control['permissions'],
    basePerm: string = ''
  ): OutputTable.Control['permissions'] | undefined {
    if (permissions) {
      if (Array.isArray(permissions)) {
        return {
          hasAny: permissions.map((p) => p.replace(/_/, basePerm)),
        }
      } else {
        //
      }
    }
  }

  static setForm<T>(
    form?: InputTable.ModalForm<T>
  ): OutputTable.ModalForm<T> | undefined {
    if (Array.isArray(form)) {
      return {
        columns: form,
      }
    } else {
      return form
    }
  }

  static setDescriptions<T>(
    descriptions?: InputTable.ModalDescriptions<T>
  ): OutputTable.ModalDescriptions<T> | undefined {
    if (Array.isArray(descriptions)) {
      return {
        columns: descriptions,
      }
    } else {
      return descriptions
    }
  }

  static setModal(modal?: InputTable.Modal): OutputTable.Modal | undefined {
    if (modal) {
      return {
        mode: 'dialog',
        id: ++Control.$modalId,
        title: '对话框',
        width: 900,
        footer: [Control.button('cancel'), Control.button('sure')],
        ...modal,
      }
    }
  }

  setView(view?: InputTable.View): OutputTable.View | undefined {
    if (view) {
      const { title } = view
      return {
        ...view,
        title: typeof title === 'string' ? () => title : title,
      }
    }
  }
}

type ExtraConfig = {
  perm?: string
  canBack?: boolean
  contexts?: Record<string, Vue.Component>
}

export default class Config<T, P extends Record<string, any>>
  implements ConfigType<T, Record<string, any>>
{
  contextComponents?: Record<string, Vue.Component>

  perm?: string

  searchs?: OutputTable.ModalForm<T>
  controls: OutputTable.Control<T>[] = []
  toolbar: OutputTable.Control<T>[] = []
  title?: OutputTable.Title
  contexts?: Partial<Record<`${ContextTypeEnum}`, string>>;

  [key: string]: any

  constructor(build: BaseConfig<T, P>, extra?: ExtraConfig) {
    this.load(build, extra)
  }

  load(build: BaseConfig<T, P>, extra: ExtraConfig = {}) {
    for (const key in build) {
      this[`_${key}`] = build[key]
    }

    const { perm: e_perm, canBack: e_canBack, contexts: ccs } = extra
    const {
      perm,
      searchs,
      controls = [],
      toolbar = [],
      title,
      contexts,
    } = build

    this.perm = perm || e_perm
    this.contextComponents = ccs
    this.searchs = Control.setForm(searchs)
    this.contexts = contexts

    this.controls = controls
      .filter((c) => c)
      .map((ctrl) => new Control<T>(ctrl, this))
    this.toolbar = toolbar
      .filter((c) => c)
      .map((ctrl) => new Control<T>(ctrl, this))

    if (typeof title === 'string') {
      this.title = {
        canBack: e_canBack,
        content: () => title,
      }
    } else if (typeof title === 'function') {
      this.title = {
        canBack: e_canBack,
        content: title,
      }
    } else {
      this.title = title
    }
  }

  get<V = any>(key: string, initialValue?: V): V | undefined {
    return Util.chainValue(this, `_${key}`, initialValue)
  }

  context(name: `${ContextTypeEnum}`) {
    const contextName = this.contexts?.[name]
    if (contextName) {
      return this.contextComponents?.[contextName]
    }
  }
}
