import Vue from 'vue'
import SchemaList from '../lib/schema'
import { FormMixinType } from './data'

type EmitListenerParams = {
  /**
   * 新值
   */
  newdata: Record<string, any>
  /**
   * 旧值
   */
  olddata: Record<string, any>
  /**
   * 触发者
   */
  launcher?: string
}

const formMixin = Vue.extend<FormMixinType.Data, any, any, any>({
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    ndiv: Number,
    initialValues: Object,
  },
  data() {
    /**
     * 统一: schema 的传入值使用普通对象,可以使用ts校验防止出错,传入组件之后由组件生成可用的 IntactSchema
     *      目的是防止多个组件使用同一个 schema 时,修改某一个组件 schema 时,因为引用赋值导致其他组件 schema 发生变化
     */
    const schemas: any = new SchemaList(this.columns)
    const chainIndexs = schemas.map((s: any) => s.chainIndex)
    /**
     * 规则校验初始化
     *
     * - 注意: 不推荐动态改变 columns/initialValues 传参,以触发页面更新
     *          推荐当 columns/initialValues 发生改变时,组件外触发 resetField 方法触发页面更新
     *        原因是被动触发更新,开发人员无法掌握更新过程,不易维护,并且可能会产生未知异常
     */
    const rules = schemas.reduce((result: any, schema: any) => {
      const { chainIndex, rule } = schema
      result[chainIndex] = rule
      return result
    }, {} as Record<string, Field.Rule[]>)
    return {
      schemas,
      chainIndexs,
      rules,
      column: this.ndiv || 2,
      /**
       * 组件key
       *
       * - 用于控制 form 组件的重置和更新
       */
      formKey: 1,
      /**
       * 临时值
       *
       * - 选择组件等更改时会有对象被选中,这些对象不会用于上传,但可能会用于组件内部使用,所以暂时保存在该对象里
       */
      tempdata: {},
      /**
       * 组件内部表单值
       *
       * - chain概念: 一般情况下,表单索引 dataIndex 使用单一字符串,如: 'name',
       *             但为了提升该组件的功能,以支持 'sex.code' 这种 dataIndex,引入了 chain 概念
       * - chain用法: dataIndex: 'sex.code' <=> chainIndex: 'sex__code' (该部分可优化)
       *             大部分 dataIndex 和 chainIndex 相关的转换已封装在 Util 类里
       * - 注意: 组件内部推荐使用 chainIndex 和 chaindata,不推荐使用 dataIndex
       *        组件外部获取表单值,推荐使用 validate 方法,chaindata 仅用于表单输入过程中,外部组件需要使用表单值的情况下
       */
      chaindata: {},
    }
  },
  watch: {
    chaindata: {
      immediate: true,
      handler(newdata, olddata) {
        this.$emit('change', newdata)
        this.emitListener({ newdata, olddata })
      },
    },
  },
  mounted() {
    this.createObserver()
  },
  methods: {
    updateRules() {
      const schemas: any = new SchemaList(this.columns)
      schemas.reduce((result: any, schema: any) => {
        const { chainIndex, rule } = schema
        result[chainIndex] = rule
        return result
      }, {} as Record<string, Field.Rule[]>)
    },
    /**
     * 重置
     */
    resetFields() {
      this.formKey++
      this.chaindata = {}
      this.tempdata = {}
      this.createObserver()
    },
    /**
     * 动态大小监听
     *
     * - 动态监听容易标签大小变化,以调整 column 值的大小
     * - 由于现在页面重置时修改 formKey,重置完之后容器标签也会重置,所以要重新监听
     */
    createObserver() {
      if (this.ndiv) return
      this.$nextTick(() => {
        const formElement = this.$refs.form?.$el
        if (formElement) {
          if (!this.resizeObserver) {
            // @ts-ignore
            this.resizeObserver = new ResizeObserver((entries: any[]) => {
              entries.forEach((entry) => {
                const { width } = entry.contentRect
                const ratio = Math.max(width / (this.labelWidth + 208), 1)
                this.column = ratio > 2 ? Math.floor(ratio) : ratio
              })
            })
          }
          this.resizeObserver.disconnect()
          this.resizeObserver.observe(formElement)
        }
      })
    },
    /**
     * 由form发起
     *
     * @param {keyof T} chainIndex
     * @param {any} value
     */
    updateField(chainIndex: string, value: any) {
      if (this.$refs[chainIndex]) {
        this.$refs[chainIndex]?.[0]?.['update']?.(value)
      } else {
        this.chaindata[chainIndex] = value
      }
    },
    /**
     * 更新监听
     */
    emitListener({ newdata, olddata = {}, launcher }: EmitListenerParams) {
      this.schemas.forEach((schema: IntactSchema) => {
        const { chainIndex, listener } = schema
        if (listener) {
          const params: Field.ListenerParams = {
            chaindata: newdata,
            tempdata: this.tempdata,
            initialValues: this.initialValues,
            setValue: (data) => {
              for (let key in data) {
                this.updateField(key, data[key])
              }
            },
            setSchema: (s) => {
              for (let sk in s) {
                // @ts-ignore
                schema[sk] = s[sk]
                this.updateRules()
              }
            },
          }

          if (typeof listener === 'string') {
            /**
             * 单体监听
             */
            const [key, property] = listener.split('.')
            ;(!launcher || launcher === key) &&
              this.chainIndexs.indexOf(key) !== -1 &&
              this.updateField(chainIndex, this.tempdata[key]?.[property])
          } else if (typeof listener === 'function') {
            /**
             * 整体监听
             */
            listener.call(schema, params)
          } else {
            /**
             * 指定监听
             */
            Object.entries(listener).forEach(async ([key, fn]) => {
              if (newdata[key] !== olddata[key]) {
                ;(!launcher || launcher === key) && fn.call(schema, params)
              }
            })
          }
        }
      })
    },
  },
})

export default formMixin
