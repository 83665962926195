<template>
  <div class="ui-descriptions-group" v-loading="loading">
    <ui-descriptions
      v-for="(record, index) in records"
      :key="index"
      :size="size"
      :ndiv="ndiv"
      :columns="columns"
      :border="border"
      :editable="editable"
      :config="config"
      @request="(cb) => cb(record)"
    >
      <el-divider v-if="index !== records.length - 1"
    /></ui-descriptions>
    <div v-if="!records.length" class="empty">暂无数据 !</div>
  </div>
</template>

<script>
import UiDescriptions from '../descriptions'
export default {
  name: 'ui-descriptions-group',
  components: { UiDescriptions },
  props: {
    size: {
      type: String,
      default: 'medium',
    },
    ndiv: {
      type: Number,
      default: 1,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    border: {
      type: Boolean,
      default: false,
    },
    editable: Boolean,
    config: {
      type: Object,
      default: () => {
        return {
          alignLeft: false,
          noPadding: true,
        }
      },
    },
  },
  data() {
    return {
      loading: false,
      records: [],
    }
  },
  created() {
    this.update()
  },
  methods: {
    /**
     * 更新
     */
    update() {
      this.records.splice(0)
      this.loading = true
      this.$emit('request', (records) => {
        console.log(records)
        if (Array.isArray(records)) {
          this.records.push(...records)
        } else if (Array.isArray(records.data)) {
          this.records.push(...records.data)
        }
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-descriptions-group {
  .empty {
    width: 100%;
    text-align: center;
    color: #cccccc;
    user-select: none;
  }
}
</style>
