<script>
import UiCell from "../../cell";
import CascaderField from "./modules/cascader-field.vue";
import SelectField from "./modules/select-field.vue";
import FuzzyField from "./modules/fuzzy-field.vue";
import FileField from "./modules/file-field.vue";
import TreeField from "./modules/tree-field.vue";
import CarField from "./modules/car-field.vue";
import FuzzySelect from "./modules/fuzzy-select.vue";
import CheckboxField from "./modules/checkbox-field.vue";
export default {
  name: "label-field",
  props: {
    schema: Object,
    initialValue: {},
    tempdata: Object,
    chaindata: Object,
    initialValues: Object,
    readonly: Boolean,
    size: String,
  },
  data() {
    return {
      value: this.initialValue,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        // console.log(newVal, 'new')
        this.$emit("change", newVal);
      },
    },
  },
  methods: {
    /**
     * 向下赋值,使用函数赋值,使赋值更加规范和统一,方便查找问题和修改
     */
    update(value) {
      // console.log("update",value)
      if (value !== this.value) {
        const fieldRef = this.$refs[this.schema.chainIndex];
        if (fieldRef) {
          fieldRef.updateField?.(value);
        } else {
          this.value = value;
        }
      }
    },
    /**
     * 向上触发更新,实现联动
     */
    emitListener(params) {
      this.$emit("emitListener", {
        newdata: this.chaindata,
        launcher: this.schema.chainIndex,
        ...params,
      });
    },
  },
  render() {
    const { chainIndex, valueType, fieldProps, valueEnum, placeholder, unit } =
      this.schema;
    const readonly = this.readonly || this.schema.readonly;

    const field = () => {
      switch (valueType) {
        case "carNo":
          return (
            <CarField
              vModel={this.value}
              tempdata={this.tempdata}
              schema={this.schema}
              chaindata={this.chaindata}
              onEmitListener={this.emitListener}
            />
          );
        case "switch":
          return (
            <el-switch
              vModel={this.value}
              size={this.size}
              disabled={readonly}
              active-color="#7EC542"
              active-text={valueEnum["true"]?.text}
              inactive-text={valueEnum["false"]?.text}
            />
          );
        case "file":
          return (
            <FileField
              vModel={this.value}
              size={this.size}
              readonly={readonly}
              schema={this.schema}
              chaindata={this.chaindata}
              tempdata={this.tempdata}
              initialValues={this.initialValues}
            />
          );
        case "textarea":
          return (
            <el-input
              type="textarea"
              vModel={this.value}
              size={this.size}
              disabled={readonly}
              autosize={{
                minRows: 4,
                maxRows: 8,
              }}
              clearable={true}
            />
          );
        case "color":
          return (
            <el-color-picker
              vModel={this.value}
              size={this.size}
              color-format={fieldProps.colorFormat}
            />
          );
        default:
          if (readonly) {
            return (
              <div class="readonly">
                <UiCell
                  data={this.chaindata}
                  value={this.value}
                  schema={this.schema}
                />
              </div>
            );
          }
      }

      switch (valueType) {
        case "date":
          return (
            <el-date-picker
              vModel={this.value}
              type="date"
              size={this.size}
              placeholder={placeholder || "选择日期"}
              placement="bottom-start"
              value-format={fieldProps.valueFormat || "yyyy-MM-dd HH:mm:ss"}
            />
          );
        case "time":
          return (
            <el-time-picker
              vModel={this.value}
              value-format="HH:mm:ss"
              size={this.size}
              picker-options={{
                selectableRange: "00:00:00 - 23:59:59",
              }}
              placeholder={placeholder || "任意时间点"}
            />
          );
        case "datetime":
          return (
            <el-date-picker
              type="datetime"
              vModel={this.value}
              value-format="yyyy-MM-dd HH:mm:ss"
              size={this.size}
              placeholder={placeholder || "选择日期和时间"}
            />
          );
        case "dateRange":
          return (
            <el-date-picker
              type="daterange"
              vModel={this.value}
              value-format={fieldProps.valueFormat || "yyyy-MM-dd HH:mm:ss"}
              size={this.size}
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          );
        case "select":
          return (
            <SelectField
              vModel={this.value}
              ref={chainIndex}
              size={this.size}
              tempdata={this.tempdata}
              schema={this.schema}
              chaindata={this.chaindata}
              onEmitListener={this.emitListener}
            />
          );
        case "checkboxField":
          return (
            <CheckboxField
              vModel={this.value}
              size={this.size}
              tempdata={this.tempdata}
              schema={this.schema}
              chaindata={this.chaindata}
              onEmitListener={this.emitListener}
            />
          );
        case "cascader":
          return (
            <CascaderField
              vModel={this.value}
              ref={chainIndex}
              size={this.size}
              schema={this.schema}
              tempdata={this.tempdata}
              chaindata={this.chaindata}
              onEmitListener={this.emitListener}
            />
          );
        case "treeSelect":
          return (
            <TreeField
              vModel={this.value}
              ref={chainIndex}
              size={this.size}
              schema={this.schema}
              tempdata={this.tempdata}
              chaindata={this.chaindata}
              onEmitListener={this.emitListener}
            />
          );
        case "fuzzy":
          return (
            <FuzzyField
              vModel={this.value}
              size={this.size}
              schema={this.schema}
              tempdata={this.tempdata}
              chaindata={this.chaindata}
              initialValues={this.initialValues}
              onEmitListener={this.emitListener}
            />
          );
        case "fuzzySelect":
          return (
            <FuzzySelect
              vModel={this.value}
              size={this.size}
              schema={this.schema}
              tempdata={this.tempdata}
              chaindata={this.chaindata}
              initialValues={this.initialValues}
              onEmitListener={this.emitListener}
            />
          );
        // case 'text':
        //   return (
        //     <el-input
        //       vModel={this.value}
        //       size={this.size}
        //       placeholder={placeholder}
        //       clearable
        //     >
        //       <template v-if="schema.unit" slot="append">
        //         {unit}
        //       </template>
        //     </el-input>
        //   )
        case "text":
          return (
            <div class="input-flex">
              <el-input
                vModel={this.value}
                size={this.size}
                placeholder={placeholder}
                clearable
              ></el-input>
              <span
                class="input-unit"
                style={this.schema.unit ? "margin-left:15px" : ""}
              >
                {this.schema.unit}
              </span>
            </div>
          );
        case "digit":
          return (
            <el-input-number vModel={this.value} size={this.size} min={0} />
          );
        case "password":
          return (
            <el-input
              vModel={this.value}
              placeholder={placeholder}
              size={this.size}
              show-password
              clearable
            />
          );
      }
    };

    const { tip } = this.schema;

    return tip ? (
      <el-tooltip content={tip} placement="top">
        {field()}
      </el-tooltip>
    ) : (
      field()
    );
  },
};
</script>
<style scoped>
.input-flex {
  width: 100%;
  display: flex;
  align-items: center;
}
</style>
