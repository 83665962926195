import { TenantTypeEnum } from '@/typings/enum'

/**
 * 公司信息
 */
export const tenantInfoColumns: Schema[] = [
  {
    dataIndex: 'idCardFront',
    title: '法人身份证正面',
    valueType: 'file',
    limit: 1,
    fieldProps: {
      initialUrl: 'idCardFrontFileUrl',
    },
    formFieldLayout:{
      colspan:2
    }
  },
  {
    dataIndex: 'idCardFrontFileUrl',
    title: '法人身份证正面',
    valueType: 'file',
    hideInForm: true,
  },
  {
    dataIndex: 'idCardBack',
    title: '法人身份证反面',
    valueType: 'file',
    limit: 1,
    fieldProps: {
      initialUrl: 'idCardBackFileUrl',
    },
    formFieldLayout:{
      colspan:2
    }
  },
  {
    dataIndex: 'idCardBackFileUrl',
    title: '法人身份证反面',
    valueType: 'file',
    hideInForm: true,
  },
  {
    dataIndex: 'businessInformation',
    title: '工商信息',
    valueType: 'file',
    limit: 1,
    required: false,
    hideInForm: true,
    listener: {
      type({ chaindata: { type }, setSchema }) {
        setSchema({
          hideInForm: !(
            type === TenantTypeEnum.WAREHOUSE || type === TenantTypeEnum.AGENCY
          ),
        })
      },
    },
    fieldProps: {
      initialUrl: 'businessInformationFileUrl',
    },
  },
  {
    dataIndex: 'businessInformationFileUrl',
    title: '工商信息',
    valueType: 'file',
    hideInForm: true,
  },
  {
    dataIndex: 'billingInformation',
    title: '开票信息',
    valueType: 'file',
    limit: 1,
    required: false,
    hideInForm: true,
    listener: {
      type({ chaindata: { type }, setSchema }) {
        setSchema({
          hideInForm: !(
            type === TenantTypeEnum.WAREHOUSE ||
            type === TenantTypeEnum.MERCHANT ||
            type === TenantTypeEnum.AGENCY ||
            type === TenantTypeEnum.TRANSPORTATION
          ),
        })
      },
    },
    fieldProps: {
      initialUrl: 'billingInformationFileUrl',
    },
  },
  {
    dataIndex: 'billingInformationFileUrl',
    title: '开票信息',
    valueType: 'file',
    hideInForm: true,
  },
  {
    dataIndex: 'storageQualification',
    title: '仓储资质',
    valueType: 'file',
    limit: 1,
    required: false,
    hideInForm: true,
    listener: {
      type({ chaindata: { type }, setSchema }) {
        setSchema({
          hideInForm: !(type === TenantTypeEnum.WAREHOUSE),
        })
      },
    },
    fieldProps: {
      initialUrl: 'storageQualificationFileUrl',
    },
  },
  {
    dataIndex: 'storageQualificationFileUrl',
    title: '仓储资质',
    valueType: 'file',
    hideInForm: true,
  },
  {
    dataIndex: 'businessLicense',
    title: '营业执照',
    valueType: 'file',
    limit: 1,
    // required: false,
    // hideInForm: true,
    listener: {
      type({ chaindata: { type }, setSchema }) {
        setSchema({
          hideInForm: !(
            type === TenantTypeEnum.MERCHANT ||
            type === TenantTypeEnum.AGENCY ||
            type === TenantTypeEnum.TRANSPORTATION
          ),
        })
      },
    },
    fieldProps: {
      initialUrl: 'businessLicenseFileUrl',
    },
    formFieldLayout:{
      colspan:2
    }
  },
  {
    dataIndex: 'businessLicenseFileUrl',
    title: '营业执照',
    valueType: 'file',
    hideInForm: true,
  },
  {
    dataIndex: 'creditInformation',
    title: '征信信息',
    valueType: 'file',
    limit: 1,
    required: false,
    hideInForm: true,
    listener: {
      type({ chaindata: { type }, setSchema }) {
        setSchema({
          hideInForm: !(type === TenantTypeEnum.MERCHANT),
        })
      },
    },
    fieldProps: {
      initialUrl: 'creditInformationFileUrl',
    },
  },
  {
    dataIndex: 'creditInformationFileUrl',
    title: '征信信息',
    valueType: 'file',
    hideInForm: true,
  },
  {
    dataIndex: 'warehousingAgreement',
    title: '仓储协议',
    valueType: 'file',
    limit: 1,
    required: false,
    hideInForm: true,
    listener: {
      type({ chaindata: { type }, setSchema }) {
        setSchema({
          hideInForm: !(
            type === TenantTypeEnum.MERCHANT || type === TenantTypeEnum.AGENCY
          ),
        })
      },
    },
    fieldProps: {
      initialUrl: 'warehousingAgreementFileUrl',
    },
  },
  {
    dataIndex: 'warehousingAgreementFileUrl',
    title: '仓储协议',
    valueType: 'file',
    hideInForm: true,
  },
  {
    dataIndex: 'agencyAgreement',
    title: '代理协议',
    valueType: 'file',
    limit: 1,
    required: false,
    hideInForm: true,
    listener: {
      type({ chaindata: { type }, setSchema }) {
        setSchema({
          hideInForm: !(type === TenantTypeEnum.AGENCY),
        })
      },
    },
    fieldProps: {
      initialUrl: 'agencyAgreementFileUrl',
    },
  },
  {
    dataIndex: 'agencyAgreementFileUrl',
    title: '代理协议',
    valueType: 'file',
    hideInForm: true,
  },
  {
    dataIndex: 'transportQualification',
    title: '运输资质',
    valueType: 'file',
    limit: 1,
    required: false,
    hideInForm: true,
    listener: {
      type({ chaindata: { type }, setSchema }) {
        setSchema({
          hideInForm: !(type === TenantTypeEnum.AGENCY),
        })
      },
    },
    fieldProps: {
      initialUrl: 'transportQualificationFileUrl',
    },
  },
  {
    dataIndex: 'transportQualificationFileUrl',
    title: '运输资质',
    valueType: 'file',
    hideInForm: true,
  },
  {
    dataIndex: 'leaseAgreement',
    title: '租赁协议',
    valueType: 'file',
    limit: 1,
    required: false,
    hideInForm: true,
    listener: {
      type({ chaindata: { type }, setSchema }) {
        setSchema({
          hideInForm: !(type === TenantTypeEnum.TRANSPORTATION),
        })
      },
    },
    fieldProps: {
      initialUrl: 'leaseAgreementFileUrl',
    },
  },
  {
    dataIndex: 'leaseAgreementFileUrl',
    title: '租赁协议',
    valueType: 'file',
    hideInForm: true,
  },
  {
    dataIndex: 'roadTransportPermit',
    title: '道路运输许可证',
    valueType: 'file',
    limit: 1,
    required: false,
    hideInForm: true,
    listener: {
      type({ chaindata: { type }, setSchema }) {
        setSchema({
          hideInForm: !(type === TenantTypeEnum.TRANSPORTATION),
        })
      },
    },
    fieldProps: {
      initialUrl: 'roadTransportPermitFileUrl',
    },
  },
  {
    dataIndex: 'roadTransportPermitFileUrl',
    title: '道路运输许可证',
    valueType: 'file',
    hideInForm: true,
  },
  {
    dataIndex: 'waterTransportPermit',
    title: '水路运输许可证',
    valueType: 'file',
    limit: 1,
    required: false,
    hideInForm: true,
    listener: {
      type({ chaindata: { type }, setSchema }) {
        setSchema({
          hideInForm: !(type === TenantTypeEnum.TRANSPORTATION),
        })
      },
    },
    fieldProps: {
      initialUrl: 'waterTransportPermitFileUrl',
    },
  },
  {
    dataIndex: 'waterTransportPermitFileUrl',
    title: '水路运输许可证',
    valueType: 'file',
    hideInForm: true,
  },
  {
    dataIndex: 'introduction',
    title: '简介',
    required: false,
    hideInForm: true,
    valueType: 'textarea',
  },
]
