<template>
  <div class="message">
    <div class="title fl al-c">
      <div class="title-item active">资讯</div>
      <!-- <div
        :class="['title-item', activeIndex == 1 ? 'active' : '']"
        @click="changeMessage(1)"
      >
        快讯
      </div> -->
      <div class="more" @click="moreClick">
        <i class="el-icon-more icon"></i>
      </div>
    </div>

    <ul class="msg" v-if="activeIndex == 0">
      <li
        class="msg-item"
        v-for="item in zList"
        :key="item.id"
        @click="toDetail(item.id)"
      >
        <div class="content text-el">{{ item.title }}</div>
      </li>
    </ul>
    <!-- <ul class="msg" v-if="activeIndex == 0">
      <li class="msg-item" v-for="(item, index) in zList" :key="index">
        <div class="content text-el">FMG加速推进加蓬贝林加铁矿石项目</div>
      </li>
    </ul> -->
    <ul class="msg" v-else>
      <li class="msg-item" v-for="(item, index) in kList" :key="index">
        <div class="content">
          02-15 15:19:30 | 【花旗：预计2023年铜价为8700
          美元/吨】花旗称，预计2023年铜价为8700美元/吨， 2024年为9000美元/吨。
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import service from "../../service";
export default {
  data() {
    return {
      activeIndex: 0,
      zList: [],
      kList: [1, 2, 3, 4, 5],
    };
  },
  created() {
    this.getNotice();
  },
  methods: {
    toDetail(id) {
      this.$router.push("/noticeDetail?id=" + id);
    },
    moreClick() {
      this.$router.push("/notice?n=" + this.activeIndex);
    },
    async getNotice() {
      let { data } = await service.getNotice({
        current: 1,
        size: 10,
      });
      this.zList = data.records;
    },
    changeMessage(index) {
      if (index == this.activeIndex) return;
      this.activeIndex = index;
    },
  },
};
</script>

<style scoped>
.message {
  font-size: 14px;
  color: #333;
  padding: 0 32px;
}
.title {
  font-size: 16px;
  line-height: 22px;
  color: #999;
  cursor: pointer;
  user-select: none;
}
.title .title-item:first-child {
  margin-right: 20px;
}
.title-item.active {
  color: #333;
  font-weight: bold;
}
.msg {
  margin: 42px 0 0 18px;
}
.msg-item {
  list-style: disc;
  color: #333;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
}

.msg-item:last-child {
  margin: 0;
}
.content {
  /* color: #333; */
  text-align: justify;
}
.msg-item:hover {
  color: #6788eb;
}
.more {
  font-size: 16px;
  cursor: pointer;
  user-select: none;
  margin-left: auto;
  color: #333;
}
.more .icon:hover {
  transform: scale(1.2);
}
</style>
