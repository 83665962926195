enum ButtonTypeEnum {
  /**
   * 确认
   */
  SURE = 'sure',
  /**
   * 取消
   */
  CANCEL = 'cancel',
  /**
   * 文字按钮
   */
  TEXT = 'text',
  /**
   * 高亮
   */
  PRIMARY = 'primary',
}

const button = <T extends Array<any>>(
  type: `${ButtonTypeEnum}`,
  button?: ModalButton<T>
): ModalButton<T> => {
  switch (type) {
    case 'sure':
      return {
        method: 'sure',
        text: '确认',
        type: 'primary',
        updateContent: true,
        gobackContent: true,
        ...button,
      }
    case 'cancel':
      return {
        method: 'cancel',
        text: '取消',
        updateContent: false,
        gobackContent: true,
        ...button,
      }
    case 'text':
      return {
        type: 'text',
        updateContent: false,
        gobackContent: false,
        ...button,
      }
    case 'primary':
      return {
        type: 'primary',
        updateContent: false,
        gobackContent: false,
        ...button,
      }
    default:
      return {
        ...button,
      }
  }
}

export default button
