type TableRecords = {
  current?: number
  total: number
  records: Record<string, any>[]
}

type SimpleRecords = {
  data: Record<string, any>[]
  total: number
}

/**
 * 格式化分页范围值
 */
export const buildRecords = (
  resp: Records | SimpleRecords | Record<string, any>[] | void
): TableRecords => {
  if (resp) {
    if (Array.isArray(resp)) {
      return {
        records: resp,
        total: resp.length,
      }
    } else {
      if (Array.isArray(resp.data)) {
        const { data, total } = resp as SimpleRecords
        return {
          records: data,
          total: total,
        }
      } else {
        const { data } = resp as Records
        return { ...data }
      }
    }
  }
  return { records: [], total: 0 }
}
