<template>
  <div class="main">
    <div
      class="goods-item fl al-c"
      v-for="item in goodsList"
      :key="item.id"
      @click.stop="toDetail(item)"
    >
      <div class="goods fl">
        <div class="img-box">
          <img class="goods-img" :src="item.fileUrl" alt="" />
          <div class="page-view">浏览量：{{ item.pageView }}</div>
        </div>
        <div class="goods-desc">
          <div class="goods-name text-el">
            {{ item.goodsType }}{{ item.goods }} {{ item.specifications
            }}{{ item.materialTexture }}
          </div>
          <div class="goods-plant text-el">仓库：{{ item.warehouse }}</div>
          <div class="goods-tip">定额违约金</div>
        </div>
      </div>
      <div class="goods2">
        <div class="goods-name text-el">1件/{{ item.unitWeight }}吨</div>
        <div class="goods-plant text-el">存放地：{{ item.enterprise }}</div>
        <div class="goods2-add text-el">地址：{{ item.warehouseLocation }}</div>
      </div>
      <div class="goods3 fl al-c">
        <div class="money-f">&yen;{{ item.price }}</div>
      </div>
      <div class="goods4">
        <div>
          <el-button type="primary" plain @click.stop="toDetail(item)"
            >查看详情</el-button
          >
        </div>
        <div class="goods4-2 fl al-c">
          <div class="goods4-icon" @click.stop="openIm(item)">
            <svg-icon icon-class="im" class="icon"></svg-icon>
          </div>
          <div class="goods4-icon" @click.stop="collectClick(item)">
            <svg-icon
              v-if="item.isCollection == 0"
              icon-class="shoucang"
              class="icon"
            ></svg-icon>
            <svg-icon
              v-else
              icon-class="shoucang-active"
              class="icon"
            ></svg-icon>
          </div>
        </div>
      </div>
    </div>
    <ui-im
      ref="im"
      :goods="goods"
      @onMessage="onMessage"
      @close="closeMessage"
    ></ui-im>

    <el-dialog
      :visible.sync="showLogin"
      width="683px"
      title="登录/注册"
      top="17%"
    >
      <div class="login-box">
        <login-section @loginSuccess="loginSuccess"></login-section>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { UiIm, LoginSection } from "@/ui";
import db from "@/utils/localstorage";
import { mapGetters } from "vuex";

export default {
  components: {
    UiIm,
    LoginSection,
  },
  props: {
    goodsList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      goods: null,
      showLogin: false,
    };
  },
  computed: {
    ...mapGetters(["account"]),
  },
  methods: {
    loginSuccess() {
      this.showLogin = false;
    },
    openIm(item) {
      if (this.account && this.account.accountId) {
        this.goods = item;
        this.$refs["im"].openDialog();
        this.$refs.im.setSend(item.accountId, item.user);
      } else {
        this.showLogin = true;
      }
    },
    closeMessage() {},
    onMessage(id) {
      // if (this.showChat) return
      // let index = this.orderList.findIndex((i) => i.applyTenantId == id)
    },
    collectClick(item) {
      // if(item.isCollection == 1){

      // }
      this.$emit("collect", item);
    },
    toDetail(goods) {
      this.$router.push("/goods?id=" + goods.id);
      // let account = db.get("account");
      // if (account) {
      //   this.$router.push("/goods?id=" + goods.id);
      // } else {
      //   this.showLogin = true;
      // }
    },
  },
};
</script>

<style scoped>
.goods-item {
  padding: 20px 30px;
  border-bottom: 1px solid #f0f0f0;
  height: 132px;
  justify-content: space-between;
  cursor: pointer;
}
.goods-item:last-child {
  border: 0;
}
.goods {
  width: 30%;
  height: 100%;
}
.goods2 {
  width: 19%;
  height: 100%;
}
.goods3,
.goods4 {
  width: 12%;
  height: 100%;
  text-align: center;
}
.goods-img {
  width: 92px;
  height: 92px;
  display: block;
  border-radius: 2px;
}
.goods-desc {
  margin-left: 14px;
  width: calc(100% - 107px);
}
.goods-name {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}
.goods-plant {
  font-size: 14px;
  color: #666;
  line-height: 20px;
  margin: 5px 0 11px;
}
.goods-tip {
  display: inline-block;
  padding: 1px 6px;
  font-size: 12px;
  line-height: 19px;
  color: #ef984a;
  background: #fcf0e3;
  border-radius: 1px;
}

.goods2-add {
  font-size: 14px;
  color: #666;
  line-height: 20px;
}
.money-f {
  font-size: 26px;
  color: #bb271a;
  line-height: 31px;
  white-space: nowrap;
}
.goods3-2 {
  margin-top: 15px;
  justify-content: center;
}
.goods3-icon {
  cursor: pointer;
  transition: all 0.3s;
}
.goods3-icon:first-child {
  margin-right: 10px;
}
.goods3-icon:hover {
  transform: scale(1.1);
}
.goods3-icon .icon {
  width: 34px;
  height: 34px;
}

.goods4 .el-button--primary.is-plain {
  background: transparent;
  font-size: 14px;
  color: #3c9cff;
}
.goods4 .el-button--primary.is-plain:hover {
  color: #3c9cff;
}
.goods4-icon {
  transition: all 0.3s;
  cursor: pointer;
}
.goods4-icon:first-child {
  margin-right: 20px;
}
.goods4-icon .icon {
  width: 22px;
  height: 22px;
}
.goods4-icon:hover {
  transform: scale(1.1);
}
.goods4-2 {
  margin-top: 11px;
  justify-content: center;
}
.img-box {
  position: relative;
  border-radius: 2px;
  overflow: hidden;
}
.page-view {
  width: 100%;
  line-height: 26px;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
}
.login-box {
  width: 60%;
  margin: auto;
}
</style>
