import axios from '../index'

export default {
  /**
   * 模糊查询
   */
  async fuzzy(keyWord, tenantType) {
    if (keyWord) {
      const { data } = await axios(
        {
          url: '/enterprise/listAll',
          method: 'GET',
          data: {
            typeKey: tenantType,
            name: keyWord,
            status: 2,
          },
        },
        {
          aloneBaseUrl: true,
        }
      )
      return data.map((item) => ({
        label: item.name,
        value: item.id,
      }))
    }
  },
  /**
   * 租户角色
   */
  async role() {
    const { data } = await axios(
      {
        url: '/role/listAll',
        method: 'GET',
      },
      {
        aloneBaseUrl: true,
      }
    )
    return data.map((item) => ({
      label: item.name,
      value: String(item.id),
    }))
  },
  /**
   * 合约类型
   */
  async contract() {
    const { data } = await axios({
      url: '/contract/contractList',
      method: 'GET',
    })
    return data
  },
}
