import axios from '../index'

export default {
  noticeList(data:any){
    return axios({
      url:'/platformActivity/public/list',
      method:'GET',
      data
    })
  },
  noticeDetail(id:any){
    return axios({
      url:'/platformActivity/public/detail',
      method:'GET',
      data:{
        id
      }
    })
  },
  noticeColect(messageId:any){
    return axios({
      url:'/platformActivityCollection/collection',
      method:'GET',
      data:{
        messageId
      }
    })
  },
  noticePageView(id:any){
    return axios({
      url:'/platformActivity/public/pageView',
      method:'GET',
      data:{
        id
      }
    })
  },
  noticeIsCollect(messageId:any){
    return axios({
      url:'/platformActivityCollection/isCollection',
      method:'GET',
      data:{
        messageId
      }
    })
  },
  submit(data:any){
    return axios({
      url:'/platformActivity/submit',
      method:'POST',
      data
    })
  },
}