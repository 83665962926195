import Vue from 'vue'
import Vuex from 'vuex'
//@ts-ignore
import getters from './getters'
//@ts-ignore
import oauth from './modules/oauth'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    oauth,
  },
  getters,
})

export default store
