<template>
  <div class="transport">
    <div class="title">{{ title }}</div>
    <slot />
    <div class="item fl al-c">
      <div class="item-left">
        <div>{{ datas.top.label }}</div>
        <div class="num">{{ datas.top.value }}</div>
        <div class="item-bt fl">
          <div class="bt-left">
            <div>{{ datas.left.label }}</div>
            <div class="num">{{ datas.left.value }}</div>
          </div>
          <div class="bt-right">
            <div>{{ datas.right.label }}</div>
            <div class="num">
              <svg-icon
                v-if="datas.right.status > 0"
                icon-class="up"
                class="icon"
              ></svg-icon>
              <svg-icon v-else icon-class="down" class="icon"></svg-icon>
              {{ datas.right.value }}
            </div>
          </div>
        </div>
      </div>
      <div class="item-right">
        <chart />
      </div>
    </div>
  </div>
</template>

<script>
import chart from "./chart/index.vue";
export default {
  components: { chart },
  props: {
    title: {
      type: String,
      default: "物流",
    },
    datas: {
      type: Object,
      default: () => {
        return {
          top: {
            label: "今日承运量",
            value: "5,760",
          },
          left: {
            label: "上一工作日",
            value: "5,7760",
          },
          right: {
            label: "日环比",
            value: "96.1%",
            status: 1,
          },
        };
      },
    },
  },
};
</script>

<style scoped>
.transport {
  font-size: 12px;
  color: #999;
  line-height: 17px;
}
.title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  line-height: 22px;
  margin-bottom: 4px;
  padding-left: 30px;
}
.item {
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 30px 13px;
  justify-content: space-between;
}
.item-left,
.item-right {
  width: 50%;
}
.item-left {
  margin-top: 16px;
}
.num {
  font-size: 16px;
  line-height: 22px;
  color: #333;
  font-weight: bold;
}
.item-bt {
  width: 100%;
  margin-top: 20px;
  justify-content: space-between;
}
.num .icon {
  width: 12px;
  height: 12px;
}
</style>
