export const buildList = (value: any): string[] => {
  console.log(value, 'value')
  if (value == '') return []
  if (Array.isArray(value)) {
    return value.map((i) => '' + i)
  } else {
    if (typeof value === 'number') {
      value = String(value)
    }
    if (typeof value === 'string') {
      return value.split(',').map((i) => '' + i)
    }
  }
  return []
}

export const buildString = (value: any): string | void => {
  if (Array.isArray(value)) {
    return value.toString()
  } else {
    if (typeof value === 'number') {
      return String(value)
    }
    if (typeof value === 'string') {
      return value
    }
  }
}
