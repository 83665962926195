<template>
  <div class="ui-tree-page">
    <!-- 搜索 -->
    <div class="search" v-if="searchProps">
      <ui-form
        ref="search-form"
        :inspect="false"
        :columns="searchColumns"
        :labelWidth="searchProps.labelWidth"
      />
      <div class="search-control">
        <el-button type="primary" icon="el-icon-search" @click="search"
          >查询</el-button
        >
        <el-button icon="el-icon-refresh" @click="resetSearch">重置</el-button>
      </div>
      <!-- <el-input v-model="keyWord" placeholder="输入关键字查询" />
      <div class="search-control">
        <el-button type="primary" icon="el-icon-search" @click="filter"
          >查询</el-button
        >
        <el-button icon="el-icon-refresh" @click="reset">重置</el-button>
      </div> -->
    </div>

    <div class="content">
      <!-- 树形头部 -->
      <div class="header">
        <div class="title" v-if="pageConfig.title">
          <i v-if="pageConfig.title.canBack"></i>
          <span>{{ pageConfig.title.content(params) }}</span>
        </div>
        <div class="blank"></div>
        <div class="toolbar">
          <el-button
            v-for="(ctrl, index) in pageConfig.toolbar"
            v-perm="ctrl.permissions"
            :key="index"
            :type="ctrl.type"
            :icon="ctrl.icon"
            @click="delegate(ctrl)"
            >{{ ctrl.text }}</el-button
          >
          <slot name="toolbar"></slot>
        </div>
      </div>
      <!-- 树型列表 -->
      <ui-tree
        ref="tree"
        :config="pageConfig"
        :data="treedata"
        :label="label"
        :nodeKey="nodeKey"
        :defaultSelected="defaultSelected"
        @nodeClick="nodeClick"
        @delegate="delegate"
      />
    </div>

    <!-- 对话框 -->
    <ui-modal
      ref="modal"
      :pageConfig="pageConfig"
      :columns="columns"
      :service="service"
      :config="modalConfig"
      :sections="controlSections"
      :contextProps="controlContextProps"
      @success="update"
    />
  </div>
</template>

<script>
import Util from '../../lib/util'
import UiForm from '../form'
import UiTree from '../tree'
import UiModal from '../modal'
import pageMixin from '../../mixins/page'
export default {
  name: 'ui-tree-page',
  components: { UiForm, UiTree, UiModal },
  mixins: [pageMixin],
  props: {
    label: {
      type: String,
      default: 'label',
    },
    nodeKey: {
      type: String,
      default: 'value',
    },
    defaultSelected: {
      type: String | Number,
      default: '',
    },
  },
  data() {
    return {
      treedata: [],
      treeBy: {},

      keyWord: '',
    }
  },
  mounted() {
    this.update()
  },
  methods: {
    nodeClick(node) {
      this.$emit('nodeClick', node)
    },
    /**
     * 切换操作栏的显示/隐藏
     *
     * @param {boolean} visible
     */
    toggleControls(visible) {
      this.$refs.tree.toggleControls(visible)
    },
    filter() {
      this.$refs['tree'].filter(this.keyWord)
    },
    reset() {
      this.keyWord = ''
      this.filter()
    },
    /**
     * 重置搜索条件
     */
    resetSearch() {
      this.$refs['search-form'].resetFields()
      this.treeBy = {}
      this.update()
    },
    async search() {
      this.treeBy = await this.$refs['search-form'].validate()
      this.update()
    },
    /**
     * 更新
     */
    async update(params) {
      this.loading = true
      this.treedata = []
      try {
        this.treedata = await this.service.view?.(
          {
            ...this.treeBy,
          },
          {
            ...this.params,
            ...params,
          }
        )
      } catch (e) {
        Util.error('获取数据失败:', e)
      }
      this.loading = false
      this.$emit('updated')
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-tree-page {
  box-sizing: border-box;
  padding: 12px;

  .search {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 18px;
    margin-bottom: 12px;

    .el-input {
      max-width: 200px;
    }

    .search-control {
      height: 40px;
      display: flex;
      align-items: center;
      margin-left: 12px;
    }
  }

  .content {
    background-color: #ffffff;
    padding: 18px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 18px;
    }

    .pagination {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 14px;
      margin-top: 18px;
    }
  }
}
.title {
  font-size: 16px;
}
</style>
