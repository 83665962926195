<template>
  <el-cascader
    ref="cascader"
    v-model="result"
    :props="{
      emitPath: false,
      multiple: schema.multiple,
      leaf: 'isLeaf',
      ...schema.fieldProps,
    }"
    :options="options"
    :show-all-levels="false"
  />
</template>

<script>
import Util from '../../../../lib/util'
import { buildList, buildString } from '../lib/select'
export default {
  name: 'cascader-field',
  props: {
    schema: Object,
    value: {},
    tempdata: {
      type: Object,
      default: () => ({}),
    },
    chaindata: Object,
  },
  data() {
    const { multiple } = this.schema
    return {
      options: [],
      result: multiple ? buildList(this.value) : buildString(this.value),
    }
  },
  watch: {
    result(val) {
      const { chainIndex, multiple } = this.schema

      if (multiple) {
        this.tempdata[chainIndex] = this.$refs.cascader.getCheckedNodes(true)
      }
      this.$emit('input', buildString(val))
    },
  },
  beforeMount() {
    this.resetOptions(() => {
      if (Util.notNull(this.result)) {
        this.$nextTick(() => {
          const { chainIndex, multiple } = this.schema

          if (multiple) {
            this.tempdata[chainIndex] =
              this.$refs.cascader.getCheckedNodes(true)
          }
          this.$emit('emitListener')
        })
      }
    })
  },
  methods: {
    updateField(value) {
      this.result = value
    },
    async resetOptions(fetched) {
      this.options = []
      const { request } = this.schema
      this.options =
        (await request?.({
          chaindata: this.chaindata,
        })) || []
      fetched?.()
    },
  },
}
</script>

<style lang="scss" scoped>
//
</style>
