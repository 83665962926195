// @ts-ignore
import db from '@/utils/localstorage'
import axios from '../index'

export type DictEntity = {
  [key: string]: any
}

export default {
  /**
   * 下拉列表
   */
  async valueEnum(
    code?: string,
    isUsual?: boolean | string
  ): Promise<Field.Option[] | void> {
    const account = db.get('account', {})
    if (code) {
      const typeKey = isUsual ? (typeof(isUsual) == 'boolean' ? '' : isUsual) : account?.enterpriseTypeKey
      const { data } = await axios(
        {
          url: '/dict/listAll',
          method: 'GET',
          data: { code, platformTypeKey: typeKey },
        },
        {
          cacheKey: code,
          aloneBaseUrl: true,
        }
      )
      return data.map((item: DictEntity) => ({
        label: item.dictValue,
        value: item.dictKey,
        tagType: item?.tagType,
        code: item?.code,
        id: item?.id,
      }))
    }
  },
  /**
   * 树形字典
   */
  async tree(code?: string): Promise<Field.Option[] | void> {
    if (code) {
      const { data } = await axios(
        {
          url: '/dict/tree',
          method: 'GET',
          data: { code },
        }
        // {
        //   cacheKey: code,
        // }
      )
      return data
    }
  },
  /**
   * 模糊查询
   */
  async fuzzy(code?: string) {
    if (code) {
      const { data } = await axios({
        url: '/dict/fuzzy',
        method: 'GET',
        data: { code },
      })
      return data.map((item: DictEntity) => ({
        label: item.dictValue,
        value: item.id,
        code: item.code,
      }))
    } else {
      return []
    }
  },
}
