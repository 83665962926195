<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.router-view {
  min-height: 1100px;
}
</style>
