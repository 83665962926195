var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{key:_vm.formKey,ref:"form",class:['ui-form', _vm.inspect ? '' : 'no-inspect'],attrs:{"show-message":_vm.inspect,"model":_vm.chaindata,"rules":!_vm.readonly && _vm.inspect ? _vm.rules : null,"validate-on-rule-change":false,"hide-required-asterisk":this.readonly}},[_vm._l((_vm.schemas),function(s){return [(!s.hideInForm)?_c('el-form-item',{key:s.chainIndex,style:(_vm.fieldStyle(s)),attrs:{"prop":s.chainIndex,"label":s.title,"label-width":_vm.labelWidth + 'px'}},[(s.valueType === 'slot')?[_vm._t(s.chainIndex,null,{"schema":s,"tempdata":_vm.tempdata,"chaindata":_vm.chaindata,"readonly":_vm.readonly,"initialValues":_vm.initialValues,"initialValue":_vm.Util.chainValue(
              _vm.initialValues,
              s.chainIndex,
              typeof s.initialValue === 'function'
                ? s.initialValue(_vm.initialValues)
                : s.initialValue
            ),"setValue":(value) => _vm.changeField(s, value)})]:[_c('label-field',{ref:s.chainIndex,refInFor:true,attrs:{"schema":s,"tempdata":_vm.tempdata,"chaindata":_vm.chaindata,"readonly":_vm.readonly,"initialValues":_vm.initialValues,"initialValue":_vm.Util.chainValue(
              _vm.initialValues,
              s.chainIndex,
              typeof s.initialValue === 'function'
                ? s.initialValue(_vm.initialValues)
                : s.initialValue
            )},on:{"change":(value) => _vm.changeField(s, value),"emitListener":_vm.emitListener}})]],2):_c('static-field',{key:s.dataIndex,attrs:{"value":_vm.Util.chainValue(
          _vm.initialValues,
          s.chainIndex,
          typeof s.initialValue === 'function'
            ? s.initialValue(_vm.initialValues)
            : s.initialValue
        )},on:{"change":(value) => _vm.changeField(s, value)}}),(s.formFieldLayout && s.formFieldLayout.divider)?_c('div',{key:'divider' + s.chainIndex,staticClass:"ui-form__divider"}):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }