<template>
  <div class="home">
    <div class="swiper-section">
      <mid-section />
    </div>

    <!-- 行情 -->
    <div class="container hangqing">
      <div class="hq-top fl al-c">
        <h3>行情明细</h3>
        <div class="hq-refresh" @click="refresh">
          <svg-icon
            icon-class="refresh"
            :class="['icon', refreshing ? 'refresh' : '']"
          ></svg-icon>
          <span>刷新</span>
        </div>
      </div>
      <el-row class="main-row">
        <el-col :span="12" class="cols">
          <div class="cols1-top fl">
            <div class="cols-title">电商</div>
            <ul class="cols1-ul fl al-c">
              <li
                v-for="(item, index) in businessList"
                :key="index"
                :class="[activeBusiness == index ? 'active' : '']"
                @click="changeBusiness(index)"
              >
                {{ item }}
              </li>
            </ul>
          </div>
          <!-- 成交情况 -->
          <deal v-if="activeBusiness == 0" />
          <!-- 价格趋势 -->
          <price v-if="activeBusiness == 1" />
          <!-- 原料行情 -->
          <market v-if="activeBusiness == 2" />
        </el-col>
        <!-- <el-col :span="8" class="cols">
          <transport />
          <transport title="白条" :datas="baitiao" style="padding-top: 12px">
            <div slot class="baitiao-mid fl al-c">
              <div class="baitiao-item">
                <span>累计服务用户数</span>
                <span class="num">7876</span>
              </div>
              <div class="baitiao-item">
                <span>在贷金额</span>
                <span class="num">7876万元</span>
              </div>
            </div>
          </transport>
          <statics />
          <statics :showBorder="false" title="数据" />
        </el-col> -->
        <el-col :span="12" class="cols">
          <message ref="message" />
        </el-col>
      </el-row>
    </div>

    <!-- 推荐 -->
    <recommond />
  </div>
</template>

<script>
import midSection from "@/components/midSection/index.vue";
import recommond from "./lib/recommond/index.vue";
import deal from "./lib/deal/index.vue";
import price from "./lib/price/index.vue";
import market from "./lib/market/index.vue";
import transport from "./lib/transport/index.vue";
import statics from "./lib/statics/index.vue";
import message from "./lib/message/index.vue";

export default {
  components: {
    midSection,
    recommond,
    deal,
    price,
    market,
    transport,
    statics,
    message,
  },
  data() {
    return {
      activeBusiness: 0,
      businessList: ["成交情况", "价格趋势", "原料行情"],
      baitiao: {
        top: {
          label: "今日放款金额",
          value: "508,666元",
        },
        left: {
          label: "上一工作日",
          value: "43,910,212元",
        },
        right: {
          label: "日环比",
          value: "87.5%",
          status: -1,
        },
      },
      refreshing: false,
    };
  },
  methods: {
    refresh() {
      if (this.refreshing) return;
      this.refreshing = true;
      this.$refs["message"].getNotice();
      setTimeout(() => {
        this.refreshing = false;
      }, 1000);
    },
    changeBusiness(index) {
      if (this.activeBusiness == index) return;
      this.activeBusiness = index;
    },
  },
};
</script>

<style scoped>
.home {
  background: #f5f5f5;
  padding: 1px 0 50px;
}
.swiper-section {
  margin: 20px auto;
}
.hangqing {
  margin: 20px auto 0;

  /* height: 662px; */
  padding-bottom: 20px;
}
.hq-top {
  height: 65px;
  padding-left: 30px;
  border-bottom: 1px solid #f0f0f0;
  background: #fff;
}
.hq-top h3 {
  font-size: 18px;
  line-height: 26px;
  margin-right: 22px;
}
.hq-refresh {
  font-size: 14px;
  color: #666;
  cursor: pointer;
  vertical-align: middle;
  user-select: none;
}
@keyframes xuanzhuan {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.hq-refresh .icon {
  margin-right: 5px;
  width: 12px;
  height: 12px;
}
.refresh {
  animation: xuanzhuan 0.5s forwards;
}

.main-row {
  background: #fff;
}
.cols {
  padding: 30px;
}
.cols:nth-child(2) {
  border: 1px solid #f0f0f0;
  border-top: 0;
  border-bottom: 0;
  padding: 0;
  padding-top: 30px;
}
.cols-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}
.cols1-top {
  justify-content: space-between;
}
.cols1-top li {
  font-size: 14px;
  line-height: 20px;
  color: #666;
}
.cols1-top li.active {
  color: #3c9cff;
}
.cols1-top li {
  margin-right: 20px;
}
.cols1-top li:last-child {
  margin: 0;
}
.cols1-ul li {
  cursor: pointer;
}

.baitiao-mid {
  padding: 0 30px;
  color: #666;
  justify-content: space-between;
}
.baitiao-item {
  width: 50%;
  padding-left: 10px;
  position: relative;
}
.baitiao-item::after {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #bb271a;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.baitiao-item span:first-child {
  margin-right: 6px;
}
.num {
  font-size: 12px;
  color: #333;
  font-weight: bold;
}
</style>
