<template>
  <div class="ui-form-group">
    <div
      class="ui-form-item"
      v-for="(dataItem, index) in formValues"
      :key="dataItem.formKey"
    >
      <ui-form
        :ref="'form-' + dataItem.formKey"
        :columns="columns"
        :initialValues="dataItem"
        :labelWidth="labelWidth"
        :ndiv="ndiv"
      />
      <div class="remove" v-if="!locked" @click="values.splice(index, 1)">
        <i class="el-icon-delete"></i>
      </div>
    </div>
    <div v-if="!formValues.length" class="empty">暂无数据 !</div>
  </div>
</template>

<script>
import UiForm from '../form'
import Util from '../../lib/util'
export default {
  name: 'ui-form-group',
  components: { UiForm },
  props: {
    ndiv: Number,
    labelWidth: Number,
    length: Number,
    columns: {
      type: Array,
      default: () => [],
    },
    values: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const lockedValues = []
    if (Util.notNull(this.length)) {
      for (let i = 0; i < this.length; i++) {
        lockedValues.push({
          ...this.values[i],
          formKey: i + 1,
        })
      }
    }
    return {
      formKey: 1,

      locked: Util.notNull(this.length),
      lockedValues,
    }
  },
  computed: {
    formValues() {
      return this.locked ? this.lockedValues : this.values
    },
  },
  watch: {
    values: {
      immediate: true,
      handler(newVal = []) {
        for (let i = 0; i < newVal.length; i++) {
          if (!newVal[i].formKey) {
            newVal[i].formKey = this.formKey++
          }
        }
      },
    },
  },
  methods: {
    /**
     * 校验 & 取值
     */
    async validate() {
      const dataList = []
      for (let i = 0; i < this.formValues.length; i++) {
        dataList.push(
          await this.$refs['form-' + this.formValues[i].formKey][0]?.validate()
        )
      }
      return dataList
    },
    /**
     * 赋值
     *
     * @param {array | object} data
     */
    refill(data) {
      this.values.splice(0)
      // TODO: lockedValues
      if (Array.isArray(data)) {
        this.values.push(...data)
      } else {
        this.values.push(data)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-form-group {
  width: 100%;
  display: flex;
  flex-direction: column;

  .ui-form-item {
    position: relative;
    padding-right: 20px;

    .remove {
      visibility: hidden;
      width: 20px;
      height: 32px;
      margin: 4px 0;
      font-size: 14px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      // background-color: rgba(0, 0, 0, 0.1);
    }

    &:hover .remove {
      visibility: visible;
    }
  }

  .empty {
    width: 100%;
    text-align: center;
    color: #cccccc;
    user-select: none;
  }
}
</style>
