export const registerAccount: Schema[] = [
  // {
  //   dataIndex: 'nickName',
  //   title: '昵称',
  // },
  // {
  //   dataIndex: 'headPortrait',
  //   title: '头像',
  //   valueType: 'file',
  //   required:false
  // },
  {
    dataIndex: 'phone',
    title: '手机号',
    rule:[
      {
        trigger:'blur',required:true,pattern:/^(?:(?:\+|00)86)?1[3-9]\d{9}$/,message:'请输入正确的手机号'
      }
    ]
  },
  {
    dataIndex: 'password',
    title: '密码',
    valueType: 'password',
  },
]
export const registerCompany: Schema[] = [
  {
    dataIndex: 'phone',
    title: '账号',
  },
  {
    dataIndex: 'password',
    title: '密码',
    valueType: 'password',
  },
  
  
]
export const loginColumns: Schema[] = [
  {
    dataIndex: 'account',
    title: '用户名',
  },
  {
    dataIndex: 'password',
    title: '密码',
    valueType: 'password',
  },
]