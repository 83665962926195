<template>
  <el-popover
    ref="popover"
    class="tree-field"
    placement="bottom-start"
    trigger="focus"
    width="240"
    v-model="popoverVisible"
  >
    <el-tree
      node-key="value"
      :data="treeList"
      :expand-on-click-node="false"
      :props="{ label }"
      @node-click="changeField"
    />
    <el-input
      slot="reference"
      ref="input"
      placeholder="请选择节点"
      clearable
      :value="selected && selected[label]"
      @clear="changeField"
    />
  </el-popover>
</template>

<script>
import Util from "../../../../lib/util";
export default {
  name: "tree-field",
  props: {
    schema: Object,
    value: {},
    tempdata: Object,
    chaindata: Object,
  },
  data() {
    return {
      popoverVisible: false,
      treeList: [],
      selected: null,
    };
  },
  computed: {
    label() {
      if (this.schema?.fieldProps?.label) {
        return this.schema.fieldProps.label;
      } else {
        return "label";
      }
    },
    treeValue() {
      if (this.schema?.fieldProps?.value) {
        return this.schema.fieldProps.value;
      } else {
        return "value";
      }
    },
  },
  watch: {
    async chaindata(newVal, oldVal) {
      const { dependencies: deps = [] } = this.schema;
      (typeof deps === "string" ? [deps] : deps).forEach((dep) => {
        if (newVal[dep] !== oldVal[dep]) {
          if (oldVal[dep]) {
            this.selected = undefined;
          }
          this.resetOptions();
        }
      });
    },
    selected(node) {
      const { dataIndex } = this.schema;
      this.tempdata[dataIndex] = node;
      this.$emit("input", node?.[this.treeValue]);
    },
  },
  async mounted() {
    await this.resetOptions();
    this.updateField(this.value);
  },
  methods: {
    updateField(value) {
      if (Util.notNull(value)) {
        this.selected = this.findNode(this.treeList, value);
        this.$emit("emitListener");
      }
    },
    changeField(leaf) {
      if (leaf) {
        const { strict } = this.schema.fieldProps;
        if (!strict || leaf.isLeaf) {
          this.selected = leaf;
          this.popoverVisible = false;
        }
      } else {
        this.selected = leaf;
      }
    },
    findNode(tree = [], value) {
      for (let node of tree) {
        if (node[this.treeValue] == value) {
          return node;
        } else {
          const target = this.findNode(node.children || [], value);
          if (target) {
            return target;
          }
        }
      }
    },
    async resetOptions() {
      this.treeList = [];
      const { request } = this.schema;
      this.treeList =
        (await request?.({
          chaindata: this.chaindata,
        })) || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.tree-field {
  width: 100%;
  max-height: 500px;
  overflow: auto;
}
</style>
