import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/home/index.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "首页",
    component: () => import("../views/layout/index.vue"),
    children: [
      {
        path: "/",
        name: "home",
        meta: {
          name: "home",
        },
        component: HomeView,
      },
      {
        path: "/market",
        name: "market",
        meta: {
          name: "market",
        },
        component: () => import("../views/market/index.vue"),
      },

      {
        path: "/goods",
        name: "goods",
        meta: {
          name: "goods",
        },
        component: () => import("../views/goods/index.vue"),
      },
      {
        path: "/buy",
        name: "buy",
        meta: {
          name: "buy",
        },
        redirect: "/buy/list",
        component: () => import("../views/buy/index.vue"),
        children: [
          {
            path: "/buy/list",
            name: "buy/list",
            meta: {
              name: "buy/list",
            },
            component: () => import("../views/buy/children/list.vue"),
          },
          {
            path: "/buy/detail",
            name: "buy/detail",
            meta: {
              name: "buy/detail",
            },
            component: () => import("../views/buy/children/detail.vue"),
          },
        ],
      },
      {
        path: "/transport",
        name: "transport",
        meta: {
          name: "transport",
        },
        redirect: "/transport/list",
        component: () => import("../views/transport/index.vue"),
        children: [
          {
            path: "/transport/list",
            name: "transport/list",
            meta: {
              name: "transport/list",
            },
            component: () => import("../views/transport/children/list.vue"),
          },
          {
            path: "/transport/detail",
            name: "transport/detail",
            meta: {
              name: "transport/detail",
            },
            component: () => import("../views/transport/children/detail.vue"),
          },
        ],
      },
      {
        path: "/cangchu",
        name: "cangchu",
        meta: {
          name: "cangchu",
        },
        redirect: "/cangchu/list",
        component: () => import("../views/cangchu/index.vue"),
        children: [
          {
            path: "/cangchu/list",
            name: "cangchu/list",
            meta: {
              name: "cangchu/list",
            },
            component: () => import("../views/cangchu/children/list.vue"),
          },
          {
            path: "/cangchu/detail",
            name: "cangchu/detail",
            meta: {
              name: "cangchu/detail",
            },
            component: () => import("../views/cangchu/children/detail.vue"),
          },
        ],
      },
      {
        path: "/about",
        name: "about",
        meta: {
          name: "about",
        },
        component: () => import("../views/about/index.vue"),
      },
      {
        path: "/editor",
        name: "editor",
        meta: {
          name: "editor",
        },
        component: () => import("../views/editor/index.vue"),
      },
      {
        path: "/notice",
        name: "notice",
        meta: {
          name: "notice",
        },
        component: () => import("../views/notice/index.vue"),
      },
      {
        path: "/noticeDetail",
        name: "noticeDetail",
        meta: {
          name: "noticeDetail",
        },
        component: () => import("../views/notice/detail.vue"),
      },
      {
        path: "/noticeCommend",
        name: "noticeCommend",
        meta: {
          name: "noticeCommend",
        },
        component: () => import("../views/notice/commend/index.vue"),
      },
      {
        path: "/noticeCommendDetail",
        name: "noticeCommendDetail",
        meta: {
          name: "noticeCommendDetail",
        },
        component: () => import("../views/notice/commend/detail.vue"),
      },
      {
        path: "/noticeCommendEditor",
        name: "noticeCommendEditor",
        meta: {
          name: "noticeCommendEditor",
        },
        component: () => import("../views/editor/commend/index.vue"),
      },
      {
        path: "/noticeActivity",
        name: "noticeActivity",
        meta: {
          name: "noticeActivity",
        },
        component: () => import("../views/notice/activity/index.vue"),
      },
      {
        path: "/noticeActivityDetail",
        name: "noticeActivityDetail",
        meta: {
          name: "noticeActivityDetail",
        },
        component: () => import("../views/notice/activity/detail.vue"),
      },
      {
        path: "/noticeActivityEditor",
        name: "noticeActivityEditor",
        meta: {
          name: "noticeCommendEditor",
        },
        component: () => import("../views/editor/activity/index.vue"),
      },
      {
        path: "/finance",
        name: "finance",
        meta: {
          name: "finance",
        },
        component: () => import("../views/finance/index.vue"),
      },
      {
        path: "/finance/detail",
        name: "financeDetail",
        meta: {
          name: "financeDetail",
        },
        component: () => import("../views/finance/detail.vue"),
      },
      {
        path: "/finance/editor",
        name: "financeEditor",
        meta: {
          name: "financeEditor",
        },
        component: () => import("../views/finance/editor.vue"),
      },
      {
        path: "/mypublish",
        name: "mypublish",
        meta: {
          name: "mypublish",
        },
        redirect: "/mypublish/warehouse",
        component: () => import("../views/mypublish/index.vue"),
        children: [
          {
            path: "/mypublish/warehouse",
            name: "mypublish/warehouse",
            meta: {
              name: "mypublish/warehouse",
            },
            component: () =>
              import("../views/mypublish/children/warehouse.vue"),
          },
          {
            path: "/mypublish/purchase",
            name: "mypublish/purchase",
            meta: {
              name: "mypublish/purchase",
            },
            component: () => import("../views/mypublish/children/purchase.vue"),
          },
          {
            path: "/mypublish/transport",
            name: "mypublish/transport",
            meta: {
              name: "mypublish/transport",
            },
            component: () =>
              import("../views/mypublish/children/transport.vue"),
          },
          {
            path: "/mypublish/goods",
            name: "mypublish/goods",
            meta: {
              name: "mypublish/goods",
            },
            component: () => import("../views/mypublish/children/goods.vue"),
          },
        ],
      },
      {
        path: "/mycommodity",
        name: "mycommodity",
        meta: {
          name: "mycommodity",
        },
        component: () => import("../views/mycommodity/index.vue"),
      },
      {
        path: "/order",
        name: "order",
        meta: {
          name: "order",
        },
        component: () => import("../views/order/index.vue"),
      },
      {
        path: "/orderDetail",
        name: "orderDetail",
        meta: {
          name: "orderDetail",
        },
        component: () => import("../views/order/detail/index.vue"),
      },
      {
        path: "/bussiness",
        name: "bussiness",
        meta: {
          name: "bussiness",
        },
        component: () => import("../views/bussiness/index.vue"),
      },
      {
        path: "/cart",
        name: "cart",
        meta: {
          name: "cart",
        },
        component: () => import("../views/cart/index.vue"),
      },
      {
        path: "/app",
        name: "app",
        meta: {
          name: "app",
        },
        component: () => import("../views/app/index.vue"),
      },
      {
        path: "/publish",
        name: "publish",
        meta: {
          name: "publish",
        },
        redirect: "/publish/warehouse",
        component: () => import("../views/publish/index.vue"),
        children: [
          {
            path: "/publish/warehouse",
            name: "publish/warehouse",
            meta: {
              name: "publish/warehouse",
            },
            component: () => import("../views/publish/children/warehouse.vue"),
          },
          {
            path: "/publish/purchase",
            name: "publish/purchase",
            meta: {
              name: "publish/purchase",
            },
            component: () => import("../views/publish/children/purchase.vue"),
          },
          {
            path: "/publish/transport",
            name: "publish/transport",
            meta: {
              name: "publish/transport",
            },
            component: () => import("../views/publish/children/transport.vue"),
          },
          {
            path: "/publish/goods",
            name: "publish/goods",
            meta: {
              name: "publish/goods",
            },
            component: () => import("../views/publish/children/goods.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/privacy",
    name: "privacy",
    meta: {
      name: "privacy",
    },
    component: () => import("../views/privacy/index.vue"),
  },
  {
    path: "/onlinePay",
    name: "onlinePay",
    meta: {
      name: "onlinePay",
    },
    component: () => import("../views/onlinePay/index.vue"),
  },
];

const router = new VueRouter({
  routes,
  // mode: "history",
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0,
    };
  },
});

export default router;
