var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-descriptions",style:(_vm.getStyle())},[_c('el-descriptions',{attrs:{"size":_vm.size,"column":_vm.ndiv,"border":_vm.border}},[_vm._l((_vm.schemas),function(s){return [(!s.hideInDesc)?_c('el-descriptions-item',{key:s.chainIndex,attrs:{"label":s.title,"contentStyle":{
          'text-align': 'left',
          display: 'inline-block',
          'white-space': 'nowrap',
        },"labelStyle":{
          'text-align': 'left',
          display: 'inline-block',
          'white-space': 'nowrap',
        }}},[_c('descriptions-item',{attrs:{"record":_vm.chaindata,"schema":s,"editable":_vm.editable && s.editable},on:{"change":(value, cb) => _vm.changeField(s, value, cb)}})],1):_vm._e()]})],2),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }