import Vue from 'vue'
import Config from '../lib/config'
import SchemaList from '../lib/schema'

const pageMixin = Vue.extend<any, any, any, any>({
  props: ['bone', 'contexts', 'params', 'perm', 'canBack'],
  data() {
    const { config, schema, service } = this.bone

    const pageConfig = new Config(
      typeof config === 'function' ? config() : config,
      {
        perm: this.perm,
        canBack: this.canBack,
        contexts: this.contexts,
      }
    )

    const columns = typeof schema === 'function' ? schema() : schema

    const searchProps = pageConfig.searchs?.columns?.length
      ? pageConfig.searchs
      : null
    const searchColumns = SchemaList.compose(columns, searchProps?.columns).map(
      (item) => ({
        ...item,
        hideInForm: item.hideInSearch,
      })
    )
    return {
      /**
       * 全局参数
       */
      pageConfig,
      columns,
      service,
      /**
       * 搜索
       */
      searchProps,
      searchColumns,
      /**
       * 操作框相关
       */
      controlSections: [],
      controlContextProps: {},
      modalConfig: {},
    }
  },
  methods: {
    /**
     * 按钮事件代理
     */
    async delegate(control: OutputTable.Control, rowdata: RowData) {
      const { prepare, modal, view, sections } = control
      const inline = !!rowdata
      // TODO
      const selections = this.$refs.table?.selections

      try {
        await prepare?.call(this, {
          config: this.pageConfig,
          service: this.service,
          params: this.params,

          rowdata,
          setRowdata: (r) => (rowdata = r),

          selections,
          updateTable: this.update,
        })
      } catch (err) {
        typeof err === 'string' && this.$message.info(err)
        return
      }
      this.$emit('delegate', { control, rowdata })
      this.controlSections = sections
      this.controlContextProps = <ContextProps>{
        inline,
        rowdata,
        selections,
        config: this.pageConfig,
        service: this.service,
      }

      if (modal) {
        this.modalConfig = modal
        this.$refs.modal?.open()
      }

      view && this.openView(view, rowdata)
    },
    /**
     * 页面跳转
     */
    openView({ name, title }: OutputTable.View, rowdata: RowData) {
      const $view = {
        path: `${this.$route.path}/view${name ? '/' + name : ''}`,
        title: typeof title === 'function' ? title(rowdata) : title,
        columns: this.columns,
      }

      const crumb = {
        name: $view.path,
        path: $view.path,
        meta: {
          title: $view.title,
        },
        params: {
          ...rowdata,
          $view,
        },
      }

      const crumbs = this.$store.state.app.crumbs
      this.$store.dispatch('app/setCrumbs', [...crumbs, crumb])
      this.$router.push(crumb)
    },
  },
})

export default pageMixin
