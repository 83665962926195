<template>
  <div class="fl fl-d-c al-c ju-c login-container">
    <template v-if="account.accountId">
      <div class="avatar">
        <img v-if="headUrl" :src="headUrl" alt="" />
        <img v-else src="../../../assets/images/icons/avatar.png" alt="" />
      </div>
      <div>Hi，你好！</div>
      <div class="account-name fl al-c" v-if="account.isAuthentication == 1">
        <template v-if="account.accountTypeKey == 1">
          <template v-if="rankIcon">
            <svg-icon :icon-class="rankIcon" class="icon" />
          </template>
          <svg-icon icon-class="qiye" class="icon" />
          <span class="nickname text-el">{{ account.enterprise }}</span>
        </template>
        <template v-else>
          <svg-icon icon-class="geren" class="icon" />
          <span>{{ account.nickName }}</span>
        </template>
      </div>
      <auth-certification v-if="!account.isAuthentication"></auth-certification>
      <template v-else>
        <el-button type="primary" class="button-login" @click="toCloud"
          >进入云仓 <i class="el-icon-arrow-right"></i>
        </el-button>
        <el-button
          v-if="hasSupervise"
          type="primary"
          class="button-login"
          @click="toSupervise"
          >进入监管 <i class="el-icon-arrow-right"></i
        ></el-button>
      </template>
      <!-- <el-button type="primary" class="button-login" @click="logout"
        >退出</el-button
      > -->
    </template>
    <template v-else>
      <div>
        <div class="input-box">
          <ui-form
            ref="login"
            :columns="loginColumns"
            :ndiv="1"
            :labelWidth="70"
          />
        </div>
        <div class="yinsi" style="padding: 0 10px; font-size: 14px">
          <el-checkbox v-model="isCheck"></el-checkbox>
          <a href="https://yc.tswlsz.com/#/privacy" target="_blank"
            >隐私政策与服务协议</a
          >
        </div>
        <div class="fl ju-c">
          <el-button type="plain" @click="showRegister = true">注册</el-button>
          <el-button type="primary" @click="submit">登录</el-button>
        </div>
      </div>
    </template>

    <el-dialog
      title="注册账号"
      width="900px"
      :visible.sync="showRegister"
      :close-on-click-modal="false"
      :show-close="false"
      append-to-body
      class="register-dialog"
    >
      <div class="nav fl al-c">
        <div
          :class="['nav-item', isCompany ? '' : 'active']"
          @click="change(false)"
        >
          个人注册
        </div>
        <div
          :class="['nav-item', isCompany ? 'active' : '']"
          @click="change(true)"
        >
          企业注册
        </div>
      </div>
      <template v-if="showForm">
        <ui-form ref="register" :columns="columns" :ndiv="2" :labelWidth="90" />
        <el-form
          v-if="!isCompany"
          ref="yzmForm"
          :rules="loginRules2"
          inline
          style="padding: 10px 40px"
          :model="yzmForm"
        >
          <el-form-item label="验证码" prop="yzm">
            <el-input
              placeholder="请输入验证码"
              v-model="yzmForm.yzm"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button v-if="downTime == 0" type="primary" @click="sendYzmClick"
              >发送验证码</el-button
            >
            <div v-else>{{ downTime }}</div>
          </el-form-item>
        </el-form>
      </template>
      <div class="yinsi">
        <el-checkbox v-model="isCheck"></el-checkbox>
        <a href="https://yc.tswlsz.com/#/privacy" target="_blank"
          >隐私政策与服务协议</a
        >
      </div>
      <div class="dialog-bottom">
        <el-button class="bottom-btn" @click="close">取消</el-button>
        <el-button type="primary" class="bottom-btn" @click="registerSubmit"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { UiForm } from "@/ui";
import { MessageBox } from "element-ui";
import { mapGetters, mapMutations } from "vuex";
import { registerAccount, loginColumns, registerCompany } from "./columns";
import service from "./service";
import noticeHtml from "@/utils/noticeHtml";
import authCertification from "../authCertification/index.vue";
import db from "@/utils/localstorage";
export default {
  computed: {
    ...mapGetters(["account", "roleList"]),
  },
  components: {
    UiForm,
    authCertification,
  },
  data() {
    return {
      registerAccount,
      loginColumns,
      registerCompany,
      columns: registerAccount,
      showRegister: false,
      hasSupervise: false,
      rankIcon: "",
      headUrl: db.get("headUrl"),
      loginRules2: {
        yzm: [{ required: true, trigger: "blur", message: "请输入验证码" }],
      },
      yzmForm: {
        yzm: "",
      },
      isCheck: true,
      downTime: 0,
      timer: null,
      isCompany: false,
      showForm: true,
      isProd: false,
    };
  },
  mounted() {
    this.isProd = process.env.VUE_APP_BASE_API === "/prod-api";
    if (this.roleList) {
      this.hasSupervise = this.roleList.some(
        (i) => i.platformTypeKey === "SUPERVISE"
      );
    }
    this.getRankIcon();
  },
  methods: {
    ...mapMutations("oauth", [
      "setToken",
      "setEnterpriseId",
      "setAccount",
      "setRoleList",
    ]),
    change(isCompany) {
      this.isCompany = isCompany;
      this.showForm = false;
      if (isCompany) {
        this.columns = this.registerCompany;
      } else {
        this.columns = this.registerAccount;
      }
      this.$nextTick(() => {
        this.showForm = true;
      });
    },
    async sendYzmClick() {
      let data = await this.$refs["register"].validate();
      if (data.phone && !this.timer) {
        await service.getYzm(data.phone);
        this.downTime = 59;
        this.timer = setInterval(() => {
          if (this.downTime == 0) {
            clearInterval(this.timer);
            this.timer = null;
            return;
          }
          this.downTime--;
        }, 1000);
      }
    },
    getRankIcon() {
      let icons = ["jinpai", "yinpai", "tongpai"];
      let key = this.account.memberTypeKey ? this.account.memberTypeKey : "";
      this.rankIcon = key ? icons[+key + 1] : "";
    },
    toCloud() {
      if (this.isProd) {
        window.open(
          `https://yc.tswlsz.com/#/login?tk=${this.account.token}&aid=${this.account.accountId}`,
          // `http://192.168.110.208:88/#/login?tk=${this.account.token}&aid=${this.account.accountId}`,
          "_blank"
        );
      } else {
        window.open(
          `http://192.168.110.208:88/#/login?tk=${this.account.token}&aid=${this.account.accountId}`,
          "_blank"
        );
      }
    },
    toSupervise() {
      if (this.isProd) {
        window.open(
          `https://jg.tswlsz.com/#/login?tk=${this.account.token}&aid=${this.account.accountId}`,
          "_blank"
        );
      } else {
        window.open(
          `http://192.168.110.208:66/#/login?tk=${this.account.token}&aid=${this.account.accountId}`,
          "_blank"
        );
      }
    },
    logout() {
      MessageBox.confirm(noticeHtml("确定退出吗？"), "提示", {
        dangerouslyUseHTMLString: true,
        center: true,
      }).then(() => {
        this.$store.dispatch("oauth/logout");
      });
    },
    async getRoles(enterpriseId) {
      if (!enterpriseId) return;
      let data = await service.roleList({
        enterpriseId,
      });
      // this.roleList = data;
      this.setRoleList(data);
      this.hasSupervise = data.some((i) => i.platformTypeKey === "SUPERVISE");
    },
    async getAccountDetail(accountId) {
      let data = await service.getAccountDetail(accountId);
      this.headUrl = data?.headPortraitUrl;
      db.set("headUrl", data?.headPortraitUrl);
    },
    async submit() {
      const loginData = await this.$refs.login.validate();
      if (!this.isCheck) {
        this.$message({
          type: "error",
          message: "请先勾选隐私政策与服务协议",
        });
        return;
      }
      service.login({ ...loginData, prefix: "PC_" }).then(({ data }) => {
        this.setAccount(data);
        this.setToken(data.token);
        this.setEnterpriseId(data.enterpriseId);
        this.getRoles(data.enterpriseId);
        this.getRankIcon();
        this.getAccountDetail(data.accountId);
        this.$emit("loginSuccess");
      });
    },
    async registerSubmit() {
      const registerData = await this.$refs.register.validate();

      if (!this.isCheck) {
        this.$message({
          type: "error",
          message: "请先勾选隐私政策与服务协议",
        });
        return;
      }
      //验证 验证码
      if (this.isCompany) {
        await service.registerCompany(registerData);
      } else {
        const yzm = await this.$refs["yzmForm"].validate();
        await service.checkYzm({
          phone: registerData.phone,
          code: this.yzmForm.yzm,
        });
        await service.register(registerData);
      }

      this.$message({
        type: "success",
        message: "注册成功",
      });
      this.showRegister = false;
      clearInterval(this.timer);
      this.timer = null;
      this.downTime = 0;
    },
    close() {
      clearInterval(this.timer);
      this.timer = null;
      this.downTime = 0;
      this.showRegister = false;
    },
  },
};
</script>

<style scoped lang="scss">
.login-container {
  width: 100%;
  height: 100%;
  background: #fff;
  // justify-content: space-around;
  font-size: 18px;
  color: #333;
  box-sizing: border-box;
}
.avatar {
  width: 86px;
  height: 86px;
  border-radius: 50%;
  overflow: hidden;
  margin: 61px auto 27px;
}
.avatar img {
  width: 100%;
  height: auto;
  display: block;
}
.account-name {
  // width: 105px;
  font-size: 18px;
  margin: 15px auto 30px;
}
.nickname {
  max-width: 110px;
}
.account-name .icon {
  width: 22px;
  height: 22px;
}
.button-login {
  width: 102px;
  display: block;
  margin: 0 !important;
}
.button-login:first-of-type {
  margin-bottom: 10px !important;
}
.dialog-bottom {
  display: flex;
  justify-content: center;
}
.yinsi {
  padding-left: 100px;
  margin: 15px 0;
}
.yinsi a {
  color: #3c9cff;
  margin-left: 10px;
}
.nav {
  padding: 20px 0;
}
.nav-item {
  font-size: 18px;
  padding: 4px 0;
  margin-right: 20px;
  position: relative;
  cursor: pointer;
  color: #000;
}
.active {
  font-weight: bold;
  font-size: 20px;
}
.active::after {
  content: "";
  width: 80%;
  height: 4px;
  border-radius: 1px;
  background: #3c9cff;
  position: absolute;
  bottom: 0;
  left: 0;
}
::v-deep .el-dialog__body {
  padding: 30px 70px;
}
</style>
