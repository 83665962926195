import Util from '../util'

/**
 * 引用类型不在这里添加
 */
const defaultSchema: Schema = {
  valueType: 'text',
  dataIndex: '_',
  title: '_',
  required: true,
  limit: 1,
}

/**
 * Schema -> IntactSchema
 *
 * TODO: 初始化 schema 时没有使用深拷贝,但是注意深拷贝时不要破坏原数据,如: 正则表达式
 */
export const buildSchema = (build: Schema): IntactSchema => {
  const schema = {
    ...defaultSchema,
    fieldProps: {},
    ...build,
  } as IntactSchema

  const { valueType, dataIndex, initialValue } = schema

  schema.chainIndex = Util.deChain(dataIndex)

  if (valueType === 'switch') {
    schema.initialValue = initialValue === undefined ? false : initialValue
  } else if (valueType === 'fuzzy') {
    schema.tip = '输入关键字查询'
  }

  schema.width = ((): number | undefined => {
    const { width: value } = schema
    if (value) {
      return value
    } else if (valueType === 'date') {
      return 160
    } else if (valueType === 'switch') {
      return 144
    }
  })()

  schema.rule = ((): Field.Rule[] => {
    const { rule: list = [], required } = schema
    if (required !== false) {
      list.push({
        required: true,
        message: '不能为空',
        trigger: 'blur',
      })
    }
    if (/^(password|chinese|number)$/.test(valueType)) {
      list.push({
        pattern: /^\S+$/,
        message: '不能包含空格',
        trigger: 'blur',
      })
    } else if (/^select$/.test(valueType)) {
      list.push({
        pattern: /^((?!\-1)\S{2}|\S?|\S{3,99})$/,
        message: '原数据已被删除',
        trigger: 'blur',
      })
    }
    return list
  })()

  schema.valueEnum = buildValueEnum(schema)

  return {
    ...schema,
  }
}

const buildValueEnum = (
  schema: Schema
): Record<string, Field.Text> | undefined => {
  const { valueType, valueEnum } = schema

  if (valueType === 'switch') {
    if (!valueEnum) {
      return {
        true: { text: '启用', tag: 'success' },
        false: { text: '停用', tag: 'danger' },
      }
    } else if (Array.isArray(valueEnum)) {
      return {
        true: { text: valueEnum[0], tag: 'success' },
        false: { text: valueEnum[1], tag: 'danger' },
      }
    }
  }

  if (valueEnum) {
    if (Array.isArray(valueEnum)) {
      return valueEnum.reduce(
        (r, text) => ({ ...r, [text]: { text } }),
        {} as Record<string, Field.Text>
      )
    }
    if (typeof valueEnum === 'object') {
      return Object.entries(valueEnum).reduce(
        (r, [key, value]) => ({
          ...r,
          [key]: typeof value === 'string' ? { text: value } : value,
        }),
        {} as Record<string, Field.Text>
      )
    }
  }
}
