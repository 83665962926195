export default {
  disable: true,
  order: 3,
  label: "手势",
  head: "fa fa-",
  list: [
    "hand-grab-o",
    "hand-lizard-o",
    "hand-o-down",
    "hand-o-left",
    "hand-o-right",
    "hand-o-up",
    "hand-paper-o",
    "hand-peace-o",
    "hand-pointer-o",
    "hand-rock-o",
    "hand-scissors-o",
    "hand-spock-o",
    "hand-stop-o",
    "thumbs-down",
    "thumbs-o-down",
    "thumbs-o-up",
    "thumbs-up",
  ]
}