export default {
  disable: true,
  order: 8,
  label: "可旋转",
  head: "fa fa-",
  list: [
    "circle-o-notch",
    "cog",
    "gear",
    "refresh",
    "spinner",
  ]
}