<template>
  <div
    :class="visible ? 'im open' : 'im'"
    @mousedown="handleMouseDown"
    @mousemove.prevent="handleMouseMove"
    @mouseup.prevent="handleMouseUp"
  >
    <!-- <div class="mask"></div> -->
    <div class="im-center" :style="{ left: mLeft + 'px', top: mTop + 'px' }">
      <div class="header fl">
        <div class="close" @click.stop="close">
          <i class="el-icon-close close-icon"></i>
        </div>
      </div>
      <div class="main fl">
        <!-- 好友列表 -->
        <div class="friends">
          <div
            v-for="(item, index) in friendsList"
            :key="index"
            :class="
              activeFriend == index ? 'friends-item active' : 'friends-item'
            "
            @click.stop="friendClick(item, index)"
          >
            <div class="name">{{ item.sendEnterprise }}</div>
            <span
              class="unread-count"
              v-if="activeFriend !== index && item.unreadQuantity > 0"
              >{{ item.unreadQuantity }}</span
            >
          </div>
        </div>
        <div class="im-box">
          <div class="view" ref="view">
            <div v-for="(item, index) in list" :key="index">
              <div
                v-if="item.sendAccountId"
                :class="
                  item.sendAccountId == account.accountId
                    ? 'message-box right'
                    : 'message-box left'
                "
              >
                <span class="avater">
                  <el-avatar
                    shape="square"
                    size="small"
                    src="https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"
                  ></el-avatar>
                </span>
                <span
                  :class="
                    item.sendAccountId == account.accountId
                      ? 'message-bg right'
                      : 'message-bg left'
                  "
                  v-html="item.message"
                ></span>
              </div>
              <div v-else class="time">
                <span>{{ item.message }}</span>
              </div>
            </div>
            <div
              :class="['goods fl al-c ju-b', showGoods ? '' : 'goods-hide']"
              v-if="goods"
            >
              <div class="goods-left fl al-c">
                <div class="goods-img">
                  <img :src="goods.fileUrl" alt="" />
                </div>
                <div class="goods-desc">
                  <div class="goods-title">
                    {{ goods.goodsType }} {{ goods.goods }}
                    {{ goods.specifications }}{{ goods.materialTexture }}
                  </div>
                  <div class="goods-text">仓库：{{ goods.warehouse }}</div>
                  <div class="goods-tips">定额违约金</div>
                </div>
              </div>
              <div class="goods-right">
                <div class="goods-title">1件/{{ goods.unitWeight }}吨</div>
                <div class="price money-f">¥{{ goods.price }}</div>
                <div class="goods-btn">
                  <el-button type="primary" size="small">发送</el-button>
                </div>
              </div>
            </div>
          </div>

          <div class="input-box">
            <div class="file-toolbox">
              <div class="file-icon">
                <!-- <i class="el-icon-folder-opened"></i> -->
                <file-field
                  ref="file-field"
                  v-model="fileValue"
                  size="small"
                  :schema="schema"
                  value=""
                  :initialValues="initialValues"
                  @input="uploadChange"
                />
              </div>
            </div>
            <div class="text-box">
              <textarea
                class="textarea"
                v-model="currentMsg"
                maxlength="100"
                placeholder="最多可输入100个字"
                @keydown.enter.prevent="sendMsg"
              ></textarea>
            </div>

            <div class="send-box">
              <el-button
                type="primary"
                size="small"
                class="send-btn"
                @click="sendMsg"
                >发送</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import db from "@/utils/localstorage";
import FileField from "../form/label-field/modules/file-field.vue";
import { chatListAll, getUserList } from "./api";
export default {
  components: {
    FileField,
  },
  props: {
    goods: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      schema: {
        dataIndex: "upload__string",
        chainIndex: "upload.string",
        fieldProps: {
          icon: "upload",
        },
        title: "文件上传",
        valueType: "file",
        tip: "只能上传jpg/png文件，且不超过500kb",
        limit: 3,
      },
      chaindata: "file",
      fileValue: "",
      initialValues: {},
      messageList: {},
      list: [],
      currentMsg: "", //当前要发送的消息

      canWebsocket: true,
      websocket: {},
      account: db.get("account"),
      visible: false,
      token: db.get("token"),

      sendTimer: null,

      receiveAccountId: "", //接收消息方ID
      title: "",

      friendsList: [],
      activeFriend: 0,

      messageTime: 0,

      startX: 0,
      startY: 0,
      mLeft: 80,
      mTop: 80,
      cLeft: 0,
      cTop: 400,
      isClick: false,
      showGoods: true,
    };
  },
  created() {
    if (typeof WebSocket === "undefined") {
      this.canWebsocket = false;
      return;
    }
    // this.getMessage()
    // this.initSocket()
  },
  computed: {
    chatTitle() {
      if (this.friendsList.length) {
        return this.friendsList[this.activeFriend]["sendEnterprise"];
      }
      return "";
    },
    // list(){
    //   if(this.messageList[this.receiveAccountId]){
    //     return this.messageList[this.receiveAccountId]
    //   }
    // }
  },
  watch: {
    messageList() {},
  },
  methods: {
    handleMouseDown(e) {
      if (e.target.className.includes("head")) {
        this.startX = e.clientX;
        this.startY = e.clientY;
        this.cLeft = e.target.parentElement.offsetLeft;
        this.cTop = e.target.parentElement.offsetTop;
        this.isClick = true;
      }
    },
    handleMouseMove(e) {
      if (this.isClick) {
        let mx = e.clientX;
        let my = e.clientY;
        let mLeft = mx - this.startX + this.cLeft;
        let mTop = my - this.startY + this.cTop;
        let cw = e.target.parentElement.clientWidth;
        if (mLeft <= 0 || mLeft >= document.documentElement.clientWidth - cw)
          return;
        if (mTop <= 0 || mTop >= document.documentElement.clientHeight - 732)
          return;
        this.mLeft = mLeft;
        this.mTop = mTop;
      }
    },
    handleMouseUp(e) {
      this.isClick = false;
    },
    //点击左侧好友
    friendClick(friend, index) {
      this.activeFriend = index;
      this.receiveAccountId = friend.sendAccountId;
      friend.unreadQuantity = 0;
      this.friendsList.splice(index, 1, friend);
      this.getMessage();
    },
    //获取左侧好友列表消息
    async getFriends(id) {
      let { data } = await getUserList();
      this.friendsList.push({
        sendAccountId: id,
        sendEnterprise: this.title,
        unreadQuantity: 0,
      });
      this.initSocket();
      // this.friendsList = data;
      // if (data.length) {
      //   // this.receiveAccountId = data[0]["sendAccountId"];
      //   // data.filter(i => i.sendAccountId == this.receiveAccountId)
      //   this.initSocket();
      // } else {
      //   this.friendsList.push({
      //     sendAccountId: id,
      //     sendEnterprise: this.title,
      //     unreadQuantity: 0,
      //   });
      //   this.initSocket();
      //   this.getMessage();
      // }

      // if(data.length == 0){
      //   this.$message({
      //     type:'info',
      //     message:'暂无消息'
      //   })
      // }
    },
    setSend(id, title) {
      this.receiveAccountId = id;

      this.title = title;
      this.getFriends(id);
      // this.initSocket();
      // this.getMessage();
    },
    uploadChange(list) {
      console.log(list);
    },
    openDialog() {
      this.visible = true;
      document.body.style.overflow = "hidden";
      setTimeout(() => {
        this.showGoods = false;
      }, 3000);
      // this.scrollToBottom()
    },
    sendMsg() {
      if (this.sendTimer) return;
      this.sendTimer = setTimeout(() => {
        clearTimeout(this.sendTimer);
        this.sendTimer = null;
      }, 500);
      let fileList = this.$refs["file-field"].fileList;
      console.log(fileList);
      if (this.currentMsg.length == 0 && fileList.length == 0) {
        this.$message({
          type: "warning",
          message: "发送内容不能为空",
        });
        return;
      }
      if (this.currentMsg.length > 100) {
        this.$message({
          type: "warning",
          message: "发送消息不能超过100字",
        });
        return;
      }
      let message = "";
      if (fileList.length) {
        fileList.forEach((file) => {
          //图片格式
          let isImg = /\.(png|jpg|jpeg|gif)$/.test(file.name);
          if (isImg) {
            message = message + `<img src="${file.response.obsUrl}" ><br>`;
          } else {
            message =
              message +
              `<a class="file-a" href="${file.response.obsUrl}" >${file.name}</a><br>`;
          }
        });
      }
      message = message + this.currentMsg;
      let msg = {
        message,
        receiveAccountId: this.receiveAccountId,
      };
      this.websocket.send(JSON.stringify(msg));
      console.log("发送中...");
    },
    //socket接收消息 展示消息数量
    showBadge(id) {
      if (this.receiveAccountId == id) return;
      let index = this.friendsList.findIndex((i) => i.sendAccountId == id);
      let unreadQuantity = this.friendsList[index].unreadQuantity
        ? this.friendsList[index].unreadQuantity
        : 0;
      let friend = this.friendsList[index];
      friend.unreadQuantity = unreadQuantity + 1;
      this.friendsList.splice(index, 1, friend);
    },
    socketList(sendAccountId) {
      // let baseUrl = 'ws://dd.wdscf.com'
      let baseUrl = "ws://192.168.110.115:8088";
      let url = baseUrl + "/chatServer/" + this.account.enterpriseId;
      this.websocket[sendAccountId] = new WebSocket(url);
      this.websocket[sendAccountId].onopen = () => {
        console.log("连接成功");
      };
      this.websocket[sendAccountId].onmessage = (evt) => {
        console.log("返回数据：", evt);

        let { data } = evt;
        if (data) {
          let obj = {};
          if (data.indexOf("message") > -1 && data.indexOf("{") > -1) {
            obj = JSON.parse(data).data
              ? JSON.parse(data).data
              : JSON.parse(data);
          } else {
            obj = {
              sendAccountId: this.receiveAccountId,
              receiveAccountId: this.account.enterpriseId,
              message: data,
            };
          }
          if (this.receiveAccountId == obj.sendAccountId) {
            let index = this.friendsList.findIndex(
              (i) => i.sendAccountId == obj.sendAccountId
            );
            let f = this.friendsList[index];
            f.unreadQuantity = 0;
            this.friendsList.splice(index, 1, f);
            return;
          }
          this.messageList[obj.sendAccountId] = this.messageList[
            obj.sendAccountId
          ]
            ? this.messageList[obj.sendAccountId]
            : [];
          this.messageList[obj.sendAccountId].push(obj);
          //好友列表显示未读消息
          this.showBadge(obj.sendAccountId);
          this.$emit("onMessage", obj.sendAccountId);
          this.$refs["file-field"].fileList.splice(0);
        }
        this.currentMsg = "";
        this.scrollToBottom();
      };
      this.websocket[sendAccountId].onerror = (evt) => {
        console.log("错误：", evt);
        this.$message({
          type: "error",
          message: "连接失败",
        });
      };
    },
    initSocket() {
      this.getMessage();
      // for (let i in this.friendsList) {
      //   this.socketList(this.friendsList[i].sendAccountId)
      // }
      // let url =
      //   "ws://192.168.110.115:8088/chatServer/" + this.account.enterpriseId;
      let url =
        "wss://api.tswlsz.com/message/chatServer/" + this.account.accountId;
      this.websocket = new WebSocket(url);
      this.websocket.onopen = () => {
        console.log("连接成功");
      };
      this.websocket.onmessage = (evt) => {
        console.log("返回数据：", evt);

        let { data } = evt;
        if (data) {
          let obj = {};
          if (data.indexOf("message") > -1 && data.indexOf("{") > -1) {
            obj = JSON.parse(data).data
              ? JSON.parse(data).data
              : JSON.parse(data);
          } else {
            obj = {
              sendAccountId: this.receiveAccountId,
              receiveAccountId: this.account.accountId,
              message: data,
            };
          }
          console.log(obj);
          if (
            this.receiveAccountId == obj.sendAccountId &&
            this.messageTime == 0
          ) {
            let index = this.friendsList.findIndex(
              (i) => i.sendAccountId == obj.sendAccountId
            );
            let f = this.friendsList[index];
            f.unreadQuantity = 0;
            this.friendsList.splice(index, 1, f);
          } else {
            let which = obj.sendAccountId;
            if (obj.sendAccountId == this.account.accountId) {
              //自己主动发的
              which = obj.receiveAccountId;
            } else {
              //被动接收的
            }
            this.messageList[which] = this.messageList[which]
              ? this.messageList[which]
              : [];
            this.messageList[which].push(obj);
            //好友列表显示未读消息
            this.showBadge(which);
            this.$emit("onMessage", which);
          }
          this.messageTime++;
          this.$refs["file-field"].fileList.splice(0);
        }
        this.currentMsg = "";
        this.scrollToBottom();
      };
      this.websocket.onerror = (evt) => {
        console.log("错误：", evt);
        this.$message({
          type: "error",
          message: "连接失败",
        });
      };
    },
    async getMessage() {
      if (!this.messageList[this.receiveAccountId]) {
        let { data } = await chatListAll({
          sendAccountId: db.get("account")?.accountId,
          receiveAccountId: this.receiveAccountId,
        });
        this.messageList[this.receiveAccountId] = data;
        if (this.messageList[this.receiveAccountId]) {
          this.list = this.messageList[this.receiveAccountId];
        }
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      } else {
        this.list = this.messageList[this.receiveAccountId];
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      }
    },
    scrollToBottom() {
      // this.$nextTick(() => {
      //   this.$refs["view"].scrollTop = this.$refs["view"].scrollHeight;
      // });
    },
    close() {
      document.body.style.overflow = "visible";
      this.visible = false;
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.im {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  // bottom: 0;
  // right: 0;
  z-index: 999;
  transform: scale(0);
  transition: all 0.3s;
  overflow: hidden;
}
.open {
  transform: scale(1);
}
.im-center {
  width: 37%;
  height: 732px;
  // max-height: 85%;
  position: absolute;
  bottom: 80px;
  left: 80px;
  border-radius: 10px;
  background: #eaeaea;
  overflow: hidden;
  box-shadow: 0px 21px 27px rgba(51, 51, 51, 0.1);
}
.header {
  height: 57px;
  background: #3c9cff;
  text-align: right;
  line-height: 57px;
  color: #fff;
  font-size: 20px;
  .close {
    cursor: pointer;
    margin-left: auto;
  }
  .close-icon {
    padding: 10px;
    margin-right: 14px;
  }
}
.main {
  height: calc(100% - 57px);
}
.im-box {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
}
.im-title {
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  background: #fafafa;
}
.view {
  height: 300px;
  overflow: auto;
  padding: 0px 30px;
  border-bottom: 1px solid #f0f0f0;
  line-height: 40px;
  position: relative;
  .message-box {
    line-height: 24px;
    align-items: center;
    display: flex;
    align-items: center;
    margin: 10px 0;
    .avater {
      display: flex;
      align-items: center;
      border-radius: 50%;
      width: 54px;
      height: 54px;
      overflow: hidden;
      & ::v-deep .el-avatar--small {
        width: 100%;
        height: 100%;
      }
    }
  }
  .message-box.right {
    justify-content: flex-end;
  }
  .message-box.right .avater {
    order: 2;
  }
  .message-bg {
    line-height: 38px;
    padding: 0 10px;
    position: relative;
    background: #fff;
    margin: 0 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-size: 14px;
  }

  .message-bg.left {
    text-align: left;
    &::after {
      content: "";
      width: 5px;
      height: 5px;
      border-top: 5px solid transparent;
      border-left: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 5px solid #eaeaea;
      position: absolute;
      left: -9px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .message-bg.right {
    text-align: right;
    &::after {
      content: "";
      width: 5px;
      height: 5px;
      border-top: 5px solid transparent;
      border-left: 5px solid #eaeaea;
      border-bottom: 5px solid transparent;
      border-right: 5px solid transparent;
      position: absolute;
      right: -9px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .message-bg ::v-deep a {
    text-decoration: underline;
    color: #409eff;
  }

  .time {
    text-align: center;
    & > span {
      padding: 5px 10px;
      background: #eaeaea;
      border-radius: 3px;
    }
  }
}
.view ::v-deep img {
  width: auto;
}
.input-box {
  // height: 200px;
  padding: 10px 30px 24px;
  flex: 18%;
  display: flex;
  flex-direction: column;
  .file-toolbox {
    display: flex;
    align-items: center;
    line-height: 30px;
    .file-icon {
      padding: 0 10px;
      cursor: pointer;
      .upload-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
  .text-box {
    height: 100px;
    flex: 5%;
    padding: 0 0 15px 0;
    overflow: hidden;
    .textarea {
      width: 100%;
      height: 100%;
      border: 0;
      outline: none;
      resize: none;
      display: block;
    }
  }
  .send-box {
    height: 35px;
    display: flex;
    justify-content: flex-end;
    .send-btn {
      padding: 0 50px;
    }
  }
}
.avater img {
  width: auto !important;
}
.friends {
  width: 260px;
  height: 100%;
  background: #fff;
  overflow: auto;
  border-right: 1px solid #f0f0f0;
  .friends-item {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 15px;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    .name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .unread-count {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 15px;
      height: 15px;
      text-align: center;
      line-height: 15px;
      background: #f56c6c;
      border-radius: 50%;
      color: #fff;
      font-size: 14px;
    }
  }
  .friends-item.active {
    background: #f5faff;
  }
}
.goods {
  width: 95%;
  height: 121px;
  padding: 15px;
  box-sizing: border-box;
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 4px 10px rgba(51, 51, 51, 0.1);
  border-radius: 4px;
  transition: all 0.3s;
  background: #fff;
  z-index: 10;
  .goods-img {
    width: 92px;
    height: 92px;
    background: #d9d9d9;
    margin-right: 7px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .goods-title {
    font-size: 14px;
    line-height: 20px;
    color: #333;
  }
  .goods-text {
    font-size: 12px;
    line-height: 17px;
    color: #666;
    margin: 6px 0 10px;
  }
  .goods-tips {
    display: inline-block;
    color: #ef984a;
    font-size: 12px;
    line-height: 1em;
    padding: 2px 6px;
    background: #fcf0e3;
    border-radius: 1px;
  }
  .goods-right {
    text-align: right;
  }
  .money-f {
    font-size: 16px;
    line-height: 20px;
    color: #bb271a;
    margin: 2px 0 20px;
  }
}
.goods-hide {
  opacity: 0;
  height: 0;
  overflow: hidden;
}
</style>
