<template>
  <div class="ui-descriptions" :style="getStyle()">
    <el-descriptions :size="size" :column="ndiv" :border="border">
      <template v-for="s in schemas">
        <el-descriptions-item
          v-if="!s.hideInDesc"
          :key="s.chainIndex"
          :label="s.title"
          :contentStyle="{
            'text-align': 'left',
            display: 'inline-block',
            'white-space': 'nowrap',
          }"
          :labelStyle="{
            'text-align': 'left',
            display: 'inline-block',
            'white-space': 'nowrap',
          }"
        >
          <descriptions-item
            :record="chaindata"
            :schema="s"
            :editable="editable && s.editable"
            @change="(value, cb) => changeField(s, value, cb)"
          />
        </el-descriptions-item>
      </template>
    </el-descriptions>
    <slot></slot>
  </div>
</template>

<script>
import DescriptionsItem from './item.vue'
import formMixin from '../../mixins/form'
export default {
  name: 'ui-descriptions',
  components: { DescriptionsItem },
  mixins: [formMixin],
  props: {
    size: {
      type: String,
      default: 'medium',
    },
    ndiv: {
      type: Number,
      default: 1,
    },
    border: {
      type: Boolean,
      default: false,
    },
    editable: Boolean,
    config: {
      type: Object,
      default: () => {
        return {
          alignLeft: false,
          noPadding: true,
        }
      },
    },
  },
  data() {
    return {
      //
    }
  },
  created() {
    this.update()
  },
  methods: {
    getStyle() {
      if (!this.config.noPadding) {
        return { width: 'auto', margin: 'auto' }
      }
      if (this.config.alignLeft) {
        return { padding: '20px 0 20px 160px', width: 'auto' }
      } else {
        return { padding: '0 160px', width: 'auto', margin: 'auto' }
      }
    },
    update() {
      return new Promise((resolve) => {
        this.$emit('request', (record) => {
          this.chaindata = { ...record }
          resolve()
        })
      })
    },
    changeField(schema, value, cb) {
      const { chainIndex } = schema
      this.$emit(
        'change',
        {
          [chainIndex]: value,
        },
        async () => {
          await this.update()
          cb()
        }
      )
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-descriptions-item__container {
  margin: 0 12px;
  display: flex;
  // align-items: center;
  // justify-content: center;
  .el-descriptions-item__content {
    color: #000000;
    flex: none;
    // text-align: center;
    i {
      line-height: 1.5 !important;
    }
  }
}
::v-deep .el-descriptions__table tbody:last-child td {
  display: inline-block;
  width: 100%;
}
.ui-descriptions {
  width: 100%;
}
::v-deep .el-descriptions__table {
  width: auto;
  // margin: 0 auto;
}
::v-deep .el-descriptions__body {
  color: #000;
}
::v-deep
  .el-descriptions__body
  .el-descriptions__table
  .el-descriptions-row
  .el-descriptions-item__cell:first-child {
  padding-right: 40px;
}
::v-deep
  .el-descriptions--medium:not(.is-bordered)
  .el-descriptions-item__cell {
  padding-bottom: 17px;
}
</style>
