import axios from '@/axios'

export function getUserList(receiveAccountId) {
  return axios({
    url: '/chatRecord/sendUserList',
    method: 'GET',
    data: {
      receiveAccountId,
    },
  })
}

export function chatListAll(data) {
  return axios({
    url: '/chatRecord/listAll',
    method: 'GET',
    data,
  })
}
