import axios from '../index'

export default {
  submit(data:any){
    return axios({
      url:'/warehouseMessage/submit',
      method:'POST',
      data
    })
  },
  list(data:any){
    return axios({
      url:'/warehouseMessage/list',
      method:'GET',
      data
    })
  },
  publicList(data:any){
    return axios({
      url:'/warehouseMessage/public/list',
      method:'GET',
      data:{
        ...data,
        isPublic:1
      }
    })
  },
  detail(id:any){
    return axios({
      url:'/warehouseMessage/detail',
      method:'GET',
      data:{
        id
      }
    })
  },
  publicDetail(id:any){
    return axios({
      url:'/warehouseMessage/public/detail',
      method:'GET',
      data:{
        id
      }
    })
  },
  isCollect(messageId:any){
    return axios({
      url:'/warehouseMessageCollection/isCollection',
      method:'GET',
      data:{
        messageId
      }
    })
  },
  collect(messageId:any){
    return axios({
      url:'/warehouseMessageCollection/collection',
      method:'GET',
      data:{
        messageId
      }
    })
  },
  remove(id:any){
    return axios({
      url:'/warehouseMessage/remove?id='+id,
      method:'POST',
    })
  },
  pageView(id:any){
    return axios({
      url:'/warehouseMessage/public/pageView?id='+id,
      method:'GET',
    })
  },
  submitConfirm(id:any){
    return axios({
      url:'/warehouseMessage/submitConfirm?id='+id,
      method:'GET',
    })
  },
  //审核  id status:1审批通过 2审批不通过
  check(data:any){
    return axios({
      url:'/warehouseMessage/check',
      method:'GET',
      data
    })
  },
  toConfirm(id:any){
    return axios({
      url:'/warehouseMessage/submitConfirm',
      method:'GET',
      data:{
        id
      }
    })
  },
}