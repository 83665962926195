import Util from './util'

const localStorage = window.localStorage

const db = {
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },

  get(key, defaultValue) {
    const value = localStorage.getItem(key)
    if (Util.notNull(value)) {
      try {
        return JSON.parse(value)
      } catch (e) {
        Util.error('缓存数据异常:', e)
      }
    }
    return defaultValue
  },

  remove(key) {
    localStorage.removeItem(key)
  },

  clear() {
    localStorage.clear()
  },
}

export default db
