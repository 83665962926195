<template>
  <div class="view-container">
    <el-page-header
      v-if="!hideHeader"
      class="view-header"
      @back="goBack"
      :content="title"
    />
    <div class="view-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ui-page-view',
  props: {
    hideHeader: Boolean,
    title: String,
    canBack: Boolean,
  },
  computed: {
    ...mapState('app', ['crumbs']),
  },
  methods: {
    goBack() {
      this.$store.dispatch(
        'app/setCrumbs',
        this.crumbs.slice(0, this.crumbs.length - 1)
      )
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
.view-container {
  border-radius: 0;
  width: calc(100% - 24px);
  margin: 12px;
  background-color: #ffffff;

  .view-header {
    padding: 20px;
    border-bottom: 1px solid #f0f0f0;
  }

  .view-content {
    width: 100%;
  }
}
</style>
