<template>
  <el-select
    clearable
    v-model="result"
    :size="size"
    :multiple="schema.multiple"
    :placeholder="schema.placeholder"
  >
    <el-option
      v-for="(option, index) in options"
      :key="option.value + '-' + index"
      :value="'' + option.value"
      :label="option.label"
    />
  </el-select>
</template>

<script>
import Util from "../../../../lib/util";
import { buildList, buildString } from "../lib/select";
export default {
  name: "select-field",
  props: {
    schema: Object,
    value: {},
    tempdata: {
      type: Object,
      default: () => ({}),
    },
    chaindata: Object,
    size: String,
  },
  data() {
    const { multiple } = this.schema;
    return {
      options: [],
      result: multiple ? buildList(this.value) : buildString(this.value),
    };
  },
  watch: {
    schema: {
      deep: true,
      immediate: true,
      handler(n) {
        n.valueEnum && this.resetOptions();
      },
    },
    value() {
      const { multiple, listener } = this.schema;
      if (listener) {
        this.result = multiple
          ? buildList(this.value)
          : buildString(this.value);
      }
    },
    /**
     * 当 dependencies 内的变量更新时重置当前field
     */
    async chaindata(newVal, oldVal) {
      const { dependencies: deps = [] } = this.schema;
      (typeof deps === "string" ? [deps] : deps).forEach((dep) => {
        if (newVal[dep] !== oldVal[dep]) {
          // console.log(newVal[dep], oldVal[dep], dep, 'newVal[dep], oldVal[dep]')
          if (oldVal[dep]) {
            // this.result = undefined
            this.resetOptions();
          } else {
            this.resetOptions(() => {
              this.updateField(this.result, this.options);
            });
          }
        }
      });
    },
    result: {
      immediate: true,
      handler(val) {
        // if (!val) return
        const { chainIndex, multiple } = this.schema;
        // console.log('chainIndex', chainIndex, this.value, this.options)
        if (multiple) {
          this.tempdata[chainIndex] = this.options.filter(
            (option) => val && val.includes(option.value)
          );
        } else {
          this.tempdata[chainIndex] = this.options.filter(
            (option) => option.value == val
          )[0];
        }
        // console.log('watch', this.schema.dataIndex, buildString(val))
        this.$emit("input", buildString(val));
        this.$emit("emitListener");
      },
    },
  },
  beforeMount() {
    setTimeout(() => {
      let options = this.options;
      this.resetOptions((options) => {
        this.updateField(this.result, this.options);
      });
    }, 500);
  },
  methods: {
    /**
     *
     * 手动触发更新,会向上调用emitListener方法,触发schema中的listener,进而实现联动
     */
    updateField(value, options) {
      if (Util.notNull(value)) {
        // options = options ? options : this.options
        // let options = Array.from(this.options)
        // console.log(value, 'updateField', options)
        const { chainIndex, multiple } = this.schema;
        // console.log(value, chainIndex, 'select', this.options)
        // this.tempdata[chainIndex] = this.options.filter(
        //   (option) => option.value == value
        // )[0]
        // this.$emit('emitListener')
        if (multiple) {
          this.tempdata[chainIndex] =
            "" ||
            options.filter((option) => value && value.includes(option.value));
        } else {
          this.tempdata[chainIndex] =
            "" || options.filter((op) => op.value == value)[0];
          // console.log(this.tempdata[chainIndex], 'this.tempdata[chainIndex]')
          // console.log(
          //   Array.from(options).filter((op) => op.value == 2177),
          //   'this.options'
          // )
        }
        this.$emit("emitListener");
      }
    },
    async resetOptions(fetched) {
      this.options = [];
      const { request, valueEnum, fieldProps, multiple, chainIndex } =
        this.schema;
      if (request) {
        let options =
          (await request({
            chaindata: this.chaindata,
            tempdata: this.tempdata,
          })) || [];
        this.options = options;
        this.$forceUpdate();
        // console.log('获取options完毕，开始执行回调', chainIndex, valueEnum)
        fetched?.(this.options);
      } else if (valueEnum) {
        this.options = Object.entries(valueEnum).map(([value, { text }]) => ({
          value,
          label: text,
        }));
      }
      if (fieldProps.useFirstOption && Util.isNull(this.result)) {
        this.result = this.options[0]?.value;
      }
      if (!multiple) {
        let r = this.options.some((i) => i.value == this.result);
        if (!r) {
          this.result = undefined;
        }
      }
    },
  },
};
</script>

<style></style>
