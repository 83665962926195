<template>
  <div class="container recommond">
    <div class="title-t">推荐货品</div>
    <div class="main">
      <goods-list :goodsList="goodsList" @collect="collectClick" />
      <!-- <div class="goods-item fl al-c" v-for="item in goodsList" :key="item.id">
        <div class="goods fl">
          <img class="goods-img" :src="item.img" alt="" />
          <div class="goods-desc">
            <div class="goods-name text-el">{{ item.name }}</div>
            <div class="goods-plant text-el">仓库：{{ item.plantName }}</div>
            <div class="goods-tip">{{ item.type }}</div>
          </div>
        </div>
        <div class="goods2">
          <div class="goods-name text-el">{{ item.unit }}</div>
          <div class="goods-plant text-el">存放地：{{ item.city }}</div>
          <div class="goods2-add text-el">地址：{{ item.address }}</div>
        </div>
        <div class="goods3">
          <div class="money-f">&yen;{{ item.price }}</div>
          <div class="goods3-2 fl al-c">
            <div class="goods3-icon">
              <svg-icon icon-class="call" class-name="icon"></svg-icon>
            </div>
            <div class="goods3-icon">
              <svg-icon icon-class="qq" class-name="icon"></svg-icon>
            </div>
          </div>
        </div>
        <div class="goods4">
          <div>
            <el-button type="primary" plain>查看详情</el-button>
          </div>
          <div class="goods4-2 fl al-c">
            <div class="goods4-icon">
              <svg-icon icon-class="gouwuche" class="icon"></svg-icon>
            </div>
            <div class="goods4-icon">
              <svg-icon icon-class="shoucang" class="icon"></svg-icon>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <el-dialog
      :visible.sync="showLogin"
      width="683px"
      title="登录/注册"
      top="17%"
    >
      <div class="login-box">
        <login-section @loginSuccess="loginSuccess"></login-section>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import goodsList from "@/components/goodsList/index.vue";
import { goodsPublishAxios } from "@/axios/lib";
import { mapGetters } from "vuex";
import { LoginSection } from "@/ui";

export default {
  components: {
    goodsList,
    LoginSection,
  },
  data() {
    return {
      goodsList: [],
      current: 1,
      size: 3,
      showLogin: false,
    };
  },
  created() {
    this.getList();
  },
  computed: {
    ...mapGetters(["account"]),
  },
  methods: {
    loginSuccess() {
      this.showLogin = false;
    },
    async collectClick(item) {
      if (this.account.accountId) {
        let { info } = await goodsPublishAxios.collect(item.id);
        this.getList();
        this.$message({
          message: info,
          type: "success",
        });
        this.getList();
      } else {
        this.showLogin = true;
      }
    },
    async getList() {
      let { data } = await goodsPublishAxios.publicList({
        current: this.current,
        size: this.size,
        // collectionAccountId: this.account.accountId,
      });
      if (data.records) {
        this.goodsList = data.records;
      }
    },
  },
};
</script>

<style scoped>
.recommond {
  background: #fff;
  max-height: 454px;
}
.title-t {
  padding: 20px 30px 0;
}
.main {
  margin-top: 3px;
}
.goods-item {
  padding: 20px 30px;
  border-bottom: 1px solid #f0f0f0;
  height: 132px;
  justify-content: space-between;
}
.goods-item:last-child {
  border: 0;
}
.goods {
  width: 30%;
  height: 100%;
}
.goods2 {
  width: 19%;
  height: 100%;
}
.goods3,
.goods4 {
  width: 12%;
  height: 100%;
  text-align: center;
}
.goods-img {
  width: 92px;
  height: 92px;
  display: block;
  border-radius: 2px;
}
.goods-desc {
  margin-left: 14px;
  width: calc(100% - 107px);
}
.goods-name {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}
.goods-plant {
  font-size: 14px;
  color: #666;
  line-height: 20px;
  margin: 5px 0 11px;
}
.goods-tip {
  display: inline-block;
  padding: 1px 6px;
  font-size: 12px;
  line-height: 19px;
  color: #ef984a;
  background: #fcf0e3;
  border-radius: 1px;
}

.goods2-add {
  font-size: 14px;
  color: #666;
  line-height: 20px;
}
.money-f {
  font-size: 26px;
  color: #bb271a;
  line-height: 31px;
  white-space: nowrap;
}
.goods3-2 {
  margin-top: 15px;
  justify-content: center;
}
.goods3-icon {
  cursor: pointer;
  transition: all 0.3s;
}
.goods3-icon:first-child {
  margin-right: 10px;
}
.goods3-icon:hover {
  transform: scale(1.1);
}
.goods3-icon .icon {
  width: 34px;
  height: 34px;
}

.goods4 .el-button--primary.is-plain {
  background: transparent;
  font-size: 14px;
  color: #3c9cff;
}
.goods4 .el-button--primary.is-plain:hover {
  color: #3c9cff;
}
.goods4-icon {
  transition: all 0.3s;
  cursor: pointer;
}
.goods4-icon:first-child {
  margin-right: 20px;
}
.goods4-icon .icon {
  width: 22px;
  height: 22px;
}
.goods4-icon:hover {
  transform: scale(1.1);
}
.goods4-2 {
  margin-top: 11px;
  justify-content: center;
}
.login-box {
  width: 60%;
  margin: auto;
}
</style>
