<template>
  <div>
    <el-button type="primary" @click="openDialog">去认证</el-button>

    <el-dialog
      :visible.sync="showDialog"
      width="1200px"
      title="认证"
      lock-scrolls
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div class="dialog-form-container">
        <div class="nav fl al-c">
          <div
            v-if="account.accountTypeKey == 1"
            :class="['nav-item active', isCompany ? 'active' : '']"
            @click="change(true)"
          >
            企业认证
          </div>
          <div
            v-else
            :class="['nav-item active', isCompany ? '' : 'active']"
            @click="change(false)"
          >
            个人认证
          </div>
        </div>
        <ui-form
          v-if="account.accountTypeKey == 1"
          ref="form"
          :ndiv="2"
          :labelWidth="160"
          :columns="companyColumns"
        ></ui-form>
        <div v-else>
          <div ref="qrcodeArea" class="qrcode"></div>
          <p class="text-al">请使用微信扫描上方二维码</p>
        </div>
      </div>
      <div slot="footer" class="footer">
        <el-button @click="showDialog = false">取消</el-button>
        <el-button @click="dialogConfirm" type="primary">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { UiForm } from "@/ui";
import { companyColumns, service } from "./columns";
import { mapGetters } from "vuex";
import QRCode from "qrcode";
export default {
  components: {
    UiForm,
  },
  data() {
    return {
      companyColumns,
      showDialog: false,
      isCompany: true,

      qrcodeOpts: {
        errorCorrectionLevel: "H",
        type: "image/jpeg",
        width: 260,
        quality: 0.3,
        margin: 1,
        color: {
          dark: "#000000",
          light: "#ffffff",
        },
      },
    };
  },
  computed: {
    ...mapGetters(["account"]),
  },
  methods: {
    openDialog() {
      this.showDialog = true;
      if (this.account.accountTypeKey == 2) {
        this.change(false);
      }
    },
    change(isCompany) {
      this.isCompany = isCompany;
      if (!isCompany) {
        this.$nextTick(() => {
          const url = "https://yc.tswlsz.com/weixin/auth";
          const content = `${url}?accountId=${this.account.accountId}`;
          QRCode.toCanvas(content, this.qrcodeOpts, (error, canvas) => {
            if (error) console.error(error);
            this.$refs.qrcodeArea.innerHTML = "";
            this.$refs.qrcodeArea.appendChild(canvas);
          });
        });
      }
    },
    async dialogConfirm() {
      if (!this.isCompany) {
        this.showDialog = false;
        return;
      }
      let params = await this.$refs["form"].validate();
      console.log(params);
      await service.companyAccount({
        ...params,
        accountId: this.account.accountId,
      });
      this.$message({
        type: "success",
        message: "认证成功",
      });
      this.showDialog = false;
      this.$nextTick(() => {
        window.location.reload();
      });
    },
  },
};
</script>

<style scoped>
::v-deep .el-dialog {
  border-radius: 12px;
}
::v-deep .el-dialog__header {
  text-align: center;
  padding: 24px 0 46px;
}
::v-deep .el-dialog__body {
  padding-top: 0;
}
::v-deep .el-dialog__title {
  font-size: 20px;
  font-weight: 600;
}
.dialog-form-container {
  padding: 0 100px;
}
.footer {
  text-align: center;
}
.nav {
  padding: 20px 0;
}
.nav-item {
  font-size: 18px;
  padding: 4px 0;
  margin-right: 20px;
  position: relative;
  cursor: pointer;
  color: #000;
}
.active {
  font-weight: bold;
  font-size: 20px;
}
.active::after {
  content: "";
  width: 80%;
  height: 4px;
  border-radius: 1px;
  background: #3c9cff;
  position: absolute;
  bottom: 0;
  left: 0;
}
.qrcode {
  width: 100%;
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-al {
  text-align: center;
}
</style>
