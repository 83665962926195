import axios from '../index'

export default {
  noticeList(data:any){
    return axios({
      url:'/platformCounseling/public/list',
      method:'GET',
      data
    })
  },
  noticeDetail(id:any){
    return axios({
      url:'/platformCounseling/public/detail',
      method:'GET',
      data:{
        id
      }
    })
  },
  noticeColect(messageId:any){
    return axios({
      url:'/platformCounselingCollection/collection',
      method:'GET',
      data:{
        messageId
      }
    })
  },
  noticePageView(id:any){
    return axios({
      url:'/platformCounseling/public/pageView',
      method:'GET',
      data:{
        id
      }
    })
  },
  noticeIsCollect(messageId:any){
    return axios({
      url:'/platformCounselingCollection/isCollection',
      method:'GET',
      data:{
        messageId
      }
    })
  },
  noticeRemove(id:any){
    return axios({
      url:'/platformCounseling/remove?id='+id,
      method:'POST',
    })
  },
}