type ExternalType = {
  /**
   * 包含任意权限
   */
  hasAnyPerm(perms?: string[]): boolean
  /**
   * 包含所有权限
   */
  hasAllPerm(perms?: string[]): boolean
}

/**
 * 与项目相关工具类[由外部项目实现]
 */
export default class External {
  /**
   * 实现
   */
  static realize(external: Omit<Partial<ExternalType>, 'realize'>) {
    for (const key in external) {
      if (key !== 'realize') {
        External[key as keyof External] = external[key as keyof External]
      }
    }
  }
  /**
   * 权限判断
   */
  static hasPerm(perm?: Perms | string[]): boolean {
    if (perm) {
      if (Array.isArray(perm)) {
        return External.hasAnyPerm(perm)
      } else {
        return (
          External.hasAllPerm(perm.hasAll) && External.hasAnyPerm(perm.hasAny)
        )
      }
    }
    return true
  }
  /**
   * 包含任意权限
   */
  static hasAnyPerm(perms?: string[]): boolean {
    return true
  }
  /**
   * 包含所有权限
   */
  static hasAllPerm(perms?: string[]): boolean {
    return true
  }
}
