const makeXdata = (length, name = '月') => {
  let arr = []
  for (let i = 1; i <= length; i++) {
    arr.push(i + name)
  }
  return arr
}
export const weekData = {
  data: [
    3061, 5067, 6378, 6634, 3688, 2067, 3681, 2116, 4100, 3997, 3648, 4080,
    6356, 6401, 5153, 6363, 3001, 2118, 1137, 1607, 3061
  ],
  xData: makeXdata(21, '周'),
}
export const monthData = {
  data: [
    30892, 8690.3, 19485, 38970, 18573
  ],
  xData: [...makeXdata(5)],
}
export const yearData = {
  data: [319347.8, 339167.5, 269433.8, 235716.5, 314962.3, 30450.5],
  xData: ['2018年', '2019年', '2020年', '2021年', '2022年', '2023年'],
}
export const options = {
  grid: {
    bottom: '10%',
    left: '15%',
    top: '15%',
    right: '5%',
  },
  legend: {
    show: false,
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
    },
    backgroundColor: '#fff',
    borderColor: '#fff',
    textStyle: {
      color: '#333',
    },
    formatter(params) {
      return `${params[0].axisValue}
              <br /> ${params[0].seriesName} : ${params[0].value}元/吨`
    },
  },
  dataZoom: {
    type: 'inside',
  },
  xAxis: [
    {
      type: 'category',
      boundaryGap: true,
      // offset: '',
      // axisTick: {
      //   show: false,
      // },
      splitLine: {
        show: false,
      },

      axisLine: {
        show: true,
        lineStyle: {
          color: '#1B3A51',
        },
      },
      data: monthData.xData,
      axisLabel: {
        color: '#333',
        fontSize: 12,
        formatter(value) {
          return `${value.slice(0, -1)}`
        },
      },
    },
  ],
  yAxis: [
    {
      type: 'value',
      splitLine: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#1B3A51',
        },
      },
      axisLabel: {
        color: '#333',
        fontSize: 12,
      },
    },
  ],
  series: [
    {
      name: '成交价',
      type: 'bar',
      data: monthData.data,
      barWidth: Math.floor(window.document.documentElement.clientWidth * 0.004),
      itemStyle: {
        borderRadius: [100, 100, 0, 0],
        color: {
          type: 'linear',
          x: 0.5,
          y: 0,
          x2: 0.5,
          y2: 1,
          colorStops: [
            {
              offset: 1,
              color: '#091A4200', // 100% 处的颜色
            },
            {
              offset: 0,
              color: '#25F6FF', // 0% 处的颜色
            },
          ],
          global: false, // 缺省为 false
        },
      },
    },
  ],
}

const userList = [
  '五天一',
  '郑娜',
  '锺磊',
  '许强',
  '赵刚',
  '李平',
  '龚超',
  '毛秀兰',
]

const data = [85, 65, 78, 55, 78, 35, 22, 67]
const total = [13, 19, 21, 31, 16, 23, 30, 42, 41, 26]

export const lineOptions = {
  grid: {
    bottom: '10%',
    left: '15%',
    top:'10%'
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
    },
    backgroundColor: 'rgba(57, 66, 103, 1)',
    borderColor: 'rgba(57, 66, 103, 1)',
    textStyle: {
      color: '#fff',
    },
    formatter(params){
      console.log(params)
      return `${params[0].seriesName}: ${params[0].value}元/吨`
    }
  },
  legend: {
    show: false,
    right: '10%',
    // data: [
    //   {
    //     name: '平均时长',
    //     textStyle: {
    //       color: '#fff',
    //     },
    //     lineStyle: 'inherit',
    //     itemStyle: 'inherit',
    //   },
    //   {
    //     name: '任务数量',
    //     textStyle: {
    //       color: '#fff',
    //     },
    //     lineStyle: 'inherit',
    //     itemStyle: 'inherit',
    //   },
    // ],
  },
  dataZoom: {
    type: 'inside',
  },
  xAxis: [
    {
      type: 'category',
      boundaryGap: false,
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      // prettier-ignore
      data: userList,
      axisLabel: {
        color: '#fff',
        fontSize: 15,
      },
    },
  ],
  yAxis: [
    {
      type: 'value',
      // name: '平均时长/小时',
      splitLine: {
        show: false,
      },
      // nameTextStyle: {
      //   color: '#333',
      //   fontSize: 15,
      // },
      // position: 'left',
      // alignTicks: true,
      // axisLine: {
      //   show: false,
      // },
      // axisLabel: {
      //   color: '#333',
      //   fontSize: 15,
      //   // formatter: '{value} 小时',
      // },
    },
  ],
  series: [
    {
      name: '平均时长',
      type: 'line',
      data: data,
      symbol: 'none',
      lineStyle: {
        width: 1,
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: 'rgba(79, 130, 255, .6)', // 0% 处的颜色
            },
            {
              offset: 1,
              color: 'rgba(36,36,54,.3)', // 100% 处的颜色
            },
          ],
          global: false, // 缺省为 false
        },
      },
      color: 'rgba(79, 130, 255, 1)',
    },
    
  ],
}
