<template>
  <el-upload
    class="ui-file-field"
    action=""
    :list-type="listType"
    :file-list="fileList"
    :multiple="schema.limit > 0"
    :limit="schema.limit"
    :http-request="uploadFile"
    :before-remove="removeFile"
    :on-change="onChange"
  >
    <svg-icon
      v-if="schema.fieldProps.icon"
      :icon-class="schema.fieldProps.icon"
      class="upload-icon"
    ></svg-icon>
    <el-button
      v-else
      size="small"
      icon="el-icon-upload"
      type="primary"
      class="upload-button"
      >点击上传</el-button
    >
    <span v-if="isLoading" class="loading"
      ><i class="el-icon-loading"></i
    ></span>
    <template slot="file" slot-scope="{ file }">
      <div class="file-preview">
        <img
          v-if="/\.(png|jpg|jpeg|gif)$/.test(file.name)"
          :src="file.url"
          alt=""
        />
        <!-- <img
          v-else-if="/\.(docx)$/.test(file.name)"
          src="@/assets/images/icon/docx.png"
          alt=""
        />
        <img
          v-else-if="/\.(xlsx)$/.test(file.name)"
          src="@/assets/images/icon/xlsx.png"
          alt=""
        /> -->
      </div>
      <div class="file-name" @click="preview(file)">
        {{ file.name }}
      </div>
      <div class="file-action" @click="removeFile(file)">
        <div class="state" v-if="file.status === 'success'">
          <i class="el-icon-upload-success el-icon-check"></i>
        </div>
        <div class="remove">
          <i class="el-icon-close"></i>
        </div>
      </div>
    </template>
    <template slot="tip" v-if="initialValues && schema.fieldProps.showTips">
      <div class="tip">{{ initialValues[schema.fieldProps.initialUrl] }}</div>
    </template>
  </el-upload>
</template>

<script>
import { fileAxios } from "@/axios/lib";
import { buildFileList, buildFieldValue } from "../lib/file";
export default {
  name: "file-field",
  props: {
    value: {},
    readonly: Boolean,
    schema: Object,
    initialValues: Object,
    tempdata: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const {
      fieldProps: { initialUrl },
    } = this.schema;
    const initialUrls = String(this.initialValues?.[initialUrl] || "").split(
      ","
    );
    const initialList = this.value
      ? String(this.value)
          .split(",")
          .map((item, index) => ({
            id: item,
            originalName: initialUrls[index],
            url: initialUrls[index],
          }))
      : [];
    return {
      listType: "picture",
      fileList: buildFileList(initialList),
      isLoading: false,
    };
  },
  methods: {
    async uploadFile({ file }) {
      this.isLoading = true;
      // console.log(file);
      return await fileAxios.simpleUpload(file);
    },
    onChange(_, fileList) {
      this.isLoading = false;
      this.fileList = fileList;
      let {
        fieldProps: { initialUrl },
      } = this.schema;
      this.tempdata[initialUrl] = fileList[0]?.response;
      this.$emit("input", buildFieldValue(fileList));
    },
    async removeFile(file) {
      const { name, uid, response } = file;
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].name === name && this.fileList[i].uid === uid) {
          await fileAxios.removeFile(response?.id);
          this.fileList.splice(i, 1);
          this.$emit("input", "");
          break;
        }
      }
    },
    preview(file) {
      const { status, response } = file;
      if (status === "success") {
        if (/^.*\.(doc|docx|xlsx)$/.test(response?.url)) {
          window.open(
            "https://view.officeapps.live.com/op/view.aspx?src=" + response?.url
          );
        } else {
          window.open(response?.url);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-file-field {
  width: 100%;

  .upload-button {
    margin: 4px 0;
  }

  .el-upload-list--picture {
    .file-preview {
      width: 70px;
      height: 70px;
      margin-left: -80px;
      float: left;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .file-name {
      width: 80%;
      float: left;
      line-height: 70px;
      color: #606266;
      margin-right: 40px;
      overflow: hidden;
      padding-left: 4px;
      text-overflow: ellipsis;
      transition: color 0.3s;
      white-space: nowrap;
      cursor: pointer;
    }

    .file-action {
      position: absolute;
      right: -17px;
      top: -7px;
      width: 46px;
      height: 26px;
      overflow: hidden;
      transform: rotate(45deg);
      box-shadow: 0 1px 1px #ccc;

      & > div {
        width: 100%;
        height: 100%;
        text-align: center;
        cursor: pointer;

        i {
          position: static;
          opacity: 1;
          transform: rotate(-45deg);
          margin-top: 10px;
          color: #ffffff;
        }
      }

      .state {
        background: #13ce66;
        display: block;
      }

      .remove {
        background: #ff0000;
        display: none;
      }

      &:hover .state {
        display: none;
      }

      &:hover .remove {
        display: block;
      }
    }
  }
}
::v-deep .el-upload-list {
  width: 80%;
}
::v-deep .el-upload {
  position: relative;
}
.loading {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 10px;
}
.upload-icon {
  width: 20px;
  height: 20px;
}
</style>
