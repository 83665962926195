export default {
  disable: true,
  order: 15,
  label: "视频播放",
  head: "fa fa-",
  list: [
    "arrows-alt",
    "backward",
    "compress",
    "eject",
    "expand",
    "fast-backward",
    "fast-forward",
    "forward",
    "pause",
    "pause-circle",
    "pause-circle-o",
    "play",
    "play-circle",
    "play-circle-o",
    "random",
    "step-backward",
    "step-forward",
    "stop",
    "stop-circle",
    "stop-circle-o",
    "youtube-play",
  ]
}