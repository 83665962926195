import db from '@/utils/localstorage'
import router from '@/router'
import { oauthAxios } from '@/axios/lib'

const initOauthState = () => {
  return {
    token: db.get('token', ''),
    /**
     * 企业id <=> 租户id
     */
    enterpriseId: db.get('enterpriseId', ''),
    /**
     * 租户信息
     */
    // tenant: db.get('tenant', {}),
    /**
     * 当前登录账号信息
     */
    account: db.get('account', {}),
    /**
     * 当前登录账号的个人信息
     */
    // user: db.get('user', {}),
    /**
     * 当前用户可操作的权限
     */
    permissions: db.get('permissions', []),
    /**
     * 当前用户可查看的菜单路由
     */
    routes: db.get('routes', []),
    /**
     * 当前用户拥有角色
     */
    roleList: db.get('roleList', []),
  }
}

export default {
  namespaced: true,
  state: initOauthState(),
  mutations: {
    setToken(state, val) {
      db.set('token', val)
      state.token = val
    },
    setRoleList(state, val) {
      db.set('roleList', val)
      state.roleList = val
    },
    setEnterpriseId(state, val) {
      db.set('enterpriseId', val)
      state.enterpriseId = val
    },
    // setTenant(state, val) {
    //   db.set('tenant', val)
    //   state.tenant = val
    // },
    setAccount(state, val) {
      db.set('account', val)
      state.account = val
    },
    // setUser(state, val) {
    //   db.set('user', val)
    //   state.user = val
    // },
    setPermissions(state, val) {
      db.set('permissions', val)
      state.permissions = val
    },
    setRoutes(state, val) {
      db.set('routes', val)
      state.routes = val
    },
  },
  actions: {
    async logout({ state }) {
      await oauthAxios.logout()
      state.account = {}
      localStorage.clear()
      state = initOauthState()
      router.push('/')
      // location.reload()
    },
  },
}
