<template>
  <el-form
    ref="form"
    :key="formKey"
    :show-message="inspect"
    :class="['ui-form', inspect ? '' : 'no-inspect']"
    :model="chaindata"
    :rules="!readonly && inspect ? rules : null"
    :validate-on-rule-change="false"
    :hide-required-asterisk="this.readonly"
  >
    <template v-for="s in schemas">
      <el-form-item
        v-if="!s.hideInForm"
        :key="s.chainIndex"
        :prop="s.chainIndex"
        :label="s.title"
        :label-width="labelWidth + 'px'"
        :style="fieldStyle(s)"
      >
        <!-- 插槽类型 -->
        <template v-if="s.valueType === 'slot'">
          <slot
            :name="s.chainIndex"
            :schema="s"
            :tempdata="tempdata"
            :chaindata="chaindata"
            :readonly="readonly"
            :initialValues="initialValues"
            :initialValue="
              Util.chainValue(
                initialValues,
                s.chainIndex,
                typeof s.initialValue === 'function'
                  ? s.initialValue(initialValues)
                  : s.initialValue
              )
            "
            :setValue="(value) => changeField(s, value)"
          ></slot>
        </template>
        <!-- 输入区域 -->
        <template v-else>
          <label-field
            :ref="s.chainIndex"
            :schema="s"
            :tempdata="tempdata"
            :chaindata="chaindata"
            :readonly="readonly"
            :initialValues="initialValues"
            :initialValue="
              Util.chainValue(
                initialValues,
                s.chainIndex,
                typeof s.initialValue === 'function'
                  ? s.initialValue(initialValues)
                  : s.initialValue
              )
            "
            @change="(value) => changeField(s, value)"
            @emitListener="emitListener"
          />
        </template>
      </el-form-item>
      <!-- 静态类型: 为了使hideInForm的值参与emitListener -->
      <static-field
        v-else
        :key="s.dataIndex"
        :value="
          Util.chainValue(
            initialValues,
            s.chainIndex,
            typeof s.initialValue === 'function'
              ? s.initialValue(initialValues)
              : s.initialValue
          )
        "
        @change="(value) => changeField(s, value)"
      />
      <!-- 分割线,用于表单区域划分,优化显示效果 -->
      <div
        v-if="s.formFieldLayout && s.formFieldLayout.divider"
        :key="'divider' + s.chainIndex"
        class="ui-form__divider"
      ></div>
    </template>
  </el-form>
</template>

<script>
import LabelField from "./label-field";
import StaticField from "./static.vue";
import Util from "../../lib/util";
import formMinxin from "../../mixins/form";
export default {
  name: "ui-form",
  components: { LabelField, StaticField },
  mixins: [formMinxin],
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    labelWidth: {
      type: Number,
      default: 112,
    },
    inspect: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      Util,
    };
  },
  computed: {
    fieldStyle() {
      return (schema) => {
        const {
          formFieldLayout: { colspan = 1, leftBlank = 0, rightBlank = 0 } = {},
          formFieldStyle,
        } = schema;

        return {
          width: (100 / this.column) * colspan + "%",
          marginLeft: leftBlank * (100 / this.column) + "%",
          marginRight: rightBlank * (100 / this.column) + "%",
          ...formFieldStyle,
        };
      };
    },
  },
  methods: {
    /**
     * 由field发起
     *
     * @param {IntactSchema} schema
     * @param {any} value
     */
    changeField(schema, value) {
      // console.log('changeFiled', value)
      const { chainIndex } = schema;

      if (Util.notNull(value, this.chaindata[chainIndex])) {
        if (value !== this.chaindata[chainIndex]) {
          this.chaindata = {
            ...this.chaindata,
            [chainIndex]: value,
          };
          // console.log('changeField', chainIndex, value)
          this.$nextTick(() => this.validateField(chainIndex));
        }
      }
    },
    /**
     * 校验 & 取值
     */
    async validate() {
      const formdata = {};
      for (let i = 0; i < this.schemas.length; i++) {
        const schema = this.schemas[i];
        const { dataIndex, chainIndex, validate, valueType, readonly } = schema;
        if (valueType !== "blank") {
          formdata[dataIndex] = this.chaindata[chainIndex];
          /**
           * schema内校验
           */
          !this.readonly &&
            !readonly &&
            (await validate?.call(schema, {
              value: this.chaindata[chainIndex],
              setValue: (v) => (formdata[dataIndex] = v),
              setValues: (vs) => {
                if (vs) {
                  for (let key in vs) {
                    formdata[key] = vs[key];
                  }
                }
              },
              chaindata: this.chaindata,
              tempdata: this.tempdata,
              initialValues: this.initialValues,
            }));
        }
      }
      !this.readonly && (await this.$refs.form?.validate());
      return formdata;
    },
    /**
     * 单体校验
     *
     * @param {keyod T} key
     */
    validateField(key) {
      const form = this.$refs.form;
      form["validateField"](key);
    },
    /**
     * 清除校验
     */
    clearValidate() {
      const form = this.$refs.form;
      form["clearValidate"]();
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  &.no-inspect {
    .el-form-item {
      margin-bottom: 0 !important;
    }
  }

  .el-form-item {
    flex-shrink: 0;
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 0 12px;

    ::v-deep .el-form-item__label {
      line-height: 40px;
      flex-shrink: 0;
      // text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    ::v-deep .el-form-item__content {
      flex-grow: 1;
      min-height: 40px;
      display: flex;
      align-items: center;
      margin: 0 !important;
      line-height: normal;

      .form-item-box {
        width: 100%;
      }

      .el-input,
      .el-autocomplete,
      .el-date-editor,
      .el-select,
      .el-button,
      .el-cascader,
      .readonly {
        width: 100%;
        // text-align: center;

        input {
          // text-align: center;
        }
      }

      .readonly {
        height: 32px;
        border-radius: 4px;
        background-color: #f9f9f9;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .el-date-editor {
        .el-input__validateIcon {
          height: 16px;
          line-height: 16px;
        }
      }

      .el-switch {
        flex-grow: 1;
        display: flex;
        // justify-content: center;
      }
    }
  }

  .ui-form__divider {
    width: 100%;
    height: 6px;
  }
}
</style>
