<template>
  <div class="container fl al-c">
    <div class="nav">
      <div class="nav-item fl" v-for="(item, index) in navList" :key="index">
        <div class="nav-icon">
          <img :src="item.icon" class="icon" alt="" />
        </div>
        <div class="nav-content fl fl-d-c ju-c">
          <div class="nav-content-tp">{{ item.title }}</div>
          <div class="nav-content-bt text-el">
            <span
              v-for="(text, textIndex) in getText(item, 0)"
              :key="textIndex"
              >{{ text }}</span
            >
          </div>
        </div>
        <div class="nav-icon-right">
          <i class="el-icon-arrow-right"></i>
        </div>
        <div class="nav-item-block" :style="{ top: -(index * 67) + 'px' }">
          <div class="block-title">品名</div>
          <div class="block-content">
            <span v-for="(text, textIndex) in getText(item)" :key="textIndex">{{
              text
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper">
      <mid-swiper />
    </div>
    <div class="me">
      <div class="login-box">
        <login-section />
      </div>
      <!-- <div class="avatar">
        <img src="../../assets/images/icons/avatar.png" alt="" />
      </div>
      <div>Hi，你好！</div>
      <div class="account-name">
        <el-select v-model="account" placeholder="账号名称" size="medium">
          <el-option :value="'账号1'">账号1</el-option>
          <el-option :value="'账号2'">账号2</el-option>
        </el-select>
      </div>
      <el-button type="primary" class="button-login">进入工作台</el-button> -->
      <div class="me-bt fl al-c">
        <div>
          <div class="me-bt-img">
            <img src="../../assets/images/gzh.jpg" class="img" alt="" />
          </div>
          <div>公众号</div>
        </div>
        <div>
          <div class="me-bt-img">
            <img src="../../assets/images/app2.png" class="img" alt="" />
          </div>
          <div>资源振金APP</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LoginSection } from "@/ui";
import midSwiper from "../midSwiper/index.vue";

export default {
  components: {
    midSwiper,
    LoginSection,
  },
  data() {
    return {
      navList: [
        {
          title: "热卷频道",
          desc: "普卷,低合金卷",
          content: {
            name: "普卷,低合金卷,花纹卷,C料,带钢,船卷,优碳卷,汽车大梁卷",
          },
          icon: require("@/assets/images/icons/rejuan.png"),
        },
        {
          title: "冷镀频道",
          desc: "冷轧卷,冷轧合板",
          content: {
            name: "冷轧卷,冷轧盒板,轧硬卷,镀锌卷,镀铝锌卷,酸洗卷,有花镀锌卷,镀锌板,冷成型",
          },
          icon: require("@/assets/images/icons/lengdu.png"),
        },
        {
          title: "中厚板频道",
          desc: "冷轧卷,冷轧合板",
          content: {
            name: "中厚板频道,冷轧卷,冷轧盒板,轧硬卷,镀锌卷,镀铝锌卷,酸洗卷,有花镀锌卷,镀锌板,冷成型",
          },
          icon: require("@/assets/images/icons/zhonghouban.png"),
        },
        {
          title: "建材频道",
          desc: "冷轧卷,冷轧合板",
          content: {
            name: "建材频道,冷轧卷,冷轧盒板,轧硬卷,镀锌卷,镀铝锌卷,酸洗卷,有花镀锌卷,镀锌板,冷成型",
          },
          icon: require("@/assets/images/icons/jiancai.png"),
        },
        {
          title: "型管频道",
          desc: "H型钢,T型钢,扁钢",
          content: {
            name: "型管频道,冷轧卷,冷轧盒板,轧硬卷,镀锌卷,镀铝锌卷,酸洗卷,有花镀锌卷,镀锌板,冷成型",
          },
          icon: require("@/assets/images/icons/xingguan.png"),
        },
        {
          title: "不锈钢频道",
          desc: "冷轧版,热轧板,型材",
          content: {
            name: "不锈钢频道,冷轧卷,冷轧盒板,轧硬卷,镀锌卷,镀铝锌卷,酸洗卷,有花镀锌卷,镀锌板,冷成型",
          },
          icon: require("@/assets/images/icons/lengdu.png"),
        },
        {
          title: "圆钢频道",
          desc: "弹簧钢,建筑圆钢",
          content: {
            name: "圆钢频道,冷轧卷,冷轧盒板,轧硬卷,镀锌卷,镀铝锌卷,酸洗卷,有花镀锌卷,镀锌板,冷成型",
          },
          icon: require("@/assets/images/icons/rejuan.png"),
        },
        {
          title: "开平板频道",
          desc: "普碳开平板",
          content: {
            name: "开平板频道,冷轧卷,冷轧盒板,轧硬卷,镀锌卷,镀铝锌卷,酸洗卷,有花镀锌卷,镀锌板,冷成型",
          },
          icon: require("@/assets/images/icons/zhonghouban.png"),
        },
      ],
      account: "",
    };
  },
  methods: {
    getText(item, type = 1) {
      if (type == 1) {
        return item.content.name.split(",");
      }
      return item.desc.split(",");
    },
  },
};
</script>

<style scoped>
.container {
  height: 536px;
  justify-content: space-between;
}
.nav-item {
  /* width: 234px; */
  width: 100%;
  height: 67px;
  padding: 10px 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #f0f0f0;
  font-size: 14px;
  color: #999999;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  transition: all 0.3s;
  background: #fff;
}
.nav-item:hover::after {
  content: "";
  width: 4px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #3c9cff;
}
.nav-item:hover {
  margin-left: 4px;
  box-shadow: 0 4px 3px 3px rgba(51, 51, 51, 0.05);
}
.nav-item:hover .nav-item-block {
  display: block;
}
.nav-icon {
  width: 18px;
  height: 18px;
}
.nav-icon .icon {
  display: block;
  width: 100%;
  height: auto;
}
.nav {
  background: #fff;
  height: 100%;
  width: 19%;
}
.nav-content {
  width: 66%;
  flex-shrink: 0;
  margin: 0 16px;
}
.nav-content-tp {
  line-height: 22px;
  color: #333;
  font-size: 16px;
}
.nav-content-bt {
  /* line-height: 19.6px; */
  margin-top: 5px;
}
.nav-content-bt span {
  margin-right: 10px;
}
.nav-content-bt span:last-child {
  margin: 0;
}
.nav-icon-right {
  padding-top: 10px;
}

.nav-item-block {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
  width: 321%;
  height: 536px;
  background: #fff;
  z-index: 2;
  padding: 10px 30px;
  box-sizing: border-box;
  font-size: 14px;
  color: #666;
}
.block-title {
  font-weight: bold;
  font-size: 18px;
  color: #333;
  line-height: 25px;
  margin-bottom: 8px;
}
.nav-item-block span {
  line-height: 24px;
  margin: 8px 20px 0 0;
}
.swiper {
  width: 60%;
  height: 100%;
  background: #ccc;
  overflow: hidden;
}
.me {
  width: 19%;
  height: 100%;
  background: #fff;

  justify-content: space-between;
  font-size: 18px;
  color: #333;
  box-sizing: border-box;
}
.login-box {
  height: 71%;
}
.avatar {
  width: 86px;
  height: 86px;
  border-radius: 50%;
  overflow: hidden;
}
.avatar img {
  width: 100%;
  height: auto;
  display: block;
}
.account-name {
  width: 105px;
}
.button-login {
  margin-bottom: 62px;
}
.me-bt {
  width: 100%;
  text-align: center;
  font-size: 12px;
  justify-content: space-between;
  line-height: 16px;
  padding: 0 25px;
}
.me-bt-img {
  width: 82px;
  height: 82px;
  background: #f4f4f4;
  margin-bottom: 14px;
}
.me-bt-img .img {
  width: 100%;
  height: 100%;
}
</style>
