export default {
  order: 11,
  label: "图表",
  head: "fa fa-",
  list: [
    "area-chart",
    "bar-chart",
    "bar-chart-o",
    "line-chart",
    "pie-chart",
  ]
}