import { MessageBox, Message } from 'element-ui'
import { ControlTypeEnum } from '@/typings/enum'
import button from '../button'
//@ts-ignore
import noticeHtml from '@/utils/noticeHtml'

/**
 * 编辑
 */
export const update: ControlAction = (build) => {
  return {
    text: '编辑',
    icon: 'el-icon-edit',
    type: ControlTypeEnum.WARNING,
    ...build,
    modal: {
      title: '编辑',
      width: 800,
      footer: [
        button('cancel'),
        button<[Record<string, any>]>('sure', {
          async execute({ service, data: [formdata], rowdata, config }) {
            const { update: up } = service
            if (up) {
              const rowKey = config.get<string>('rowKey', 'id')
              await up(
                {
                  ...formdata,
                  [rowKey]: rowdata?.[rowKey],
                },
                rowdata!
              )
              return '编辑成功'
            }
          },
        }),
      ],
      ...build.modal,
    },
  }
}

/**
 * 添加
 */
export const insert: ControlAction = (build) => {
  return {
    text: '添加',
    icon: 'el-icon-plus',
    ...build,
    modal: {
      title: '添加',
      width: 800,
      keepalive: true,
      footer: [
        button('cancel'),
        button<[Record<string, any>]>('sure', {
          async execute({ service, data: [formdata] }) {
            const { insert: add } = service
            if (add) {
              await add({ ...formdata })
              return '添加成功'
            }
          },
        }),
      ],
      ...build.modal,
    },
  }
}

/**
 * 删除
 */
export const remove: ControlAction = (build) => {
  return {
    text: '删除',
    icon: 'el-icon-delete',
    type: ControlTypeEnum.DANGER,
    async prepare({ rowdata, service, updateTable, config }) {
      MessageBox.confirm(noticeHtml('即将永久删除该条纪录,是否继续'), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // type: 'warning',
        showClose: false,
        dangerouslyUseHTMLString: true,
        center: true,
      }).then(async () => {
        const { remove: rm } = service
        if (rm) {
          const rowKey = config.get<string>('rowKey', 'id')
          await rm(rowdata[rowKey], rowdata)
          updateTable()
          Message.success('删除成功')
        }
      })
    },
    ...build,
  }
}

/**
 * 详情
 */
export const detail: ControlAction = (build) => {
  return {
    text: '详情',
    icon: 'el-icon-view',
    async prepare({ rowdata, setRowdata, service, config }) {
      const { detail: dt } = service
      const rowKey = config.get<string>('rowKey', 'id')
      setRowdata({
        ...rowdata,
        ...(await dt?.(rowdata[rowKey], rowdata)),
      })
    },
    ...build,
    modal: {
      width: 810,
      footer: [
        button('cancel', {
          text: '关闭',
        }),
      ],
      ...build.modal,
    },
  }
}

/**
 * 导入
 */
export const loadin: ControlAction = (build) => {
  return {
    ...build,
  }
}

/**
 * 导出
 */
export const putout: ControlAction = (build) => {
  return {
    ...build,
  }
}
