export default {
  order: 12,
  label: "货币",
  head: "fa fa-",
  list: [
    "bitcoin",
    "btc",
    "cny",
    "dollar",
    "eur",
    "euro",
    "gbp", "gg",
    "gg-circle",
    "ils",
    "inr",
    "jpy",
    "krw",
    "money",
    "rmb",
    "rouble",
    "rub",
    "ruble",
    "rupee",
    "shekel",
    "sheqel",
    "try",
    "turkish-lira",
    "usd",
    "won",
    "yen",
  ]
}