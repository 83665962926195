<template>
  <div class="chart-box">
    <div class="unit">单位：{{ unit }}</div>
    <div class="echart" ref="echart"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { options } from "./options";
export default {
  props: {
    unit: {
      type: String,
      default: "吨",
    },
  },
  data() {
    return {
      echart: null,
      options,
    };
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      this.echart = echarts.init(this.$refs["echart"]);
      this.echart.setOption(this.options);
    },
  },
};
</script>

<style scoped>
.unit {
  text-align: right;
  color: #999;
  font-size: 12px;
  line-height: 17px;
  text-align: left;
}
.echart {
  width: 100%;
  height: 100px;
}
</style>
