import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import store from "./store";
import "normalize.css/normalize.css";
import "@/utils/flexiable";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "swiper/css/swiper.css";
import "@/style/index.css";

import "@/icons";

import Quill from 'quill'
import VueQuillEditor from 'vue-quill-editor'
import imageResize from 'quill-image-resize-module'
Quill.register('modules/imageResize', imageResize)
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import './ui/ui.extra'

import AmapVue from '@amap/amap-vue'
Vue.use(VueQuillEditor)

Vue.use(ElementUI);

AmapVue.config.version = '2.0'
AmapVue.config.key = '15427913b1ea2df9ee097ef5b6d90a68'
Vue.use(AmapVue);
// @ts-ignore
window['_AMapSecurityConfig'] = {
  securityJsCode: 'ec06c7e3fafc4e8b5e7c04726bf2394d',
}
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
