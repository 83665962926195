export default {
  disable: true,
  order: 14,
  label: "指示方向",
  head: "fa fa-",
  list: [
    "angle-double-down",
    "angle-double-left",
    "angle-double-right",
    "angle-double-up",
    "angle-down",
    "angle-left",
    "angle-right",
    "angle-up",
    "arrow-circle-down",
    "arrow-circle-left",
    "arrow-circle-o-down",
    "arrow-circle-o-left",
    "arrow-circle-o-right",
    "arrow-circle-o-up",
    "arrow-circle-right",
    "arrow-circle-up",
    "arrow-down",
    "arrow-left",
    "arrow-right",
    "arrow-up",
    "arrows",
    "arrows-alt",
    "arrows-h",
    "arrows-v",
    "caret-down",
    "caret-left",
    "caret-right",
    "caret-square-o-down",
    "caret-square-o-left",
    "caret-square-o-right",
    "caret-square-o-up",
    "caret-up",
    "chevron-circle-down",
    "chevron-circle-left",
    "chevron-circle-right",
    "chevron-circle-up",
    "chevron-down",
    "chevron-left",
    "chevron-right",
    "chevron-up",
    "exchange",
    "hand-o-down",
    "hand-o-left",
    "hand-o-right",
    "hand-o-up",
    "long-arrow-down",
    "long-arrow-left",
    "long-arrow-right",
    "long-arrow-up",
    "toggle-down",
    "toggle-left",
    "toggle-right",
    "toggle-up",
  ]
}