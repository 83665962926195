export enum ActionTypeEnum {
  /**
   * 编辑
   */
  UPDATE = 'update',
  /**
   * 添加
   */
  INSERT = 'insert',
  /**
   * 删除
   */
  REMOVE = 'remove',
  /**
   * 详情
   */
  DETAIL = 'detail',
  /**
   * 导入
   */
  LOADIN = 'loadin',
  /**
   * 导出
   */
  PUTOUT = 'putout',
}

export enum ControlTypeEnum {
  /**
   * 一般
   */
  PRIMARY = 'primary',
  /**
   * 成功
   */
  SUCCESS = 'success',
  /**
   * 警告
   */
  WARNING = 'warning',
  /**
   * 危险
   */
  DANGER = 'danger',
  /**
   * 不可用
   */
  DISABLE = 'disable',
}

export enum TenantTypeEnum {
  /**
   * 平台端
   */
  OPERATION = 'OPERATION',
  /**
   * 仓储端
   */
  WAREHOUSE = 'WAREHOUSE',
  /**
   * 贸易商
   */
  MERCHANT = 'MERCHANT',
  /**
   * 货代端
   */
  AGENCY = 'AGENCY',
  /**
   * 运力端
   */
  TRANSPORTATION = 'TRANSPORTATION',
}

export enum TenantTypeValueEnum {
  /**
   * 提供存储
   */
  WAREHOUSE = '仓储端',
  /**
   * 办单发起人
   */
  MERCHANT = '贸易商',
  /**
   * 办单发起人
   */
  AGENCY = '货代端',
  /**
   * 运输服务提供者
   */
  TRANSPORTATION = '运力端',
}

export enum TagTypeEnum {
  /**
   * 正常
   */
  PRIMARY = 'primary',
  /**
   * 成功
   */
  SUCCESS = 'success',
  /**
   * 信息
   */
  INFO = 'info',
  /**
   * 警告
   */
  WARNING = 'warning',
  /**
   * 异常
   */
  DANGER = 'danger',
}
