<template>
  <div class="checkbox">
    <el-checkbox-group v-model="result">
      <el-checkbox
        v-for="item in options"
        :key="item.value"
        :label="item.value"
        >{{ item.label }}</el-checkbox
      >
    </el-checkbox-group>
  </div>
</template>

<script>
import Util from '../../../../lib/util'
import { buildList, buildString } from '../lib/select'
export default {
  name: 'checkbox-field',
  props: {
    schema: Object,
    value: {},
    tempdata: {
      type: Object,
      default: () => ({}),
    },
    chaindata: Object,
    size: String,
  },
  data() {
    return {
      options: [],
      result: buildList(this.value),
    }
  },
  watch: {
    schema: {
      deep: true,
      immediate: true,
      handler(n) {
        // console.log('select-filed schema', n)
        n.valueEnum && this.resetOptions()
      },
    },
    /**
     * 当 dependencies 内的变量更新时重置当前field
     */
    async chaindata(newVal, oldVal) {
      const { dependencies: deps = [] } = this.schema
      ;(typeof deps === 'string' ? [deps] : deps).forEach((dep) => {
        if (newVal[dep] !== oldVal[dep]) {
          if (oldVal[dep]) {
            // this.result = undefined
            this.resetOptions()
          } else {
            this.resetOptions(() => {
              this.updateField(this.result)
            })
          }
        }
      })
    },
    result: {
      immediate: true,
      handler(val) {
        const { chainIndex, multiple } = this.schema
        if (multiple) {
          this.tempdata[chainIndex] = this.options.filter(
            (option) => val && val.includes(option.value)
          )
        } else {
          this.tempdata[chainIndex] = this.options.filter(
            (option) => option.value == val
          )[0]
        }
        this.$emit('input', buildString(val))
      },
    },
  },
  beforeMount() {
    this.resetOptions(() => {
      this.updateField(this.result)
    })
  },
  methods: {
    updateField(value) {
      if (Util.notNull(value)) {
        const { chainIndex } = this.schema
        this.tempdata[chainIndex] = this.options.filter(
          (option) => option.value == value
        )[0]
        this.$emit('emitListener')
      }
    },
    async resetOptions(fetched) {
      this.options = []
      const { request, valueEnum } = this.schema
      if (request) {
        this.options =
          (await request({
            chaindata: this.chaindata,
            tempdata: this.tempdata,
          })) || []
        fetched?.()
      } else if (valueEnum) {
        this.options = Object.entries(valueEnum).map(([value, { text }]) => ({
          value,
          label: text,
        }))
      }
    },
  },
}
</script>

<style scoped>
.checkbox {
  width: 100%;
  border: 1px solid #cdcdcd;
  border-radius: 6px;
  padding: 20px;
  display: flex;
  line-height: 26px;
}
</style>
