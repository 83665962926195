<template>
  <div class="ui-descriptions-item">
    <label-field
      v-if="editable && editing"
      ref="field"
      size="mini"
      :schema="schema"
      :initialValue="initialValue"
    />
    <ui-cell v-else :data="record" :value="initialValue" :schema="schema" />
    <div v-if="editable" class="switch">
      <template v-if="loading">
        <i class="el-icon-loading"></i>
      </template>
      <template v-else>
        <i v-if="!editing" class="el-icon-edit" @click="editing = true"></i>
        <i v-if="editing" class="el-icon-check" @click="changeField"></i>
        <i v-if="editing" class="el-icon-close" @click="editing = false"></i>
      </template>
    </div>
  </div>
</template>

<script>
import LabelField from '../form/label-field'
import UiCell from '../cell'
export default {
  name: 'ui-descriptions-item',
  components: { UiCell, LabelField },
  props: {
    record: Object,
    schema: Object,
    editable: Boolean,
  },
  data() {
    return {
      editing: false,
      loading: false,
    }
  },
  computed: {
    initialValue() {
      const { chainIndex, unit } = this.schema
      let u = unit ? `(${unit})` : ''
      return this.record?.[chainIndex] + u
    },
  },
  methods: {
    changeField() {
      const value = this.$refs.field?.value
      if (this.validateField()) {
        this.loading = true
        this.$emit('change', value, () => {
          this.loading = false
          this.editing = false
        })
      }
    },
    validateField() {
      return true
    },
    emitListener(params) {
      this.$emit('emitListener', {
        newdata: this.chaindata,
        launcher: this.schema.chainIndex,
        ...params,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-descriptions-item {
  display: flex;
  align-items: center;
  // justify-content: center;

  .switch {
    flex-shrink: 0;
    margin: 0 6px;
    color: #606266;
    font-size: 14px;
    cursor: pointer;

    i {
      margin: 0 3px;

      &:hover {
        color: #409eff;
      }
    }
  }
}
</style>
