<template>
  <div class="car">
    <el-button @click="showDialog = true">{{
      last ? prepend + mid + last : '点击选择车牌号'
    }}</el-button>
    <el-dialog
      title="选择车牌号"
      width="900px"
      append-to-body
      :visible.sync="showDialog"
      :close-on-click-modal="false"
    >
      <div class="section">
        <div class="section-wrapper">
          <el-button plain type="primary" @click="show = 1">{{
            prepend
          }}</el-button>
          <el-button plain type="primary" @click="show = 2">{{
            mid
          }}</el-button>
          <el-button plain type="primary" @click="show = 3">{{
            last
          }}</el-button>
          <el-button icon="el-icon-close" @click="back">退格</el-button>
          <div style="margin-left: 15px">
            是否新能源：<el-switch v-model="isElec"></el-switch>
          </div>
        </div>

        <div class="section-wrapper2">
          <div class="list" v-if="show == 1">
            <div
              :class="item.isClick ? 'pre-item active' : 'pre-item'"
              v-for="(item, index) in preList"
              :key="index"
              @click="preItemClick(item, index)"
            >
              {{ item.value }}
            </div>
          </div>
          <div class="list" v-if="show == 2">
            <div
              :class="item.isClick ? 'pre-item active' : 'pre-item'"
              v-for="(item, index) in midList"
              :key="index"
              @click="midItemClick(item, index)"
            >
              {{ item.value }}
            </div>
          </div>
          <div class="list" v-if="show == 3">
            <div
              :class="item.isClick ? 'pre-item active' : 'pre-item'"
              v-for="(item, index) in lastList"
              :key="index"
              @click="lastItemClick(item, index)"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="showDialog = false">取消</el-button>
        <el-button type="primary" @click="submit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { buildString } from '../lib/select'
import { preList, midList, lastList } from '../lib/cars'
export default {
  name: 'car-field',
  props: {
    schema: Object,
    value: {},
    tempdata: Object,
    chaindata: Object,
    initialValues: Object,
  },
  data() {
    return {
      showDialog: false,
      result: buildString(this.value),
      prepend: '冀',
      mid: 'B',
      preList,
      midList,
      lastList: [...midList, ...lastList],
      last: '',
      show: 1,
      isElec: false,
    }
  },
  beforeMount() {
    this.setRes()
  },
  methods: {
    submit() {
      // let res = this.result.trim()
      // if (/\s/g.test(res)) {
      //   this.$message({
      //     type: 'warning',
      //     message: '车牌号不能带有空格',
      //   })
      //   return
      // }
      if (this.isElec) {
        if (this.last.length !== 6) {
          this.$message({
            type: 'warning',
            message: '车牌号输入有误',
          })
          return
        }
      } else {
        if (this.last.length !== 5) {
          this.$message({
            type: 'warning',
            message: '车牌号输入有误',
          })
          return
        }
      }

      let val = this.prepend + this.mid + this.last
      this.$emit('input', buildString(val))
      this.showDialog = false
    },
    back() {
      let l = this.last.length
      this.last = this.last.slice(0, l - 1)
    },
    setRes() {
      this.prepend = this.value ? this.value.charAt(0) : '冀'
      this.mid = this.value ? this.value.charAt(1) : 'B'
      this.last = this.value ? this.value.slice(2) : ''
      // this.showDialog = true
    },
    lastItemClick(item, index) {
      if (
        (this.last.length > 4 && !this.isElec) ||
        (this.last.length > 5 && this.isElec)
      )
        return
      this.lastList.forEach((item) => {
        item.isClick = false
      })
      this.$nextTick(() => {
        this.last = this.last + item.value
        this.lastList[index].isClick = true
      })
    },
    midItemClick(item, index) {
      this.midList.forEach((item) => {
        item.isClick = false
      })
      this.$nextTick(() => {
        this.mid = item.value
        this.midList[index].isClick = true
      })
    },
    preItemClick(item, index) {
      this.preList.forEach((item) => {
        item.isClick = false
      })
      this.$nextTick(() => {
        this.prepend = item.value
        this.preList[index].isClick = true
      })
    },
  },
}
</script>

<style scoped>
.list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.pre-item {
  width: 60px;
  height: 60px;
  background: #007acc;
  color: #fff;
  font-size: 30px;
  line-height: 60px;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  user-select: none;
}
.pre-item.active {
  background: #fff;
  border: 1px solid #333;
  color: #333;
}
</style>
