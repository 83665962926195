import External from './external'

/**
 * 权限判断指令
 */
export const perm: Vue.PluginObject<{}> = {
  install(Vue) {
    Vue.directive('perm', {
      bind(el, binding, vnode) {
        const enable = External.hasPerm(binding.value)

        // !enable && el.parentNode?.removeChild(el)
        !enable && (el.style.display = 'none')
      },
    })
  },
}
