<template>
  <el-select
    clearable
    v-model="result"
    :size="size"
    :multiple="schema.multiple"
    :placeholder="schema.placeholder"
    :filter-method="search"
    filterable
  >
    <el-option
      v-for="option in options"
      :key="option.value"
      :value="'' + option.value"
      :label="option.label"
    />
  </el-select>
</template>

<script>
import Util from '../../../../lib/util'
import { buildList, buildString } from '../lib/select'
export default {
  name: 'select-field',
  props: {
    schema: Object,
    value: {},
    tempdata: {
      type: Object,
      default: () => ({}),
    },
    chaindata: Object,
    size: String,
  },
  data() {
    const { multiple } = this.schema
    return {
      options: [],
      result: multiple ? buildList(this.value) : buildString(this.value),
      records: [],
    }
  },
  watch: {
    schema: {
      deep: true,
      immediate: true,
      handler(n) {
        // console.log('select-filed schema', n)
        n.valueEnum && this.resetOptions()
      },
    },
    /**
     * 当 dependencies 内的变量更新时重置当前field
     */
    async chaindata(newVal, oldVal) {
      const { dependencies: deps = [] } = this.schema
      ;(typeof deps === 'string' ? [deps] : deps).forEach((dep) => {
        if (newVal[dep] !== oldVal[dep]) {
          if (oldVal[dep]) {
            // this.result = undefined
            this.resetOptions()
          } else {
            this.resetOptions(() => {
              this.updateField(this.result)
            })
          }
        }
      })
    },
    result: {
      immediate: true,
      handler(val) {
        const { chainIndex, multiple } = this.schema
        if (multiple) {
          this.tempdata[chainIndex] = this.options.filter(
            (option) => val && val.includes(option.value)
          )
        } else {
          this.tempdata[chainIndex] = this.options.filter(
            (option) => option.value == val
          )[0]
        }
        this.$emit('input', buildString(val))
      },
    },
  },
  beforeMount() {
    this.resetOptions(() => {
      this.updateField(this.result)
    })
  },
  methods: {
    async search(keyword) {
      if (keyword.length == 0) {
        this.options = JSON.parse(JSON.stringify(this.records))
      } else {
        const { searchRequest } = this.schema.fieldProps
        this.options = (await searchRequest?.(keyword)) || []
      }
    },
    /**
     * TODO: 可能少些功能,懒得测了,遇到再说吧
     *
     * 手动触发更新,会向上调用emitListener方法,触发schema中的listener,进而实现联动
     */
    updateField(value) {
      if (Util.notNull(value)) {
        const { chainIndex } = this.schema
        this.tempdata[chainIndex] = this.options.filter(
          (option) => option.value == value
        )[0]
        this.$emit('emitListener')
      }
    },
    async resetOptions(fetched) {
      this.options = []
      this.records = []
      const { request, valueEnum, fieldProps, multiple } = this.schema
      if (request) {
        this.records =
          (await request({
            chaindata: this.chaindata,
            tempdata: this.tempdata,
          })) || []
        fetched?.()
        this.options = JSON.parse(JSON.stringify(this.records))
      }
    },
  },
}
</script>

<style></style>
